import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Layout, LayoutType } from '../../../models/common/layout.models';

type UpdateLayoutAction = PayloadAction<LayoutType>;

const initialState: Layout = {
    layoutName: 'comparator'
};

const layoutSlice = createSlice({
    name: 'layoutSlice',
    initialState,
    reducers: {
        setStateLayout: (state, action: UpdateLayoutAction) => {
            state.layoutName = action.payload;
        },
        clearStateLayout: (state) => {
            return state;
        }
    }
});

export const { setStateLayout, clearStateLayout } = layoutSlice.actions;

export default layoutSlice.reducer;
