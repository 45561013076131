import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type formPaginationState = {
    formPaginationState: number;
};

const initialState: formPaginationState = {
    formPaginationState: 0
};

export const { actions, reducer } = createSlice({
    name: 'formPagination',
    initialState,
    reducers: {
        setStateformPagination: (state, action: PayloadAction<number>) => {
            state.formPaginationState = action.payload;
        },
        clearStateformPagination: (state) => {
            state.formPaginationState = 0;
        }
    }
});

export const { setStateformPagination, clearStateformPagination } = actions;

export default reducer;
