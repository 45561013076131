import {
    ResultComparatorPv,
    ResultComparatorStorePv
} from '@/models/pv/resultsComparatorPv.models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type UpdatePossibleInstallersAction = PayloadAction<ResultComparatorPv[]>;
type UpdateSelectedInstallerAction = PayloadAction<ResultComparatorPv>;
type UpdateLoadingResultAction = PayloadAction<true | false>;

const initialState: ResultComparatorStorePv = {
    offers: null,
    selectedOffer: null,
    loadingResult: false
};

const resultComparatorPvSlice = createSlice({
    name: 'resultComparatorPvSlice',
    initialState,
    reducers: {
        setStatePossibleInstallersPv: (
            state,
            action: UpdatePossibleInstallersAction
        ) => {
            state.offers = action.payload;
        },
        setStateSelectedInstallerPv: (
            state,
            action: UpdateSelectedInstallerAction
        ) => {
            state.selectedOffer = action.payload;
        },
        setStateLoadingResultPv: (state, action: UpdateLoadingResultAction) => {
            state.loadingResult = action.payload;
        },
        clearStateResultComparatiorPv: (state) => {
            state.offers = null;
            state.selectedOffer = null;
            state.loadingResult = false;
        }
    }
});

export const {
    setStateSelectedInstallerPv,
    setStatePossibleInstallersPv,
    setStateLoadingResultPv,
    clearStateResultComparatiorPv
} = resultComparatorPvSlice.actions;

export default resultComparatorPvSlice.reducer;
