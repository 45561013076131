import { UserInstallerValidityIrve, UserInstallerValidityPv, UserInstallerValidityStore } from '@/models/common/user.models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: UserInstallerValidityStore = {
    validityIrve: null,
    validityPv: null
};

export const { actions, reducer } = createSlice({
    name: 'validity',
    initialState,
    reducers: {
        setStateValidityIrve: (state, action: PayloadAction<UserInstallerValidityIrve>) => {
            state.validityIrve = action.payload;
        },
        setStateValidityPv: (state, action: PayloadAction<UserInstallerValidityPv>) => {
            state.validityPv = action.payload;
        },
        clearState: (state) => {
            state.validityPv = initialState.validityPv;
            state.validityIrve = initialState.validityIrve;
        }
    }
});

export const { setStateValidityIrve, setStateValidityPv, clearState } = actions;

export default reducer;
