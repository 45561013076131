import { lazy } from 'react';
import Loader from '@/components/common/Loader';

export const HousesOngoingRoute = Loader(
    lazy(() => import('@/pages/dashboard/B2C/pv/HousesOnGoing'))
);

export const HousesArchivedRoute = Loader(
    lazy(() => import('@/pages/dashboard/B2C/pv/HousesArchived'))
);

export const HousesOngoingProfileRoute = Loader(
    lazy(() => import('@/pages/dashboard/B2C/pv/Profile/Profile'))
);

export const HousesOngoingProfileChangeStationsRoute = Loader(
    lazy(() => import('@/pages/dashboard/B2C/pv/Profile/ChangeStations'))
);

export const HousesOngoingProfileChangeInstallersRoute = Loader(
    lazy(() => import('@/pages/dashboard/B2C/pv/Profile/ChangeInstallers'))
);
