import { lazy } from 'react';
import Loader from '@/components/common/Loader';

export const ProjectManagersRoute = Loader(
    lazy(() => import('@/pages/dashboard/ProjectManagers/irve/ProjectManagers'))
);

export const ProjectManagersProfileRoute = Loader(
    lazy(
        () =>
            import(
                '@/pages/dashboard/ProjectManagers/irve/Profile/ProjectManagerProfile'
            )
    )
);

export const ProjectManagerAddRoute = Loader(
    lazy(
        () =>
            import(
                '@/pages/dashboard/ProjectManagers/irve/Add/ProjectManagerAdd'
            )
    )
);
