import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    B2C,
    B2CComment,
    B2CAccessories,
    B2CMessage,
    B2CStore,
    B2CHousingType,
    B2COnGoingArchived,
    B2CTableHeader,
    B2CStatus
} from '../../../models/irve/b2c.models';

type UpdateOpportunityAction = PayloadAction<B2C>;
type UpdateOpportunitiesAction = PayloadAction<B2C[]>;
type UpdateOpportunityCommmentAction = PayloadAction<B2CComment>;
type UpdateOpportunityAllCommentsAction = PayloadAction<B2CComment[]>;
type UpdateOpportunityAccessoriesAction = PayloadAction<B2CAccessories>;
type UpdateOpportunityMessageAction = PayloadAction<B2CMessage>;
type UpdateOpportunityAllMessagesAction = PayloadAction<B2CMessage[]>;
type UpdateOpportunityFinalPriceAction = PayloadAction<string>;
type UpdateOpportunitySelectedId = PayloadAction<number>;
type UpdateOpportunityHousingType = PayloadAction<B2CHousingType>;
type UpdateOpportunityOnGoingArchived = PayloadAction<B2COnGoingArchived>;
type UpdateOpportunityTableHeaders = PayloadAction<B2CTableHeader[]>;
type UpdateOpportunityB2CStatus = PayloadAction<B2CStatus>;

const initialState: B2CStore = {
    opportunity: null,
    opportunities: null,
    opportunityComment: null,
    opportunityAllComments: null,
    opportunityAccessories: null,
    opportunityAllMessages: null,
    opportunityMessage: null,
    opportunityFinalPrice: null,
    opportunitySelectedId: null,
    opportunityHousingType: null,
    opportunityOnGoingArchived: null,
    opportunityTableHeaders: null,
    opportunityStatus: null
};

const opportunitySlice = createSlice({
    name: 'opportunitySlice',
    initialState,
    reducers: {
        setStateOpportunityIrve: (state, action: UpdateOpportunityAction) => {
            state.opportunity = action.payload;
        },
        setStateOpportunitiesIrve: (
            state,
            action: UpdateOpportunitiesAction
        ) => {
            state.opportunities = action.payload;
        },
        setStateOpportunityCommentIrve: (
            state,
            action: UpdateOpportunityCommmentAction
        ) => {
            state.opportunityComment = action.payload;
        },
        setStateOpportunityAllCommentsIrve: (
            state,
            action: UpdateOpportunityAllCommentsAction
        ) => {
            state.opportunityAllComments = action.payload;
        },
        setStateOpportunityAccessoriesIrve: (
            state,
            action: UpdateOpportunityAccessoriesAction
        ) => {
            state.opportunityAccessories = action.payload;
        },
        setStateOpportunityMessageIrve: (
            state,
            action: UpdateOpportunityMessageAction
        ) => {
            state.opportunityMessage = action.payload;
        },
        setStateOpportunityAllMessagesIrve: (
            state,
            action: UpdateOpportunityAllMessagesAction
        ) => {
            state.opportunityAllMessages = action.payload;
        },
        setStateOpportunityFinalPriceIrve: (
            state,
            action: UpdateOpportunityFinalPriceAction
        ) => {
            state.opportunityFinalPrice = action.payload;
        },
        setStateOpportunitySelectedIdIrve: (
            state,
            action: UpdateOpportunitySelectedId
        ) => {
            state.opportunitySelectedId = action.payload;
        },
        setStateOpportunityHousingTypeIrve: (
            state,
            action: UpdateOpportunityHousingType
        ) => {
            state.opportunityHousingType = action.payload;
        },
        setStateOpportunityOnGoingArchivedIrve: (
            state,
            action: UpdateOpportunityOnGoingArchived
        ) => {
            state.opportunityOnGoingArchived = action.payload;
        },
        setStateOpportunityTableHeadersIrve: (
            state,
            action: UpdateOpportunityTableHeaders
        ) => {
            state.opportunityTableHeaders = action.payload;
        },
        setStateOpportunityB2CStatusIrve: (
            state,
            action: UpdateOpportunityB2CStatus
        ) => {
            state.opportunityStatus = action.payload;
        },

        clearStateOpportunityIrve: (state) => {
            state.opportunity = null;
            state.opportunities = null;
            state.opportunityComment = null;
            state.opportunityAllComments = null;
            state.opportunityAccessories = null;
            state.opportunityAllMessages = null;
            state.opportunityMessage = null;
            state.opportunityFinalPrice = null;
            state.opportunitySelectedId = null;
            state.opportunityHousingType = null;
            state.opportunityOnGoingArchived = null;
            state.opportunityTableHeaders = null;
            state.opportunityStatus = null;
        }
    }
});

export const {
    setStateOpportunityIrve,
    setStateOpportunitiesIrve,
    setStateOpportunityCommentIrve,
    setStateOpportunityAllCommentsIrve,
    setStateOpportunityAccessoriesIrve,
    setStateOpportunityMessageIrve,
    setStateOpportunityAllMessagesIrve,
    setStateOpportunityFinalPriceIrve,
    setStateOpportunitySelectedIdIrve,
    setStateOpportunityOnGoingArchivedIrve,
    setStateOpportunityHousingTypeIrve,
    setStateOpportunityTableHeadersIrve,
    setStateOpportunityB2CStatusIrve,
    clearStateOpportunityIrve
} = opportunitySlice.actions;

export default opportunitySlice.reducer;
