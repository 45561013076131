import { Outlet } from 'react-router-dom';
import { Box, Toolbar, useTheme } from '@mui/material';
import Sidebar from '@/components/common/Sidebar';
import Topbar from '@/components/common/Topbar';
import accountInstallerB2CRoutes from '@/pages/routes/accountInstaller/irve';
import { useSelector } from 'react-redux';
import { selectRoutesIrvePV } from '@/store/selector';
import accountInstallerRoutesPv from '@/pages/routes/accountInstaller/pv';
// import {InstallerRoutes } from "@/routes/appRoutes";

const InstallerLayout = () => {
    const theme = useTheme();

    const { route } = useSelector(selectRoutesIrvePV);

    return (
        <Box sx={{ display: 'flex' }}>
            <Topbar />
            <Box
                component="nav"
                sx={{
                    width: theme.sidebar.width,
                    flexShrink: 0
                }}
            >
                <Sidebar
                    routesData={
                        route === 'irve'
                            ? accountInstallerB2CRoutes
                            : accountInstallerRoutesPv
                    }
                />
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    width: `calc(100% - ${theme.sidebar.width})`,
                    minHeight: '100vh',
                    backgroundColor: theme.general.reactFrameworkColor
                }}
            >
                <Toolbar />
                <Outlet />
            </Box>
        </Box>
    );
};

export default InstallerLayout;
