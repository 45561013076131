import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserTokens } from '@/models/common/user.models';

type UpdateUserTokensAction = PayloadAction<string | null>;
type UpdateUserRefreshTokensAction = PayloadAction<string | null>;

const initialState: UserTokens = {
    token: null,
    refreshToken: null
};

const { actions, reducer } = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setStateUserToken: (state, action: UpdateUserTokensAction) => {
            state.token = action.payload;
        },
        setStateUserRefreshToken: (
            state,
            action: UpdateUserRefreshTokensAction
        ) => {
            state.refreshToken = action.payload;
        },
        clearStateAuth: (state) => (state = initialState)
    }
});

export const { setStateUserToken, setStateUserRefreshToken, clearStateAuth } =
    actions;

export default reducer;
