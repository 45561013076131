import { LogoChargeInLongIrve, LogoChargeInLongPv } from '@/assets/logos';
import { Box, Grid, styled } from '@mui/material';
import ButtonSecondary from '../buttons/ButtonSecondary';
import ButtonPrimary from '../buttons/ButtonPrimary';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectRoutesIrvePV } from '@/store/selector';

const Header = () => {
    const navigate = useNavigate();

    const { route } = useSelector(selectRoutesIrvePV);

    const handleClickLogin = () => {
        navigate('login');
    };

    const handleClickWebsite = () => {
        window.location.replace('https://charge-in.fr');
    };

    return (
        <StyledHeader container padding={{ xs: '18px 24px', md: '20px 92px' }}>
            {/* <Grid item flex={1} display={{ xs: 'none', md: ' block' }}>
                <ButtonSecondary arrow="prev" handleClick={handleClickWebsite}>
                    Revenir sur le site
                </ButtonSecondary>
            </Grid> */}

            <Grid
                item
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <a
                    href="https://charge-in.fr"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <img
                        src={
                            route === 'irve'
                                ? LogoChargeInLongIrve
                                : LogoChargeInLongPv
                        }
                        alt="logoChargeinLong"
                        width="120"
                    />
                </a>
            </Grid>
            <Grid item flex={1} display={'flex'} justifyContent={'flex-end'}>
                <ButtonPrimary handleClick={handleClickLogin}>
                    Me connecter
                </ButtonPrimary>
            </Grid>
        </StyledHeader>
    );
};

const StyledHeader = styled(Grid)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 92px;
`;
export default Header;
