import { api } from './config';
import { UserInstaller, UserInstallerValidityIrve } from '../../models/common/user.models';
import { B2C, B2CAccessories, B2CComment, B2CTableHeader } from '../../models/irve/b2c.models';
import { StationInstaller, StationInstallerProfile } from '../../models/irve/station.models';
import { B2BInstallerMe, B2BMessage } from '../../models/irve/b2b.models';
import { InvoiceFile } from '../../models/irve/invoice.models';
import { apiFormdata } from './configFormData';
import { UserInstallerLegalInformationsToUpdate, UserInstallerProfileToUpdate } from '../../models/irve/installer.models';
import { AccessoryInstallerInstaller, AccessoryInstallerProfile } from '../../models/irve/accessory.models.type';
import { ResultComparatorInstaller, ResultComparatorStation } from '../../models/irve/resultsComparator.models';
import { YousignResponse } from '../../models/irve/yousign.models';

export const installerMeProfile = async (): Promise<UserInstaller> => {
    try {
        const response = await api.get('api/installer/me');
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const installerGetOpportunitiesHousingTypeOngoingArchived = async (housingType: string, ongoinArchived: string): Promise<B2C[]> => {
    // return await api.get("api/me");
    try {
        const response = await api.get(`api/installer/me/opportunity/filter/${housingType}/${ongoinArchived}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const installerGetOneOpportunity = async (id: number): Promise<B2C> => {
    // return await api.get("api/me");
    try {
        const response = await api.get(`api/installer/me/opportunity/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

// export const installerGetOpportunityAccessories = async (id:number): Promise<OpportunityAccessories> => {
//     // return await api.get("api/me");
//     try {
//         const response = await api.get(`api/installer/me/opportunity/${id}/get-opportunity-accessories`);
//         return response.data;
//     } catch (error) {
//         console.error(error);
//         throw new Error('Failed to log in');
//     }
// };

export const installerMeSetOpportunityStatus = async (id: number, status: string): Promise<B2C> => {
    // return await api.get("api/me");
    try {
        const response = await api.post(`api/installer/me/opportunity/${id}/set-opportunity-status/${status}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const installerGetOneStationInstaller = async (id: number): Promise<StationInstaller> => {
    // return await api.get("api/me");
    try {
        const response = await api.get(`api/installer/me/station-installer/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const installerGetStudies = async (ongoingArchived: string): Promise<B2BInstallerMe[]> => {
    // return await api.get("api/me");
    try {
        const response = await api.get(`api/installer/me/study/filter/${ongoingArchived}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const installerGetOneStudy = async (id: number): Promise<B2BInstallerMe> => {
    // return await api.get("api/me");
    try {
        const response = await api.get(`api/installer/me/study/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const installerGetStudyConversation = async (id: number): Promise<B2BMessage[]> => {
    // return await api.get("api/me");
    try {
        const response = await api.get(`api/installer/me/study/${id}/conversation`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const installerAddInvoiceFile = async (id: number, file: FormData): Promise<InvoiceFile> => {
    // return await api.get("api/me");
    try {
        const response = await apiFormdata.post(`api/installer/me/study/${id}/add-invoice-file`, file);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const installerAddMessage = async (id: number, message: string): Promise<B2BMessage[]> => {
    // return await api.get("api/me");
    try {
        const response = await api.post(`api/installer/me/study/${id}/add-message`, { message });
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const installerMeGetProfile = async (): Promise<UserInstaller> => {
    try {
        const response = await api.get(`api/installer/me/profile`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const installerMeUpdateProfile = async (profile: UserInstallerProfileToUpdate): Promise<UserInstaller> => {
    try {
        const response = await api.put(`api/installer/me/profile/update`, profile);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const installerMeUpdateLogo = async (logo: FormData): Promise<UserInstaller> => {
    try {
        const response = await apiFormdata.post(`api/installer/me/profile/update/logo`, logo);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const installerMeUpdateLegalInformations = async (profile: UserInstallerLegalInformationsToUpdate): Promise<UserInstaller> => {
    try {
        const response = await api.put(`api/installer/me/profile/update/legal-informations`, profile);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const installerMeUpdateLegalInformationsDoc = async (doc: FormData): Promise<UserInstaller> => {
    try {
        const response = await apiFormdata.post(`api/installer/me/profile/update/legal-informations-file`, doc);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const installerMeUpdateInstallations = async (formData: FormData): Promise<UserInstaller> => {
    try {
        const response = await apiFormdata.post(`api/installer/me/profile/update/installations`, formData);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const installerMeUpdateDepartments = async (formData: FormData): Promise<UserInstaller> => {
    try {
        const response = await apiFormdata.post(`api/installer/me/profile/update/departments`, formData);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const installeurMeGetSelectedStations = async (): Promise<StationInstallerProfile[]> => {
    try {
        const response = await api.get(`api/installer/me/profile/get-selected-stations`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const installeurMeGetAllStations = async (): Promise<StationInstallerProfile[]> => {
    try {
        const response = await api.get(`api/installer/me/profile/get-all-stations-wprices`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const installerAddSelectedStations = async (
    stations: StationInstallerProfile[],
    priceWoVatStations: string[],
    priceWoVatAccMono: string[],
    priceWoVatAccTri: string[]
): Promise<UserInstaller> => {
    try {
        const response = await api.post(`api/installer/me/profile/add-selected-stations`, {
            stations,
            priceWoVatStations,
            priceWoVatAccMono,
            priceWoVatAccTri
        });
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const installeurMeGetSelectedAccessoriesInstallers = async (): Promise<AccessoryInstallerProfile[]> => {
    try {
        const response = await api.get(`api/installer/me/profile/get-selected-accessories`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const installeurMeGetAllAccessorieInstaller = async (): Promise<AccessoryInstallerProfile[]> => {
    try {
        const response = await api.get(`api/installer/me/profile/get-all-accessories-wprices`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const installerAddSelectedAccessoriesInstaller = async (accessoryInstallers: AccessoryInstallerProfile[], priceWoVatAccessoriesInstaller: string[]): Promise<UserInstaller> => {
    try {
        const response = await api.post(`api/installer/me/profile/add-selected-accessories-installer`, { accessoryInstallers, priceWoVatAccessoriesInstaller });
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const installerMeCheckValidity = async (): Promise<UserInstallerValidityIrve> => {
    try {
        const response = await api.get(`api/installer/me/check-validity`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const installerMeUpdateAccessoriesInstaller = async (accessoryInstallerInstallers: AccessoryInstallerInstaller[]): Promise<UserInstaller> => {
    try {
        const response = await api.put(`api/installer/me/accessories-installer/update`, { accessoryInstallerInstallers });
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

/// NEW BUSINESS MODEL
export const installerOpportunitiesTableHeader = async (housingType: string, ongoingArchived: string, status: string): Promise<B2CTableHeader[]> => {
    // return await api.get("api/me");
    try {
        const response = await api.get(`api/installer/me/opportunity/table/${housingType}/${ongoingArchived}/header/${status}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const installerOpportunitiesHousingType = async (housingType: string, ongoingArchived: string): Promise<B2C[]> => {
    // return await api.get("api/me");
    try {
        const response = await api.get(`api/installer/me/opportunity/table/${housingType}/${ongoingArchived}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const installerOpportunitySetStatus = async (id: number, status: string): Promise<B2C> => {
    try {
        const response = await api.post(`api/installer/opportunity/status/sellsy/${id}/set/${status}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const installerSendMailNrp = async (id: number): Promise<B2C> => {
    // return await api.get("api/me");
    try {
        const response = await api.post(`api/installer/opportunity/mail/${id}/nrp`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const installerSendMailSdr = async (id: number): Promise<B2C> => {
    // return await api.get("api/me");
    try {
        const response = await api.post(`api/installer/opportunity/mail/${id}/sdr`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const installerGetOpportunityComments = async (id: number): Promise<B2CComment[]> => {
    try {
        const response = await api.get(`api/installer/opportunity/profile/comment/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const installerOpportunityAddComment = async (id: number, comment: string): Promise<B2C> => {
    try {
        const response = await api.post(`api/installer/opportunity/profile/comment/${id}/add`, { comment: comment });
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const installerGetAcessoriesInstaller = async (id: number): Promise<AccessoryInstallerInstaller[]> => {
    try {
        const response = await api.get(`api/installer/opportunity/profile/accessory-installer/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const installetGetSelectedAccessoriesInstallerInstaller = async (id: number): Promise<AccessoryInstallerInstaller[]> => {
    try {
        const response = await api.get(`api/installer/opportunity/profile/get-selected-acessories-installers/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const installerUpdateSelectedAccessoriesInstallerInstaller = async (id: number, accessoriesList: AccessoryInstallerInstaller[], accessoryStation: boolean): Promise<B2C> => {
    try {
        const response = await api.post(`api/installer/opportunity/profile/update-selected-acessories-installers/${id}`, {
            accessoriesInstallerInstaller: accessoriesList,
            accessoryStationInstaller: accessoryStation
        });
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const installerGetOpportunityAccessories = async (id: number): Promise<B2CAccessories> => {
    try {
        const response = await api.get(`api/installer/opportunity/profile/get-opportunity-accessories/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const installerOpportunityAddDiscount = async (id: number, discount: string): Promise<B2C> => {
    try {
        const response = await api.post(`api/installer/opportunity/profile/add-discount/${id}`, { discount });
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const installerGetPossibleStations = async (id: number): Promise<ResultComparatorStation[]> => {
    try {
        const response = await api.get(`api/installer/opportunity/station/${id}/get-possible-stations`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const installerAddSelectedStation = async (id: number, selectedStation: ResultComparatorStation): Promise<B2C> => {
    try {
        const response = await api.post(`api/installer/opportunity/station/${id}/add-selected-station`, selectedStation);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const installerSendInvoiceProposal = async (id: number | undefined, form: FormData): Promise<YousignResponse> => {
    try {
        const response = await apiFormdata.post(`api/installer/opportunity/profile/${id}/send-invoice-proposal`, form);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};
