import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QnA, Form5Store } from '@/models/irve/form.model';

type UpdateQnAAction = PayloadAction<QnA>;

const initialState: Form5Store = {
    qna1: null,
    qna2: null,
    qna3: null
};

const form5Slice = createSlice({
    name: 'form5',
    initialState,
    reducers: {
        setStateForm5QnA1Irve: (state, action: UpdateQnAAction) => {
            state.qna1 = action.payload;
        },
        setStateForm5QnA2Irve: (state, action: UpdateQnAAction) => {
            state.qna2 = action.payload;
        },
        setStateForm5QnA3Irve: (state, action: UpdateQnAAction) => {
            state.qna3 = action.payload;
        },
        clearStateForm5Irve: (state) => {
            state.qna1 = null;
            state.qna2 = null;
            state.qna3 = null;
        }
    }
});

export const {
    setStateForm5QnA1Irve,
    setStateForm5QnA2Irve,
    setStateForm5QnA3Irve,
    clearStateForm5Irve
} = form5Slice.actions;

export default form5Slice.reducer;
