import { lazy } from 'react';
import Loader from '@/components/common/Loader';

export const B2BOnGoingRoute = Loader(
    lazy(() => import('@/pages/dashboard/B2B/irve/OnGoing'))
);

export const B2BArchivedRoute = Loader(
    lazy(() => import('@/pages/dashboard/B2B/irve/Archived'))
);

export const B2BAddRoute = Loader(
    lazy(() => import('@/pages/dashboard/B2B/irve/Add/StudyAdd'))
);

export const B2BProfileRoute = Loader(
    lazy(() => import('@/pages/dashboard/B2B/irve/Profile/Profile'))
);

export const B2BMessengerRoute = Loader(
    lazy(() => import('@/pages/dashboard/B2B/irve/Profile/Messenger'))
);

export const B2BInstallersUpdateRoute = Loader(
    lazy(() => import('@/pages/dashboard/B2B/irve/Profile/InstallersUpdate'))
);
