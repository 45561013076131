import { lazy } from 'react';
import Loader from '@/components/common/Loader';

export const PannelsRoute = Loader(
    lazy(() => import('@/pages/accountInstaller/Me/pv/Pannel'))
);

export const PannelsAddRoute = Loader(
    lazy(() => import('@/pages/accountInstaller/Me/pv/PannelAdd'))
);
