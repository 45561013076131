import { combineReducers } from '@reduxjs/toolkit';

import appIrveSlice from './reducers/irve/appSlice';

import authSlice from './reducers/auth.slice';
import userIrveSlice from './reducers/irve/user.slice';
import layoutIrveSlice from './reducers/irve/layout.slice';
import form1IrveSlice from './reducers/irve/form1.slice';
import form2HouseIrveSlice from './reducers/irve/form2House.slice';
import form2ApartmentIrveSlice from './reducers/irve/form2Apartment.slice';
import form3IrveSlice from './reducers/irve/form3.slice';
import form4IrveSlice from './reducers/irve/form4.slice';
import form5IrveSlice from './reducers/irve/form5.slice';
import form6IrveSlice from './reducers/irve/form6.slice';
import formElecConsumIrveSlice from './reducers/irve/formElecConsum.slice';
import vehicleIrveSlice from './reducers/irve/vehicle.slice';
import accessoryIrveSlice from './reducers/irve/accessory.slice';
import resultComparatorIrveSlice from './reducers/irve/resultComparator.slice';
import opportunityIrveSlice from './reducers/irve/opportunity.slice';
import studyIrveSlice from './reducers/irve/study.slice';
import stationIrveSlice from './reducers/irve/station.slice';
import notificationIrveSlice from './reducers/irve/notification.slice';
import routesIrvePvSlice from './reducers/routesIrvePv.slice';
import formPaginationSlice from './reducers/formPagination.slice';

import formHousingPvSlice from './reducers/pv/formHousing.slice';
import formConsumPvSlice from './reducers/pv/formConsum.slice';
import formProjectPvSlice from './reducers/pv/formProject.slice';
import formInformationPvSlice from './reducers/pv/formInformations.slice';
import resultComparatorPvSlice from './reducers/pv/resultComparator.slice';
import opportunityPvSlice from './reducers/pv/opportunity.slice';
import regionListSlice from './reducers/regionList.slice';
import loadingSlice from './reducers/loading.slice';
import validitySlice from './reducers/validity.slice';

import installerSlicePv from './reducers/pv/installer.slice';
import solarPanel from './reducers/pv/solarPanel.slice';

export const localReducer = combineReducers({
    // Irve
    appIrve: appIrveSlice,
    userIrve: userIrveSlice,
    layoutIrve: layoutIrveSlice,
    form1Irve: form1IrveSlice,
    form2HouseIrve: form2HouseIrveSlice,
    form2ApartmentIrve: form2ApartmentIrveSlice,
    form3Irve: form3IrveSlice,
    form4Irve: form4IrveSlice,
    form5Irve: form5IrveSlice,
    form6Irve: form6IrveSlice,
    formElecConsumIrve: formElecConsumIrveSlice,
    vehicleIrve: vehicleIrveSlice,
    accessoryIrve: accessoryIrveSlice,
    resultComparatorIrve: resultComparatorIrveSlice,
    opportunityIrve: opportunityIrveSlice,
    studyIrve: studyIrveSlice,
    stationIrve: stationIrveSlice,
    notificationIrve: notificationIrveSlice,
    routesIrvePv: routesIrvePvSlice,
    pagination: formPaginationSlice,

    // Pv
    formHousingPv: formHousingPvSlice,
    formConsumPv: formConsumPvSlice,
    formProjectPv: formProjectPvSlice,
    formInformationPv: formInformationPvSlice,
    resultComparatorPv: resultComparatorPvSlice,
    opportunityPv: opportunityPvSlice,
    regionList: regionListSlice,
    installationPv: installerSlicePv,
    solarPanel: solarPanel,
    loading: loadingSlice,
    validity: validitySlice
});

export const cookieReducer = combineReducers({
    auth: authSlice
});
