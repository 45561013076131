import {
    Collapse,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    useTheme
} from '@mui/material';
import { useEffect, useState } from 'react';
import { RouteType } from '@/models/common/route.models';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import SidebarItem from './SidebarItem';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { selectApp } from '@/store/selector';
import TypographyCustom from './Typography';

type Props = {
    item: RouteType;
};

const SidebarItemCollapse = ({ item }: Props) => {
    const [open, setOpen] = useState(false);

    const theme = useTheme();

    const { appState } = useSelector((state: RootState) => selectApp(state));

    useEffect(() => {
        if (appState.includes(item.state)) {
            setOpen(true);
        }
    }, [appState, item]);

    return item.sidebarProps ? (
        <>
            <ListItemButton
                onClick={() => setOpen(!open)}
                sx={{
                    '&: hover': {
                        backgroundColor: theme.sidebar.hoverBg
                    },
                    paddingY: '12px',
                    paddingX: '24px'
                }}
            >
                <ListItemIcon
                    sx={{
                        color: theme.sidebar.color
                    }}
                >
                    {item.sidebarProps.icon && item.sidebarProps.icon}
                </ListItemIcon>
                <ListItemText
                    disableTypography
                    primary={
                        <TypographyCustom font="chivo" fontSize={'16px'}>
                            {item.sidebarProps.displayText}
                        </TypographyCustom>
                    }
                />
                {open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
            </ListItemButton>
            <Collapse in={open} timeout="auto">
                <List>
                    {item.child?.map((route, index) =>
                        route.sidebarProps ? (
                            route.child ? (
                                <SidebarItemCollapse item={route} key={index} />
                            ) : (
                                <SidebarItem item={route} key={index} />
                            )
                        ) : null
                    )}
                </List>
            </Collapse>
        </>
    ) : null;
};

export default SidebarItemCollapse;
