import { lazy } from 'react';
import Loader from '@/components/common/Loader';

export const FormHousingPvRoute = Loader(
    lazy(() => import('@/pages/comparator/pv/FormHousing'))
);

export const FormConsumPvRoute = Loader(
    lazy(() => import('@/pages/comparator/pv/FormConsum'))
);

export const FormProjectPvRoute = Loader(
    lazy(() => import('@/pages/comparator/pv/FormProject'))
);

export const FormInformationsPvRoute = Loader(
    lazy(() => import('@/pages/comparator/pv/FormInformations'))
);

export const formOtherPvRoute = Loader(
    lazy(() => import('@/pages/comparator/pv/FormOther'))
);
