import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    B2CPV,
    B2CPVComment,
    B2CPVMessage,
    B2CPVStatus,
    B2CPVStore,
    B2CPVTableHeader
} from '@/models/pv/b2cPv.models';
import { B2CTableHeader } from '@/models/irve/b2c.models';

const initialState: B2CPVStore = {
    b2cPv: null,
    b2cPvs: null,
    b2cPvComment: null,
    b2cPvAllComments: null,
    b2cPvAllMessages: null,
    b2cPvMessage: null,
    b2cPvFinalPrice: null,
    b2cPvSelectedId: null,
    b2cPvStatus: null,
    b2cPvTableHeaders: null
};

const opportunitySlice = createSlice({
    name: 'opportunitySlice',
    initialState,
    reducers: {
        setStateOpportunityPv: (state, action: PayloadAction<B2CPV>) => {
            state.b2cPv = action.payload;
        },
        setStateOpportunitiesPv: (state, action: PayloadAction<B2CPV[]>) => {
            state.b2cPvs = action.payload;
        },
        setStateOpportunityCommentPv: (
            state,
            action: PayloadAction<B2CPVComment>
        ) => {
            state.b2cPvComment = action.payload;
        },
        setStateOpportunityAllCommentsPv: (
            state,
            action: PayloadAction<B2CPVComment[]>
        ) => {
            state.b2cPvAllComments = action.payload;
        },

        setStateOpportunityMessagePv: (
            state,
            action: PayloadAction<B2CPVMessage>
        ) => {
            state.b2cPvMessage = action.payload;
        },
        setStateOpportunityAllMessagesPv: (
            state,
            action: PayloadAction<B2CPVMessage[]>
        ) => {
            state.b2cPvAllMessages = action.payload;
        },
        setStateOpportunityFinalPricePv: (
            state,
            action: PayloadAction<string>
        ) => {
            state.b2cPvFinalPrice = action.payload;
        },
        setStateOpportunitySelectedIdPv: (
            state,
            action: PayloadAction<number>
        ) => {
            state.b2cPvSelectedId = action.payload;
        },

        setStateOpportunityTableHeadersPv: (
            state,
            action: PayloadAction<B2CPVTableHeader[]>
        ) => {
            state.b2cPvTableHeaders = action.payload;
        },
        setStateOpportunityB2CStatusPv: (
            state,
            action: PayloadAction<B2CPVStatus>
        ) => {
            state.b2cPvStatus = action.payload;
        },

        clearStateOpportunityPv: (state) => {
            state.b2cPv = null;
            state.b2cPvs = null;
            state.b2cPvComment = null;
            state.b2cPvAllComments = null;
            state.b2cPvAllMessages = null;
            state.b2cPvMessage = null;
            state.b2cPvFinalPrice = null;
            state.b2cPvSelectedId = null;
            state.b2cPvStatus = null;
            state.b2cPvTableHeaders = null;
        }
    }
});

export const {
    setStateOpportunityPv,
    setStateOpportunitiesPv,
    setStateOpportunityCommentPv,
    setStateOpportunityAllCommentsPv,
    setStateOpportunityMessagePv,
    setStateOpportunityAllMessagesPv,
    setStateOpportunityFinalPricePv,
    setStateOpportunitySelectedIdPv,
    setStateOpportunityTableHeadersPv,
    setStateOpportunityB2CStatusPv,
    clearStateOpportunityPv
} = opportunitySlice.actions;

export default opportunitySlice.reducer;
