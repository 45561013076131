import axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse
} from 'axios';

import { store } from '@/store';
import { selectAuth } from '@/store/selector';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setStateLayout } from '@/store/reducers/irve/layout.slice';

const api: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': process.env.REACT_APP_CORS_ALLOW_ORIGIN
    }
});

// Function to refresh token
const refreshToken = async () => {
    const state = store.getState();
    const refreshedToken = await api.post('/api/security/token/refresh', {
        refreshToken: selectAuth(state).refreshToken
    });
    return refreshedToken;
};

// Request interceptor
api.interceptors.request.use(
    (request) => {
        const state = store.getState();
        if (selectAuth(state).token) {
            request.headers['Authorization'] = `Bearer ${
                selectAuth(state).token
            }`;
        }
        return request;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor
/* api.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
        if (error.response?.status === 401) {
            console.error('Unauthorized request');
            localStorage.clear();
            window.location.href = '/login';
        }
    }
); */

export { api };
