import { Outlet } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';
import Footer from '@/components/comparator/Footer';

const footerCssProps = {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    paddingBottom: '0px'
};

const ComparatorLayout = () => {
    const theme = useTheme();

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        p: 3,
                        width: `calc(100% - ${theme.sidebar.width})`,
                        minHeight: '100vh',
                        backgroundColor: theme.general.reactFrameworkColor
                    }}
                >
                    {/*<Header/>*/}
                    <Outlet />
                    {/*   <Box sx={footerCssProps}>
                        <Footer />
                    </Box> */}
                </Box>
            </Box>
        </>
    );
};

export default ComparatorLayout;
