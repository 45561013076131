import { lazy } from 'react';
import Loader from '@/components/common/Loader';

export const B2BOnGoingRoute = Loader(
    lazy(() => import('../../../accountInstaller/B2B/irve/OnGoing'))
);

export const B2BArchivedRoute = Loader(
    lazy(() => import('../../../accountInstaller/B2B/irve/Archived'))
);

export const B2BOnGoingProfileRoute = Loader(
    lazy(() => import('../../../accountInstaller/B2B/irve/Profile/Profile'))
);

export const B2BMessengerRoute = Loader(
    lazy(() => import('../../../accountInstaller/B2B/irve/Profile/Messenger'))
);

export const B2COnGoingProfileRoute = Loader(
    lazy(() => import('../../../accountInstaller/B2C/irve/Profile/Profile'))
);
