import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QnA, FormConsumPv } from '@/models/irve/form.model';

type UpdateQ1Action = PayloadAction<QnA>;

const initialState: FormConsumPv = {
    qna1: null,
    qna2: null,
    qna3: null,
    qna4: null
};

const { actions, reducer } = createSlice({
    name: 'formConsumPv',
    initialState,
    reducers: {
        setStateFormConsumQnA1Pv: (state, action: UpdateQ1Action) => {
            state.qna1 = action.payload;
        },
        setStateFormConsumQnA2Pv: (state, action: UpdateQ1Action) => {
            state.qna2 = action.payload;
        },
        setStateFormConsumQnA3Pv: (state, action: UpdateQ1Action) => {
            state.qna3 = action.payload;
        },
        setStateFormConsumQnA4Pv: (state, action: UpdateQ1Action) => {
            state.qna4 = action.payload;
        },

        clearStateFormConsumPv: (state) => {
            state.qna1 = null;
            state.qna2 = null;
            state.qna3 = null;
            state.qna4 = null;
        }
    }
});

export const {
    setStateFormConsumQnA1Pv,
    setStateFormConsumQnA2Pv,
    setStateFormConsumQnA3Pv,
    setStateFormConsumQnA4Pv,
    clearStateFormConsumPv
} = actions;

export default reducer;
