import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type state = {
    isLoading: boolean;
};

const initialState: state = {
    isLoading: false
};

export const { actions, reducer } = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        setStateLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        clearStateLoading: (state) => {
            state.isLoading = false;
        }
    }
});

export const { setStateLoading, clearStateLoading } = actions;

export default reducer;
