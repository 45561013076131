import { alpha, createTheme, lighten, darken, keyframes } from '@mui/material';
import React from 'react';

const themeColors = {
    white: '#ffffff',
    black: '#000',
    primary: '#6A55E9',
    secondary: '#0C354F',
    primaryColor: '#6A55E9',
    secondaryColor: '#BEB5F6',
    textPrimary: '#2D3134',
    textSecondary: '#959799',
    textFooter: '#1A2020',
    backgroundPrimary: '#F5F5F5',
    backgroundSecondary: '#24212E',
    backgroundFooter: '#1A2020',
    linkFooter: '#D2E0E0',
    success: '#27AE60',
    warning: '#E2B93B',
    error: '#A94442',
    info: '#2F80ED',
    hoverBg: '#1e293a',
    activeBg: '#000',
    bodyColor: '#f2f5f9',
    textColor: '#eeeeee',
    grey: '#8d8d8d',
    grey2: '#8B9199',
    grey3: '#59656D',
    status1: '#52C6AA',
    status2: '#40BAD5',
    status3: '#5A8BEC',
    status4: '#8260E3',
    status5: '#CE75EE',
    status6: '#DFC644',
    status7: '#EC8E5A',
    status8: '#EC8E5A',
    status9: '#6FB956',
    status10: '#E96FB1',
    status11: '#EC8E5A',
    status12: '#E96FB1',
    status13: '#E96FB1',
    status14: '#F74B4B',
    status15: '#F74B4B',
    statusDefault: '#fff'
};

const colors = {
    gradients: {
        blue1: `linear-gradient(135deg, ${themeColors.primaryColor} 0%, #304399 100%)`,
        blue2: 'linear-gradient(135deg, #ABDCFF 0%, #0396FF 100%)',
        blue3: 'linear-gradient(127.55deg, #141E30 3.73%, #243B55 92.26%)',
        blue4: 'linear-gradient(-20deg, #2b5876 0%, #4e4376 100%)',
        blue5: 'linear-gradient(135deg, #97ABFF 10%, #123597 100%)',
        orange1: 'linear-gradient(135deg, #FCCF31 0%, #F55555 100%)',
        orange2: 'linear-gradient(135deg, #FFD3A5 0%, #FD6585 100%)',
        orange3: 'linear-gradient(120deg, #f6d365 0%, #fda085 100%)',
        purple1: 'linear-gradient(135deg, #43CBFF 0%, #9708CC 100%)',
        purple3: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)',
        pink1: 'linear-gradient(135deg, #F6CEEC 0%, #D939CD 100%)',
        pink2: 'linear-gradient(135deg, #F761A1 0%, #8C1BAB 100%)',
        green1: 'linear-gradient(135deg, #FFF720 0%, #3CD500 100%)',
        green2: 'linear-gradient(to bottom, #00b09b, #96c93d)',
        green3: 'linear-gradient(114deg, #8BDFDA 9.78%, #369C96 84.58%)',
        green4: 'linear-gradient(114deg, #369C96 9.78%, #8BDFDA 84.58%)',

        black1: 'linear-gradient(100.66deg, #434343 6.56%, #000000 93.57%)',
        black2: 'linear-gradient(60deg, #29323c 0%, #485563 100%)'
    },
    shadows: {
        success:
            '0px 1px 4px rgba(68, 214, 0, 0.25), 0px 3px 12px 2px rgba(68, 214, 0, 0.35)',
        error: '0px 1px 4px rgba(255, 25, 67, 0.25), 0px 3px 12px 2px rgba(255, 25, 67, 0.35)',
        info: '0px 1px 4px rgba(51, 194, 255, 0.25), 0px 3px 12px 2px rgba(51, 194, 255, 0.35)',
        primary:
            '0px 1px 4px rgba(85, 105, 255, 0.25), 0px 3px 12px 2px rgba(85, 105, 255, 0.35)',
        warning:
            '0px 1px 4px rgba(255, 163, 25, 0.25), 0px 3px 12px 2px rgba(255, 163, 25, 0.35)',
        card: '0px 9px 16px rgba(159, 162, 191, .18), 0px 2px 2px rgba(159, 162, 191, 0.32)',
        cardSm: '0px 2px 3px rgba(159, 162, 191, .18), 0px 1px 1px rgba(159, 162, 191, 0.32)',
        cardLg: '0 5rem 14rem 0 rgb(255 255 255 / 30%), 0 0.8rem 2.3rem rgb(0 0 0 / 60%), 0 0.2rem 0.3rem rgb(0 0 0 / 45%)'
    },
    layout: {
        general: {
            bodyBg: themeColors.bodyColor
        },
        sidebar: {
            bg: themeColors.backgroundSecondary,
            color: themeColors.textColor,
            hoverBg: alpha(themeColors.activeBg, 0.5),
            activeBg: alpha(themeColors.activeBg, 0.3),
            labelColor: alpha(themeColors.white, 0.5)
        },
        topbar: {
            bg: themeColors.white,
            color: themeColors.backgroundSecondary
        }
    },
    alpha: {
        white: {
            5: alpha(themeColors.white, 0.02),
            10: alpha(themeColors.white, 0.1),
            30: alpha(themeColors.white, 0.3),
            50: alpha(themeColors.white, 0.5),
            70: alpha(themeColors.white, 0.7),
            100: themeColors.white
        },
        trueWhite: {
            5: alpha(themeColors.white, 0.02),
            10: alpha(themeColors.white, 0.1),
            30: alpha(themeColors.white, 0.3),
            50: alpha(themeColors.white, 0.5),
            70: alpha(themeColors.white, 0.7),
            100: themeColors.white
        },
        black: {
            5: alpha(themeColors.backgroundSecondary, 0.02),
            10: alpha(themeColors.backgroundSecondary, 0.1),
            30: alpha(themeColors.backgroundSecondary, 0.3),
            50: alpha(themeColors.backgroundSecondary, 0.5),
            70: alpha(themeColors.backgroundSecondary, 0.7),
            100: themeColors.backgroundSecondary
        },
        grey: {
            5: alpha(themeColors.grey, 0.02),
            10: alpha(themeColors.grey, 0.1),
            30: alpha(themeColors.grey, 0.3),
            50: alpha(themeColors.grey, 0.5),
            70: alpha(themeColors.grey, 0.7),
            100: themeColors.grey
        }
    },

    primary: {
        lighter: lighten(themeColors.primaryColor, 0.85),
        light: lighten(themeColors.primaryColor, 0.3),
        main: themeColors.primaryColor,
        secondary: themeColors.secondaryColor,
        dark: darken(themeColors.primaryColor, 0.2),
        background: themeColors.backgroundPrimary,
        text: themeColors.textPrimary
    },
    secondary: {
        lighter: lighten(themeColors.secondary, 0.85),
        light: lighten(themeColors.secondary, 0.25),
        main: themeColors.secondary,
        dark: darken(themeColors.secondary, 0.2),
        background: themeColors.backgroundSecondary,
        text: themeColors.textSecondary
    },
    footer: {
        lighter: lighten(themeColors.linkFooter, 0.85),
        light: lighten(themeColors.linkFooter, 0.25),
        main: themeColors.linkFooter,
        dark: darken(themeColors.linkFooter, 0.2),
        background: themeColors.backgroundFooter,
        text: themeColors.textFooter
    },
    success: {
        lighter: lighten(themeColors.success, 0.85),
        light: lighten(themeColors.success, 0.3),
        main: themeColors.success,
        dark: darken(themeColors.success, 0.2)
    },
    warning: {
        lighter: lighten(themeColors.warning, 0.85),
        light: lighten(themeColors.warning, 0.3),
        main: themeColors.warning,
        dark: darken(themeColors.warning, 0.2)
    },
    error: {
        lighter: lighten(themeColors.error, 0.85),
        light: lighten(themeColors.error, 0.3),
        main: themeColors.error,
        dark: darken(themeColors.error, 0.2)
    },
    info: {
        lighter: lighten(themeColors.info, 0.85),
        light: lighten(themeColors.info, 0.3),
        main: themeColors.info,
        dark: darken(themeColors.info, 0.2)
    },
    status1: {
        lighter: lighten(themeColors.status1, 0.85),
        light: lighten(themeColors.status1, 0.3),
        main: themeColors.status1,
        dark: darken(themeColors.status1, 0.2)
    },
    status2: {
        lighter: lighten(themeColors.status2, 0.85),
        light: lighten(themeColors.status2, 0.3),
        main: themeColors.status2,
        dark: darken(themeColors.status2, 0.2)
    },
    status3: {
        lighter: lighten(themeColors.status3, 0.85),
        light: lighten(themeColors.status3, 0.3),
        main: themeColors.status3,
        dark: darken(themeColors.status3, 0.2)
    },
    status4: {
        lighter: lighten(themeColors.status4, 0.85),
        light: lighten(themeColors.status4, 0.3),
        main: themeColors.status4,
        dark: darken(themeColors.status4, 0.2)
    },
    status5: {
        lighter: lighten(themeColors.status5, 0.85),
        light: lighten(themeColors.status5, 0.3),
        main: themeColors.status5,
        dark: darken(themeColors.status5, 0.2)
    },
    status6: {
        lighter: lighten(themeColors.status6, 0.85),
        light: lighten(themeColors.status6, 0.3),
        main: themeColors.status6,
        dark: darken(themeColors.status6, 0.2)
    },
    status7: {
        lighter: lighten(themeColors.status7, 0.85),
        light: lighten(themeColors.status7, 0.3),
        main: themeColors.status7,
        dark: darken(themeColors.status7, 0.2)
    },
    status8: {
        lighter: lighten(themeColors.status8, 0.85),
        light: lighten(themeColors.status8, 0.3),
        main: themeColors.status8,
        dark: darken(themeColors.status8, 0.2)
    },
    status9: {
        lighter: lighten(themeColors.status9, 0.85),
        light: lighten(themeColors.status9, 0.3),
        main: themeColors.status9,
        dark: darken(themeColors.status9, 0.2)
    },
    status10: {
        lighter: lighten(themeColors.status10, 0.85),
        light: lighten(themeColors.status10, 0.3),
        main: themeColors.status10,
        dark: darken(themeColors.status10, 0.2)
    },
    status11: {
        lighter: lighten(themeColors.status11, 0.85),
        light: lighten(themeColors.status11, 0.3),
        main: themeColors.status11,
        dark: darken(themeColors.status11, 0.2)
    },
    status12: {
        lighter: lighten(themeColors.status12, 0.85),
        light: lighten(themeColors.status12, 0.3),
        main: themeColors.status12,
        dark: darken(themeColors.status12, 0.2)
    },
    status13: {
        lighter: lighten(themeColors.status13, 0.85),
        light: lighten(themeColors.status13, 0.3),
        main: themeColors.status13,
        dark: darken(themeColors.status13, 0.2)
    },
    status14: {
        lighter: lighten(themeColors.status14, 0.85),
        light: lighten(themeColors.status14, 0.3),
        main: themeColors.status14,
        dark: darken(themeColors.status14, 0.2)
    },
    status15: {
        lighter: lighten(themeColors.status15, 0.85),
        light: lighten(themeColors.status15, 0.3),
        main: themeColors.status15,
        dark: darken(themeColors.status15, 0.2)
    },
    statusDefault: {
        lighter: lighten(themeColors.statusDefault, 0.85),
        light: lighten(themeColors.statusDefault, 0.3),
        main: themeColors.statusDefault,
        dark: darken(themeColors.statusDefault, 0.2)
    }
};

export const PvBaseTheme = createTheme({
    // direction: i18n.dir(),
    colors: {
        gradients: {
            blue1: colors.gradients.blue1,
            blue2: colors.gradients.blue2,
            blue3: colors.gradients.blue3,
            blue4: colors.gradients.blue4,
            blue5: colors.gradients.blue5,
            orange1: colors.gradients.orange1,
            orange2: colors.gradients.orange2,
            orange3: colors.gradients.orange3,
            purple1: colors.gradients.purple1,
            purple3: colors.gradients.purple3,
            pink1: colors.gradients.pink1,
            pink2: colors.gradients.pink2,
            green1: colors.gradients.green1,
            green2: colors.gradients.green2,
            green3: colors.gradients.green3,
            green4: colors.gradients.green4,

            black1: colors.gradients.black1,
            black2: colors.gradients.black2,

            primary: 'linear-gradient(135deg, #BEB5F6 0%, #6A55E9 100%)',

            secondary: 'linear-gradient(135deg, #6A55E9 0%, #BEB5F6 100%)',

            merge: 'linear-gradient(90deg, #369C96 0%, #418EA7 34.9%, #4B7FB8 62.5%, #6A55E9 100%)'
        },
        shadows: {
            success: colors.shadows.success,
            error: colors.shadows.error,
            primary: colors.shadows.primary,
            info: colors.shadows.info,
            warning: colors.shadows.warning
        },
        alpha: {
            white: {
                5: alpha(themeColors.white, 0.02),
                10: alpha(themeColors.white, 0.1),
                30: alpha(themeColors.white, 0.3),
                50: alpha(themeColors.white, 0.5),
                70: alpha(themeColors.white, 0.7),
                100: themeColors.white
            },
            trueWhite: {
                5: alpha(themeColors.white, 0.02),
                10: alpha(themeColors.white, 0.1),
                30: alpha(themeColors.white, 0.3),
                50: alpha(themeColors.white, 0.5),
                70: alpha(themeColors.white, 0.7),
                100: themeColors.white
            },
            black: {
                5: alpha(themeColors.backgroundSecondary, 0.02),
                10: alpha(themeColors.backgroundSecondary, 0.1),
                30: alpha(themeColors.backgroundSecondary, 0.3),
                50: alpha(themeColors.backgroundSecondary, 0.5),
                70: alpha(themeColors.backgroundSecondary, 0.7),
                100: themeColors.backgroundSecondary
            },
            grey: {
                5: alpha(themeColors.grey, 0.02),
                10: alpha(themeColors.grey, 0.1),
                30: alpha(themeColors.grey, 0.3),
                50: alpha(themeColors.grey, 0.5),
                70: alpha(themeColors.grey, 0.7),
                100: themeColors.grey
            }
        },
        secondary: {
            lighter: alpha(themeColors.secondary, 0.1),
            light: lighten(themeColors.secondary, 0.3),
            main: themeColors.secondary,
            dark: darken(themeColors.secondary, 0.2),
            text: themeColors.textSecondary,
            background: themeColors.backgroundSecondary
        },
        primary: {
            lighter: alpha(themeColors.primaryColor, 0.1),
            light: lighten(themeColors.primaryColor, 0.3),
            main: themeColors.primaryColor,
            dark: darken(themeColors.primaryColor, 0.2),
            grey: themeColors.grey2,
            text: themeColors.textPrimary,
            background: themeColors.backgroundPrimary,
            secondary: themeColors.secondaryColor
        },
        footer: {
            lighter: alpha(themeColors.linkFooter, 0.1),
            light: lighten(themeColors.linkFooter, 0.3),
            main: themeColors.linkFooter,
            dark: darken(themeColors.linkFooter, 0.2),
            text: themeColors.textFooter,
            background: themeColors.backgroundFooter
        },
        success: {
            lighter: alpha(themeColors.success, 0.1),
            light: lighten(themeColors.success, 0.3),
            main: themeColors.success,
            dark: darken(themeColors.success, 0.2)
        },
        warning: {
            lighter: alpha(themeColors.warning, 0.1),
            light: lighten(themeColors.warning, 0.3),
            main: themeColors.warning,
            dark: darken(themeColors.warning, 0.2)
        },
        error: {
            lighter: alpha(themeColors.error, 0.1),
            light: lighten(themeColors.error, 0.3),
            main: themeColors.error,
            dark: darken(themeColors.error, 0.2)
        },
        info: {
            lighter: alpha(themeColors.info, 0.1),
            light: lighten(themeColors.info, 0.3),
            main: themeColors.info,
            dark: darken(themeColors.info, 0.2)
        },
        status1: {
            lighter: lighten(themeColors.status1, 0.85),
            light: lighten(themeColors.status1, 0.3),
            main: themeColors.status1,
            dark: darken(themeColors.status1, 0.2)
        },
        status2: {
            lighter: lighten(themeColors.status2, 0.85),
            light: lighten(themeColors.status2, 0.3),
            main: themeColors.status2,
            dark: darken(themeColors.status2, 0.2)
        },
        status3: {
            lighter: lighten(themeColors.status3, 0.85),
            light: lighten(themeColors.status3, 0.3),
            main: themeColors.status3,
            dark: darken(themeColors.status3, 0.2)
        },
        status4: {
            lighter: lighten(themeColors.status4, 0.85),
            light: lighten(themeColors.status4, 0.3),
            main: themeColors.status4,
            dark: darken(themeColors.status4, 0.2)
        },
        status5: {
            lighter: lighten(themeColors.status5, 0.85),
            light: lighten(themeColors.status5, 0.3),
            main: themeColors.status5,
            dark: darken(themeColors.status5, 0.2)
        },
        status6: {
            lighter: lighten(themeColors.status6, 0.85),
            light: lighten(themeColors.status6, 0.3),
            main: themeColors.status6,
            dark: darken(themeColors.status6, 0.2)
        },
        status7: {
            lighter: lighten(themeColors.status7, 0.85),
            light: lighten(themeColors.status7, 0.3),
            main: themeColors.status7,
            dark: darken(themeColors.status7, 0.2)
        },
        status8: {
            lighter: lighten(themeColors.status8, 0.85),
            light: lighten(themeColors.status8, 0.3),
            main: themeColors.status8,
            dark: darken(themeColors.status8, 0.2)
        },
        status9: {
            lighter: lighten(themeColors.status9, 0.85),
            light: lighten(themeColors.status9, 0.3),
            main: themeColors.status9,
            dark: darken(themeColors.status9, 0.2)
        },
        status10: {
            lighter: lighten(themeColors.status10, 0.85),
            light: lighten(themeColors.status10, 0.3),
            main: themeColors.status10,
            dark: darken(themeColors.status10, 0.2)
        },
        status11: {
            lighter: lighten(themeColors.status11, 0.85),
            light: lighten(themeColors.status11, 0.3),
            main: themeColors.status11,
            dark: darken(themeColors.status11, 0.2)
        },
        status12: {
            lighter: lighten(themeColors.status12, 0.85),
            light: lighten(themeColors.status12, 0.3),
            main: themeColors.status12,
            dark: darken(themeColors.status12, 0.2)
        },
        status13: {
            lighter: lighten(themeColors.status13, 0.85),
            light: lighten(themeColors.status13, 0.3),
            main: themeColors.status13,
            dark: darken(themeColors.status13, 0.2)
        },
        status14: {
            lighter: lighten(themeColors.status14, 0.85),
            light: lighten(themeColors.status14, 0.3),
            main: themeColors.status14,
            dark: darken(themeColors.status14, 0.2)
        },
        status15: {
            lighter: lighten(themeColors.status15, 0.85),
            light: lighten(themeColors.status15, 0.3),
            main: themeColors.status15,
            dark: darken(themeColors.status15, 0.2)
        },
        statusDefault: {
            lighter: lighten(themeColors.statusDefault, 0.85),
            light: lighten(themeColors.statusDefault, 0.3),
            main: themeColors.statusDefault,
            dark: darken(themeColors.statusDefault, 0.2)
        }
    },
    general: {
        reactFrameworkColor: colors.layout.general.bodyBg,
        borderRadiusSm: '6px',
        borderRadius: '10px',
        borderRadiusLg: '12px',
        borderRadiusXl: '16px'
    },
    sidebar: {
        bg: colors.layout.sidebar.bg,
        color: colors.layout.sidebar.color,
        hoverBg: colors.layout.sidebar.hoverBg,
        activeBg: colors.layout.sidebar.activeBg,
        labelColor: colors.layout.sidebar.labelColor,
        width: '290px'
    },
    topbar: {
        bg: colors.layout.topbar.bg,
        color: colors.layout.topbar.color
    },
    header: {
        height: '80px',
        background: colors.alpha.white[100],
        boxShadow: colors.shadows.cardSm,
        textColor: colors.secondary.main
    },
    modalStyle: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        bgcolor: 'background.paper',
        border: '2px solid ' + colors.alpha.white[100],
        borderRadius: '20px',
        boxShadow: 24,
        p: 4
    },
    spacing: 9,
    palette: {
        common: {
            black: colors.alpha.black[100],
            white: colors.alpha.white[100]
        },
        mode: 'light',
        primary: {
            light: colors.primary.light,
            main: colors.primary.main,
            dark: colors.primary.dark
        },
        secondary: {
            light: colors.secondary.light,
            main: colors.secondary.main,
            dark: colors.secondary.dark
        },
        error: {
            light: colors.error.light,
            main: colors.error.main,
            dark: colors.error.dark,
            contrastText: colors.alpha.white[100]
        },
        success: {
            light: colors.success.light,
            main: colors.success.main,
            dark: colors.success.dark,
            contrastText: colors.alpha.white[100]
        },
        info: {
            light: colors.info.light,
            main: colors.info.main,
            dark: colors.info.dark,
            contrastText: colors.alpha.white[100]
        },
        warning: {
            light: colors.warning.light,
            main: colors.warning.main,
            dark: colors.warning.dark,
            contrastText: colors.alpha.white[100]
        },
        text: {
            primary: colors.alpha.black[100],
            secondary: colors.alpha.black[70],
            disabled: colors.alpha.black[50]
        },
        background: {
            paper: colors.alpha.white[100],
            default: colors.layout.general.bodyBg
        },
        action: {
            active: colors.alpha.black[100],
            hover: colors.primary.lighter,
            hoverOpacity: 0.1,
            selected: colors.alpha.black[10],
            selectedOpacity: 0.1,
            disabled: colors.alpha.black[50],
            disabledBackground: colors.alpha.black[5],
            disabledOpacity: 0.38,
            focus: colors.alpha.black[10],
            focusOpacity: 0.05,
            activatedOpacity: 0.12
        },
        tonalOffset: 0.5
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1840
        }
    },
    shape: {
        borderRadius: 10
    },
    typography: {
        fontFamily: ['poppins'].join(','),
        h1: {
            fontWeight: 700,
            fontSize: 40
        },
        h2: {
            fontWeight: 700,
            fontSize: 27
        },
        h3: {
            fontWeight: 700,
            fontSize: 20
        },
        h4: {
            fontWeight: 700,
            fontSize: 16
        },
        h5: {
            fontWeight: 700,
            fontSize: 14
        },
        h6: {
            fontSize: 15
        },
        body1: {
            fontSize: 14
        },
        body2: {
            fontSize: 18
        },

        button: {
            fontWeight: 600
        },
        caption: {
            fontSize: 13,
            textTransform: 'uppercase',
            color: colors.alpha.black[50]
        },
        subtitle1: {
            fontSize: 14,
            color: colors.alpha.black[70]
        },
        subtitle2: {
            fontWeight: 400,
            fontSize: 15,
            color: colors.alpha.black[70]
        },
        overline: {
            fontSize: 13,
            fontWeight: 700,
            textTransform: 'uppercase'
        }
    },
    boxShadow: {
        first: '0px 8px 16px 0px rgba(141, 163, 160, 0.1)'
    },
    animations: {
        speed: {
            verySlow: 3000,
            slow: 2000,
            average: 1000,
            fast: 500,
            veryFast: 300
        },
        fadeIn: keyframes`
            from {
                opacity: 0;
            }
        
            to {
                opacity: 1;
            }
        `,
        zoomIn: keyframes`
            from {
                opacity: 0;
                transform: scale(0.25);
            }
        
            to {
                opacity: 1;
                transform: scale(1);
            }
        `,

        zoomInReverse: keyframes`
            from {
                opacity: 1;
                transform: scale(1);
            }
        
            to {
                opacity: 0.;
                transform: scale(0.25);
            }
        `,
        zoomOut: keyframes`
            from {
                opacity: 0;
                transform: scale(1.5);
            }
        
            to {
                opacity: 1;
                transform: scale(1);
            }
        `,
        zoomInFromBottom: keyframes`
            from {
                opacity: 0;
                transform: scale(0.5) translateY(100%);
            }
        
            to {
                opacity: 1;
                transform: scale(1) translateY(0);
            }
        `
    },
    shadows: [
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none'
    ]
});
