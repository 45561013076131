import {api} from "./config";

export const meProfile = async () => {
    try {
        const response = await api.get('api/security/me');
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI::api/security/me');
    }
};
