import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Vehicle, VehicleStore } from '../../../models/common/vehicle.models';

type UpdateVehiclesAction = PayloadAction<Vehicle[]>;

const initialState: VehicleStore = {
    vehicles: null
};

const vehicleSlice = createSlice({
    name: 'vehicleSlice',
    initialState,
    reducers: {
        setStateVehiclesListIrve: (state, action: UpdateVehiclesAction) => {
            state.vehicles = action.payload;
        },
        clearStateVehicleIrve: (state) => {
            state.vehicles = null;
        }
    }
});

export const { setStateVehiclesListIrve, clearStateVehicleIrve } =
    vehicleSlice.actions;

export default vehicleSlice.reducer;
