import { Outlet } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';
import BackgroundLayoutComparator from '@/components/comparator/BackgroundLayout';
import Header from '@/components/comparator/Header';
import { useSelector } from 'react-redux';
import { selectRoutesIrvePV } from '@/store/selector';

const ComparatorLayout = () => {
    const { route } = useSelector(selectRoutesIrvePV);
    return (
        <Box
            sx={{
                position: 'relative',
                backgroundColor:
                    route === 'irve' ? '#FAFEFF' : 'rgb(250,250,255)'
            }}
        >
            <Box
                sx={{
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    zIndex: '2',
                    position: 'relative'
                }}
            >
                <Header />
                <Box
                    component="main"
                    padding={{ xs: '0', lg: '0px 120px' }}
                    sx={{
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <Outlet />
                </Box>
            </Box>

            <BackgroundLayoutComparator />
        </Box>
    );
};

export default ComparatorLayout;
