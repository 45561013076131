import { lazy } from 'react';
import Loader from '@/components/common/Loader';

export const AdminsRoute = Loader(
    lazy(() => import('@/pages/dashboard/Admins/pv/Admins'))
);

export const AdminsProfileRoute = Loader(
    lazy(() => import('@/pages/dashboard/Admins/pv/Profile/AdminProfile'))
);

export const AdminAddRoute = Loader(
    lazy(() => import('@/pages/dashboard/Admins/pv/Add/AdminAdd'))
);
