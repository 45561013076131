import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
// import MainLayout from "./layouts/MainLayout";
import ComparatorLayout from './layouts/ComparatorLayout';
import CustomerLayout from './layouts/CustomerLayout';
import InstallerLayout from './layouts/InstallerLayout';
import { routes } from './routes';
import React, { useEffect, useRef } from 'react';
import { themeCreator } from './theme/base';
import { ThemeProvider } from '@mui/material';
import LoginLayout from './layouts/LoginLayout';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './store';
import { setStateLayout } from './store/reducers/irve/layout.slice';
import DashboardLayout from './layouts/DashboardLayout';
import { selectLayout, selectRoutesIrvePV, selectUser } from './store/selector';
import { setStateRouteIrvePv } from './store/reducers/routesIrvePv.slice';

function App() {
    const irveTheme = themeCreator('IrveTheme');
    const pvTheme = themeCreator('PvTheme');

    const { user } = useSelector((state: RootState) => selectUser(state));
    const { layoutName } = useSelector((state: RootState) =>
        selectLayout(state)
    );
    const { route } = useSelector((state: RootState) =>
        selectRoutesIrvePV(state)
    );

    const dispatch = useDispatch();

    const { pathname } = window.location;

    const isMounted = useRef(false);

    useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true;
            if (pathname.includes('comparator')) {
                dispatch(setStateLayout('comparator'));
            } else if (pathname.includes('login')) {
                dispatch(setStateLayout('login'));
            } else {
                if (user) {
                    //console.log('App::user::state => ', user.roles[0]);
                    if (user.roles[0] === 'ROLE_ADMIN') {
                        dispatch(setStateLayout('admin'));
                    } else if (user.roles[0] === 'ROLE_INSTALLER') {
                        dispatch(setStateLayout('installer'));
                    } else {
                        dispatch(setStateLayout('customer'));
                    }
                }
            }

            if (pathname.includes('/irve/')) {
                dispatch(setStateRouteIrvePv('irve'));
            } else if (pathname.includes('/pv/')) {
                dispatch(setStateRouteIrvePv('pv'));
            }
        }
    }, [user, route]);

    const getLayout = () => {
        switch (layoutName) {
            case 'comparator':
                return <ComparatorLayout />;
            case 'login':
                return <LoginLayout />;
            case 'installer':
                return <InstallerLayout />;
            case 'admin':
                return <DashboardLayout />;
            case 'customer':
                return <CustomerLayout />;
            default:
                return <ComparatorLayout />;
        }
    };

    return (
        <ThemeProvider theme={route === 'irve' ? irveTheme : pvTheme}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={getLayout()}>
                        {routes}
                    </Route>

                    <Route path="*" element={<div></div>} />
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
