import { lazy } from 'react';
import Loader from '@/components/common/Loader';

export const ProjectRoute = Loader(
    lazy(() => import('@/pages/accountCustomer/irve/Project'))
);

export const MeRoute = Loader(
    lazy(() => import('@/pages/accountCustomer/irve/Me'))
);

export const DocumentsRoute = Loader(
    lazy(() => import('@/pages/accountCustomer/irve/Documents'))
);

export const RecapRoute = Loader(
    lazy(() => import('@/pages/accountCustomer/irve/Recap'))
);

export const MessengerRoute = Loader(
    lazy(() => import('@/pages/accountCustomer/irve/Messenger'))
);

export const YousignRoute = Loader(
    lazy(() => import('@/pages/accountCustomer/irve/Yousign'))
);

export const ChangeStationsRoute = Loader(
    lazy(() => import('@/pages/accountCustomer/irve/ChangeStations'))
);

export const ChangeInstallersRoute = Loader(
    lazy(() => import('@/pages/accountCustomer/irve/ChangeInstallers'))
);

export const ContactRoute = Loader(
    lazy(() => import('@/pages/accountCustomer/irve/Contact'))
);
