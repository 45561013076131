import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
    Notifications,
    NotificationStore
} from '../../../models/irve/notification.models.type';

type UpdateNotificationsAction = PayloadAction<Notifications | null>;

const initialState: NotificationStore = {
    notifications: null
};

export const notificationSlice = createSlice({
    name: 'notificationSlice',
    initialState,
    reducers: {
        setStateNotificationsIrve: (
            state,
            action: UpdateNotificationsAction
        ) => {
            state.notifications = action.payload;
        },
        clearStateNotificationIrve: (state) => {
            state.notifications = null;
        }
    }
});

export const { setStateNotificationsIrve, clearStateNotificationIrve } =
    notificationSlice.actions;

export default notificationSlice.reducer;
