import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type routeIrvePvProp = {
    route: 'irve' | 'pv';
};

const initialState: routeIrvePvProp = {
    route: 'irve'
};

export const { actions, reducer } = createSlice({
    name: 'routeIrvePvSlice',
    initialState,
    reducers: {
        setStateRouteIrvePv: (state, action: PayloadAction<'irve' | 'pv'>) => {
            state.route = action.payload;
        },
        clearStateRoutesIrvePv: (state) => {
            state.route = 'irve';
        }
    }
});

export const { setStateRouteIrvePv, clearStateRoutesIrvePv } = actions;

export default reducer;
