import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
    AccessoryInstaller,
    AccessoryInstallerInstaller,
    AccessoryStationTriphasedInstaller,
    AccessoryStationMonophasedInstaller,
    AccessoryStore
} from '@/models/irve/accessory.models.type';

type UpdateAccessoriesInstallerAction = PayloadAction<AccessoryInstaller[]>;
type UpdateAccessoriesInstallerInstallerAction = PayloadAction<
    AccessoryInstallerInstaller[]
>;
type UpdateAccessoryStationAction = PayloadAction<
    AccessoryStationTriphasedInstaller | AccessoryStationMonophasedInstaller
>;

const initialState: AccessoryStore = {
    accessoriesInstaller: null,
    selectedAccessoriesInstaller: null,
    selectedAccessoriesInstallerInstaller: null,
    selectedAccessoryStation: null
};

const { actions, reducer } = createSlice({
    name: 'accessoryInstaller',
    initialState,
    reducers: {
        setStateAccessoriesInstallerIrve: (
            state,
            action: UpdateAccessoriesInstallerAction
        ) => {
            state.accessoriesInstaller = action.payload;
        },
        setStateSelectedAccessoriesInstallerIrve: (
            state,
            action: UpdateAccessoriesInstallerAction
        ) => {
            state.selectedAccessoriesInstaller = action.payload;
        },
        setStateSelectedAccessoriesInstallerInstallerIrve: (
            state,
            action: UpdateAccessoriesInstallerInstallerAction
        ) => {
            state.selectedAccessoriesInstallerInstaller = action.payload;
        },
        setStateSelectedAccessoryStationIrve: (
            state,
            action: UpdateAccessoryStationAction
        ) => {
            state.selectedAccessoryStation = action.payload;
        },
        clearStateAccessoriesIrve: (state) => {
            state.accessoriesInstaller = null;
            state.selectedAccessoriesInstaller = null;
            state.selectedAccessoriesInstallerInstaller = null;
            state.selectedAccessoryStation = null;
        }
    }
});

export const {
    setStateAccessoriesInstallerIrve,
    setStateSelectedAccessoriesInstallerIrve,
    setStateSelectedAccessoriesInstallerInstallerIrve,
    setStateSelectedAccessoryStationIrve,
    clearStateAccessoriesIrve
} = actions;

export default reducer;
