import { RouteType } from '@/models/common/route.models';

import {
    AdminPanelSettingsTwoTone,
    Apartment,
    ApartmentTwoTone,
    AppSettingsAlt,
    Build,
    BusinessTwoTone,
    DashboardCustomizeTwoTone,
    ElectricalServices,
    EvStationTwoTone,
    HandymanTwoTone,
    HomeTwoTone,
    ManageAccountsTwoTone,
    SolarPower,
    TransitEnterexit
} from '@mui/icons-material';

import {
    AccessoriesRoute,
    AccessoryInstallerAddRoute,
    AccessoryInstallerProfileRoute,
    AccessoryStationMonophasedAddRoute,
    AccessoryStationMonophasedProfileRoute,
    AccessoryStationTriphasedAddRoute,
    AccessoryStationTriphasedProfileRoute
} from './Accessories.routes';

import { StationAddRoute, StationProfileRoute, StationsRoute } from './Stations.routes';

import { OverviewsRoute } from './Overview.routes';

import { AdminAddRoute, AdminsProfileRoute, AdminsRoute } from './Admin.routes';

import {
    ApartmentsArchivedRoute,
    ApartmentsOngoingProfileChangeInstallersRoute,
    ApartmentsOngoingProfileChangeStationsRoute,
    ApartmentsOngoingProfileRoute,
    ApartmentsOngoingRoute
} from './Apartments.routes';

import { HousesArchivedRoute, HousesOngoingProfileChangeInstallersRoute, HousesOngoingProfileChangeStationsRoute, HousesOngoingProfileRoute, HousesOngoingRoute } from './Houses.routes';

import PageLayout from '@/layouts/PageLayout';

import { B2CInvoicePreviewRoute, B2CMessengerRoute } from './B2C.routes';

import { B2BAddRoute, B2BArchivedRoute, B2BInstallersUpdateRoute, B2BMessengerRoute, B2BOnGoingRoute, B2BProfileRoute } from './B2B.routes';

import { ManagerAddRoute, ManagersProfileRoute, ManagersRoute } from './Manager.routes';

import { ProjectManagerAddRoute, ProjectManagersProfileRoute, ProjectManagersRoute } from './ProjectManagers.routes';

import {
    InstallerAddAccessoriesRoute,
    InstallerAddDepartmentsProfileRoute,
    InstallerAddDepartmentsRoute,
    InstallerAddInstallationsRoute,
    InstallerAddLegalInformationsRoute,
    InstallerAddRoute,
    InstallerAddStationsRoute,
    InstallerProfileAddStationRoute,
    InstallerProfileRoute,
    InstallersRoute,
    InstallerAddSolarPanels,
    InstallerRegionsAddRoute
} from './Installer.routes';
import {
    boAccessoriesPathsPv,
    boAdminsPathsPv,
    boB2BPathsPv,
    boB2CApartmentPathsPv,
    boB2CHousePathsPv,
    boB2CInvoicePathsPv,
    boInstallersPathsPv,
    boManagersPathsPv,
    boOverviewsPathsPv,
    boPmsPathsPv,
    boSolarPanelsPathsPv
} from '@/routes/routesPathsIPV';
import { SolarPanels, SolarPanelsProfile, SolarPanelsAdd } from './SolarPanels.routes';

export const DashboardRoutesPv: RouteType[] = [
    {
        path: boOverviewsPathsPv.index,
        element: <OverviewsRoute />,
        state: 'dashboard.overview',
        sidebarProps: {
            displayText: 'Accueil',
            icon: <DashboardCustomizeTwoTone />
        },
        show: true,
        index: false,
        child: null,
        label: null
    },

    {
        path: undefined,
        element: null,
        state: 'undefined',
        sidebarProps: null,
        show: true,
        index: null,
        child: null,
        label: 'Opportunités'
    },

    {
        path: boB2CHousePathsPv.index,
        element: <HousesOngoingRoute />,
        state: 'dashboard.boOpportunitiesB2CHousePathsPv.index',
        sidebarProps: {
            displayText: 'B2C',
            icon: <Apartment />
        },
        show: true,
        label: null,
        child: null,
        index: null
    },
    {
        path: boB2CHousePathsPv.onGoingProfile,
        element: <HousesOngoingProfileRoute />,
        state: 'boOpportunitiesB2CHousePathsPv.onGoingProfile',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boB2CHousePathsPv.changeOffer,
        element: <HousesOngoingProfileChangeStationsRoute />,
        state: 'boOpportunitiesB2CHousePathsPv.onGoingProfileChangeStation',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boB2CHousePathsPv.changeInstaller,
        element: <HousesOngoingProfileChangeInstallersRoute />,
        state: 'boOpportunitiesB2CHousePathsPv.onGoingProfileChangeInstaller',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boB2CHousePathsPv.messenger,
        element: <B2CMessengerRoute />,
        state: 'boOpportunitiesB2CHousePathsPv.Messenger',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },

    {
        path: boB2CApartmentPathsPv.onGoingProfile,
        element: <ApartmentsOngoingProfileRoute />,
        state: 'boOpportunitiesB2CApartmentPathsPv.onGoingProfile',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boB2CApartmentPathsPv.changeStation,
        element: <ApartmentsOngoingProfileChangeStationsRoute />,
        state: 'boOpportunitiesB2CApartmentPathsPv.onGoingProfileChangeStation',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boB2CApartmentPathsPv.changeInstaller,
        element: <ApartmentsOngoingProfileChangeInstallersRoute />,
        state: 'boOpportunitiesB2CApartmentPathsPv.onGoingProfileChangeInstaller',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },

    {
        path: boB2CApartmentPathsPv.messenger,
        element: <B2CMessengerRoute />,
        state: 'boOpportunitiesB2CApartmentPathsPv.Messenger',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boB2CInvoicePathsPv.preview,
        element: <B2CInvoicePreviewRoute />,
        state: 'boOpportunitiesB2CInvoicePathsPv.preview',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },

    /*     {
        path: boB2BPathsPv.index,
        element: <PageLayout />,
        state: 'dashboard.boOpportunitiesB2BPathsPv.index',
        sidebarProps: {
            displayText: 'B2B',
            icon: <BusinessTwoTone />
        },
        show: true,
        label: null,
        child: [
            {
                path: boB2BPathsPv.onGoing,
                element: <B2BOnGoingRoute />,
                state: 'dashboard.boOpportunitiesB2BPathsPv.onGoing',
                sidebarProps: {
                    displayText: 'En cours',
                    icon: null
                },
                show: true,
                label: null,
                child: null,
                index: null
            },
            {
                path: boB2BPathsPv.archived,
                element: <B2BArchivedRoute />,
                state: 'dashboard.boOpportunitiesB2BPathsPv.archived',
                sidebarProps: {
                    displayText: 'Archivées',
                    icon: null
                },
                show: true,
                label: null,
                child: null,
                index: null
            }
        ],
        index: null
    }, */
    {
        path: boB2BPathsPv.studyAdd,
        element: <B2BAddRoute />,
        state: 'boOpportunitiesB2BPathsPv.studyAdd',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boB2BPathsPv.onGoingProfile,
        element: <B2BProfileRoute />,
        state: 'boOpportunitiesB2BPathsPv.onGoingProfile',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boB2BPathsPv.messenger,
        element: <B2BMessengerRoute />,
        state: 'boOpportunitiesB2BPathsPv.messenger',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boB2BPathsPv.installersUpdate,
        element: <B2BInstallersUpdateRoute />,
        state: 'boOpportunitiesB2BPathsPv.installersUpdate',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },

    {
        path: undefined,
        element: null,
        state: 'undefined',
        sidebarProps: null,
        show: true,
        index: null,
        child: null,
        label: 'Utilisateurs'
    },

    {
        path: boAdminsPathsPv.admins,
        element: <AdminsRoute />,
        state: 'dashboard.boAdminsPathsPv.admins',
        sidebarProps: {
            displayText: 'Admins',
            icon: <AdminPanelSettingsTwoTone />
        },
        show: true,
        index: false,
        child: null,
        label: null
    },
    {
        path: boAdminsPathsPv.adminsProfile,
        element: <AdminsProfileRoute />,
        state: 'dashboard.boAdminsPathsPv.adminsProfile',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boAdminsPathsPv.adminAdd,
        element: <AdminAddRoute />,
        state: 'dashboard.boAdminsPathsPv.adminAdd',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },

    {
        path: boManagersPathsPv.managers,
        element: <ManagersRoute />,
        state: 'dashboard.boManagersPathsPv.managers',
        sidebarProps: {
            displayText: 'Managers',
            icon: <AdminPanelSettingsTwoTone />
        },
        show: true,
        index: false,
        child: null,
        label: null
    },
    {
        path: boManagersPathsPv.managersProfile,
        element: <ManagersProfileRoute />,
        state: 'dashboard.boManagersPathsPv.managersProfile',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boManagersPathsPv.managerAdd,
        element: <ManagerAddRoute />,
        state: 'dashboard.boManagersPathsPv.managerAdd',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },

    {
        path: boPmsPathsPv.pms,
        element: <ProjectManagersRoute />,
        state: 'dashboard.boAdminsPathsPv.pms',
        sidebarProps: {
            displayText: 'Project Managers',
            icon: <ManageAccountsTwoTone />
        },
        show: true,
        index: false,
        child: null,
        label: null
    },
    {
        path: boPmsPathsPv.pmsProfile,
        element: <ProjectManagersProfileRoute />,
        state: 'dashboard.boPmsPathsPv.pmsProfile',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boPmsPathsPv.pmAdd,
        element: <ProjectManagerAddRoute />,
        state: 'dashboard.boPmsPathsPv.pmAdd',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },

    {
        path: boInstallersPathsPv.installers,
        element: <InstallersRoute />,
        state: 'dashboard.boInstallersPathsPv.installers',
        sidebarProps: {
            displayText: 'Installateurs',
            icon: <ManageAccountsTwoTone />
        },
        show: true,
        index: false,
        child: null,
        label: null
    },
    {
        path: boInstallersPathsPv.installerAdd,
        element: <InstallerAddRoute />,
        state: 'dashboard.boInstallersPathsPv.installerAdd',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boInstallersPathsPv.installerAddLegalInformations,
        element: <InstallerAddLegalInformationsRoute />,
        state: 'dashboard.boInstallersPathsPv.installerAddLegalInformations',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boInstallersPathsPv.installerAddLSelectedStations,
        element: <InstallerAddStationsRoute />,
        state: 'dashboard.boInstallersPathsPv.installerAddLSelectedStations',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boInstallersPathsPv.installerAddSolarPanels,
        element: <InstallerAddSolarPanels />,
        state: 'dashboard.boInstallersPathsPv.installerAddSolarPanels',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boInstallersPathsPv.installerAddInstallations,
        element: <InstallerAddInstallationsRoute />,
        state: 'dashboard.boInstallersPathsPv.installerAddInstallations',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boInstallersPathsPv.installerAddAccessories,
        element: <InstallerAddAccessoriesRoute />,
        state: 'dashboard.boInstallersPathsPv.installerAddAccessories',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boInstallersPathsPv.installerRegionsAdd,
        element: <InstallerRegionsAddRoute />,
        state: 'dashboard.boInstallersPathsPv.installerRegionsAdd',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boInstallersPathsPv.installersProfile,
        element: <InstallerProfileRoute />,
        state: 'dashboard.boInstallersPathsPv.installersProfile',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boInstallersPathsPv.installersProfileStationsAdd,
        element: <InstallerProfileAddStationRoute />,
        state: 'dashboard.boInstallersPathsPv.installersProfileStationsAdd',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boInstallersPathsPv.installerAddDepartmentsProfile,
        element: <InstallerAddDepartmentsProfileRoute />,
        state: 'dashboard.boInstallersPathsPv.InstallerAddDepartmentsProfile',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },

    {
        path: undefined,
        element: null,
        state: 'undefined',
        sidebarProps: null,
        show: true,
        index: null,
        child: null,
        label: 'Données'
    },
    {
        path: boSolarPanelsPathsPv.solarPanels,
        element: <SolarPanels />,
        state: 'dashboard.boSolarPanelsPathsPv.solarPanels',
        sidebarProps: {
            displayText: 'Panneaux photovoltaïque',
            icon: <SolarPower />
        },
        show: true,
        index: false,
        child: null,
        label: null
    },
    {
        path: boSolarPanelsPathsPv.solarPanelsProfile,
        element: <SolarPanelsProfile />,
        state: 'dashboard.boSolarPanelsPathsPv.solarPanelsProfile',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boSolarPanelsPathsPv.solarPanelsAdd,
        element: <SolarPanelsAdd />,
        state: 'dashboard.boSolarPanelsPathsPv.solarPanelsAdd',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    }
];
