import { BoltIcon } from '@/assets/icons';
import { RootState } from '@/store';
import { setStateRouteIrvePv } from '@/store/reducers/routesIrvePv.slice';
import { selectRoutesIrvePV, selectUser } from '@/store/selector';
import { WbSunnyOutlined } from '@mui/icons-material';
import { Box, Button, styled, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import TypographyCustom from '../common/Typography';
import { useNavigate } from 'react-router-dom';

const StyledButton = styled(Button)`
    background: #f0e9fa;
    border-radius: 100px;
    display: flex;
    gap: 24px;
    height: 32px;
    box-sizing: content-box;
    padding: 6px 10px;
    position: relative;

    &:hover {
        background: #f0e9fa;
    }

    .circle {
        background-color: ${({ theme }) => theme.colors.primary.main};
        height: 32px;
        width: 32px;
        border-radius: 100%;
        position: absolute;
        transition: 0.3s;
        right: 0;

        &.pv {
            right: calc(0% + 6px);
        }

        &.irve {
            right: calc(50% + 8px);
        }
    }
`;

const ButtonSwitchRoute = () => {
    const dispatch = useDispatch();
    const theme = useTheme();

    const navigate = useNavigate();

    const { user } = useSelector(selectUser);

    const { route } = useSelector((state: RootState) =>
        selectRoutesIrvePV(state)
    );

    const handleClick = () => {
        console.log(route);
        if (route === 'pv') {
            dispatch(setStateRouteIrvePv('irve'));

            if (user?.roles.includes('ROLE_CUSTOMER')) {
                navigate(`/irve/account-customer-b2c/project`);
            } else if (user?.roles.includes('ROLE_ADMIN')) {
                navigate(`/irve/dashboard/overview`);
            } else if (user?.roles.includes('ROLE_INSTALLER')) {
                navigate(`/irve/account-installer/overview`);
            }
        } else {
            dispatch(setStateRouteIrvePv('pv'));

            if (user?.roles.includes('ROLE_CUSTOMER')) {
                navigate(`/pv/account-customer-b2c/project`);
            } else if (user?.roles.includes('ROLE_ADMIN')) {
                navigate(`/pv/dashboard/overview`);
            } else if (user?.roles.includes('ROLE_INSTALLER')) {
                navigate(`/pv/account-installer/overview`);
            }
        }
    };

    return (
        <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            padding={1}
            gap={'8px'}
        >
            <Box
                flex={1}
                color={route === 'irve' ? theme.colors.primary.main : 'white'}
            >
                <TypographyCustom font="poppins" textAlign={'end'} variant="h6">
                    Borne
                </TypographyCustom>
            </Box>
            <StyledButton onClick={handleClick}>
                <Box
                    height={'24px'}
                    width={'24px'}
                    zIndex={1}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <BoltIcon
                        color={
                            route === 'pv' ? theme.colors.primary.main : 'white'
                        }
                        height={'100%'}
                    />
                </Box>
                <Box
                    className={`circle ${route === 'irve' ? 'irve' : 'pv'}`}
                ></Box>
                <Box height={'24px'} width={'24px'} zIndex={1}>
                    <WbSunnyOutlined
                        htmlColor={
                            route === 'irve'
                                ? theme.colors.primary.main
                                : 'white'
                        }
                    />
                </Box>
            </StyledButton>

            <Box
                flex={1}
                color={route === 'pv' ? theme.colors.primary.main : 'white'}
            >
                <TypographyCustom font="poppins" variant="h6">
                    Solaire
                </TypographyCustom>
            </Box>
        </Box>
    );
};

export default ButtonSwitchRoute;
