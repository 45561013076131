import { ListItemButton, ListItemIcon, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from '@/store';
import { RouteType } from '@/models/common/route.models';
import { selectApp } from '@/store/selector';
import TypographyCustom from './Typography';

type Props = {
    item: RouteType;
};

const SidebarItem = ({ item }: Props) => {
    const { appState } = useSelector((state: RootState) => selectApp(state));

    const theme = useTheme();

    return item.sidebarProps && item.path ? (
        <ListItemButton
            component={Link}
            to={item.path}
            sx={{
                '&: hover': {
                    backgroundColor: theme.sidebar.hoverBg
                },
                backgroundColor:
                    appState === item.state ? theme.sidebar.activeBg : 'unset',
                paddingY: '18px',
                paddingX: '24px',
                border: '0px solid transparent',
                borderRightColor:
                    appState === item.state
                        ? theme.colors.primary.main
                        : 'transparent'
            }}
        >
            <ListItemIcon
                sx={{
                    color: theme.sidebar.color
                }}
            >
                {item.sidebarProps.icon && item.sidebarProps.icon}
            </ListItemIcon>

            <TypographyCustom font="chivo" fontSize={'16px'}>
                {item.sidebarProps.displayText}
            </TypographyCustom>
        </ListItemButton>
    ) : null;
};

export default SidebarItem;
