import { Typography, TypographyProps } from '@mui/material';

interface TypoCustomProps extends TypographyProps {
    font: 'poppins' | 'chivo' | 'sora' | 'inter';
}

const TypographyCustom = (props: TypoCustomProps) => {
    return (
        <Typography
            {...props}
            sx={{
                fontFamily: [props.font].join(',')
            }}
            className="typo-custom"
        >
            {props.children}
        </Typography>
    );
};

export default TypographyCustom;
