import { configureStore } from '@reduxjs/toolkit';

import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { CookieStorage } from 'redux-persist-cookie-storage';

import Cookies from 'js-cookie';
import { cookieReducer, localReducer } from './root';

const localStoragePersistConfig = {
    key: 'local',
    storage: storage
    // other configurations for localStorage if needed
};

const cookiePersistConfig = {
    key: 'cookie',
    storage: new CookieStorage(Cookies, {
        expiration: {
            default: 365 * 84600, // Cookies expire after one year
            storeKey: 600
        }
    }),
    paths: ['elementInCookie']

    // cast to any as createWebStorage type is not accurate
    // other configurations for cookies if needed
};

const persistedLocalReducer = persistReducer(
    localStoragePersistConfig,
    localReducer
);
const persistedCookieReducer = persistReducer(
    cookiePersistConfig,
    cookieReducer
);

export const store = configureStore({
    reducer: {
        localStorage: persistedLocalReducer,
        cookieStorage: persistedCookieReducer
    },
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false, // Disable the warning about non-serializable values
            immutableCheck: false
        })
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
