import { AppBar, Toolbar, useTheme } from '@mui/material';
import TopBarUserBox from './TopBarUserBox';
import { Box } from '@mui/system';
import Notification from './Notification';
import TypographyCustom from './Typography';
import { useSelector } from 'react-redux';
import { selectRoutesIrvePV } from '@/store/selector';

const Topbar = () => {
    const theme = useTheme();
    const { route } = useSelector(selectRoutesIrvePV);
    return (
        <AppBar
            position="fixed"
            sx={{
                width: `calc(100% - ${theme.sidebar.width})`,
                ml: theme.sidebar.width,
                boxShadow: 'unset',
                backgroundColor: theme.topbar.bg,
                color: theme.topbar.color,
                zIndex: 800
            }}
        >
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Box
                    padding={'0 27px'}
                    sx={{
                        background: theme.colors.primary.main,
                        backgroundClip: 'text'
                    }}
                >
                    <TypographyCustom font="poppins" fontWeight={'bold'} fontSize={'30px'} color={'transparent'}>
                        Vous êtes dans l'environement {route === 'irve' && 'IRVE'}
                        {route === 'pv' && 'PV'}
                    </TypographyCustom>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center'
                    }}
                >
                    <Toolbar>
                        <Notification />
                        <TopBarUserBox />
                    </Toolbar>
                </Box>
            </Box>
        </AppBar>
    );
};

export default Topbar;
