import axios from 'axios';
import {
    clearStateAuth,
    setStateUserToken,
    setStateUserRefreshToken
} from '@/store/reducers/auth.slice';
import { store } from '@/store';
import { selectAuth } from '@/store/selector';
import { Dispatch } from '@reduxjs/toolkit';

// create an axios instance with default options
const apiFormdata = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    // baseURL: `https://api.v3.charge-in.prasdev.site.prasdev.site`,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': process.env.REACT_APP_CORS_ALLOW_ORIGIN
        // 'Access-Control-Allow-Origin': 'https://app.v3.charge-in.prasdev.site.prasdev.site',
    }
});

apiFormdata.interceptors.request.use((request) => {
    const state = store.getState();

    if (selectAuth(state).token) {
        request.headers['Authorization'] = `Bearer ${selectAuth(state).token}`;
        request.headers['Content-Type'] = 'multipart/form-data';
    }

    return request;
});

apiFormdata.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        // Handle 401 errors
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            // Dispatch action to refresh token
            await store.dispatch(refreshTokenAction());

            // Retry the original request with the new token
            return apiFormdata(originalRequest);
        }

        return Promise.reject(error);
    }
);

const refreshTokenAction = () => async (dispatch: Dispatch) => {
    const state = store.getState();
    try {
        // Make an API request to refresh the token
        const response = await apiFormdata.post('/api/security/token/refresh', {
            refreshToken: selectAuth(state).refreshToken
        });

        // Dispatch the refreshed token to the Redux store
        dispatch(setStateUserToken(response.data.token));
        dispatch(setStateUserRefreshToken(response.data.refreshToken));

        return response;
    } catch (error) {
        // Handle token refresh failure
        dispatch(clearStateAuth());
        throw error; // Propagate the error for further handling
    }
};

export { apiFormdata };
