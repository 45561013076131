import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QnA, FormInformationPv } from '@/models/irve/form.model';

type UpdateQ1Action = PayloadAction<QnA>;

const initialState: FormInformationPv = {
    qna1: null,
    qna2: null,
    qna3: null,
    qna4: null,
    qna5: null,
    qna6: null,
    qna7: null
};

const { actions, reducer } = createSlice({
    name: 'formInformationPv',
    initialState,
    reducers: {
        setStateFormInformationQnA1Pv: (state, action: UpdateQ1Action) => {
            state.qna1 = action.payload;
        },
        setStateFormInformationQnA2Pv: (state, action: UpdateQ1Action) => {
            state.qna2 = action.payload;
        },
        setStateFormInformationQnA3Pv: (state, action: UpdateQ1Action) => {
            state.qna3 = action.payload;
        },
        setStateFormInformationQnA4Pv: (state, action: UpdateQ1Action) => {
            state.qna4 = action.payload;
        },
        setStateFormInformationQnA5Pv: (state, action: UpdateQ1Action) => {
            state.qna5 = action.payload;
        },
        setStateFormInformationQnA6Pv: (state, action: UpdateQ1Action) => {
            state.qna6 = action.payload;
        },
        setStateFormInformationQnA7Pv: (state, action: UpdateQ1Action) => {
            state.qna7 = action.payload;
        },
        clearStateFormInformationPv: (state) => {
            state.qna1 = null;
            state.qna2 = null;
            state.qna3 = null;
            state.qna4 = null;
            state.qna5 = null;
            state.qna6 = null;
            state.qna7 = null;
        }
    }
});

export const {
    setStateFormInformationQnA1Pv,
    setStateFormInformationQnA2Pv,
    setStateFormInformationQnA3Pv,
    setStateFormInformationQnA4Pv,
    setStateFormInformationQnA5Pv,
    setStateFormInformationQnA6Pv,
    setStateFormInformationQnA7Pv,
    clearStateFormInformationPv
} = actions;

export default reducer;
