import { User, UserLogin, UserTokens } from '@/models/common/user.models';
import { api } from './config';

// function to log in and get a JWT token
export const login = async (user: UserLogin): Promise<UserTokens> => {
    try {
        const response = await api.post('/api/security/token', user);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI::/api/security/token');
    }
};

export const resetPassword = async (
    email: string,
    firstname: string
): Promise<User> => {
    try {
        const response = await api.post('/api/security/reset-password', {
            email: email,
            firstname: firstname
        });
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI::/api/security/token');
    }
};

export const meChangePassword = async (
    oldPassword: string,
    newPassword1: string,
    newPassword2: string
): Promise<User> => {
    try {
        const response = await api.put('/api/security/me/change-password', {
            oldPassword: oldPassword,
            newPassword1: newPassword1,
            newPassword2: newPassword2
        });
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI::/api/security/token');
    }
};
