import { imageIrveOut } from '@/assets/images/irve';
import { imagePvOut } from '@/assets/images/pv';
import ButtonPrimary from '@/components/buttons/ButtonPrimary';
import TypographyCustom from '@/components/common/Typography';
import { Login } from '@mui/icons-material';
import { Box, Container, styled, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const StyledNotFound = styled(Box)`
    padding: 96px 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const ComparatorNotFound = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    return (
        <Container
            component={'main'}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <StyledNotFound>
                <Box display={'flex'} justifyContent={'center'}>
                    <img src={imageIrveOut} alt="" height={'96px'} />
                </Box>

                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    textAlign={'center'}
                    gap={'8px'}
                >
                    <Box
                        sx={{
                            background: theme.colors.gradients.primary,
                            backgroundClip: 'text'
                        }}
                    >
                        <TypographyCustom
                            font="poppins"
                            variant="h1"
                            color="transparent"
                        >
                            Cette page n’existe pas
                        </TypographyCustom>
                    </Box>
                    <TypographyCustom font="chivo">
                        Cette page est introuvable.
                    </TypographyCustom>
                </Box>
                <Box display={'flex'} justifyContent={'center'}>
                    <ButtonPrimary handleClick={() => navigate('/irve')}>
                        Retourner à l’accueil <Login />
                    </ButtonPrimary>
                </Box>
            </StyledNotFound>
        </Container>
    );
};

export default ComparatorNotFound;
