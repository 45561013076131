import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Box, Button, ButtonProps, styled } from '@mui/material';

import React from 'react';
import TypographyCustom from '../common/Typography';

interface BtnProps extends ButtonProps {
    arrow?: 'prev' | 'next';
    handleClick: (() => void) | any;
    buttonRef?: React.MutableRefObject<HTMLButtonElement | null>;
}

const ButtonPrimary = (props: BtnProps) => {
    return (
        <StyledButton
            className={`${props.disabled ? 'disabled ' : ''}btn-primary`}
            onClick={props.handleClick}
            {...props}
        >
            <Box
                className={`btn-content`}
                padding={{ xs: '6px 22px;', md: '12px 28px;' }}
                fontSize={{ xs: '14px', md: '16px' }}
            >
                {props.arrow === 'prev' && (
                    <ArrowBack className="btn-svg prev" />
                )}
                <TypographyCustom
                    font="chivo"
                    fontSize={{ xs: '14px', md: '18px' }}
                    display={'flex'}
                    alignItems={'center'}
                    gap={'10px'}
                >
                    {props.children}
                </TypographyCustom>

                {props.arrow === 'next' && (
                    <ArrowForward className="btn-svg next" />
                )}
            </Box>
        </StyledButton>
    );
};
const StyledButton = styled(Button)(
    ({ theme }) => `
        position: relative;
        border-radius: 8px;
        color: white;
        text-transform: none;
        padding: 0;
        overflow: hidden;

        &.disabled {
            color: black;
            
            &:before, &:after {
                background: ${theme.colors.alpha.grey[10]};
            }
           
        }


        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: ${theme.colors.gradients.primary};
            z-index: 1;
            transition: 0.2s ease-in;

        }

        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: ${theme.colors.gradients.secondary};
            z-index: 0;
        }

        .btn-content {
            z-index: 2;
            display:flex;
            align-items: center;
            gap: 10px;
        }

        .btn-svg {
            height: 20px;
            position: relative;
            transition: right 0.1s;
            color: currentColor;
            right: 0;
        }

        &:hover{
            &:before{
                opacity: 0;
            }

            .btn-svg {
                &.next {
                    right: -10px;
                }
            }
        }
    `
);

export default ButtonPrimary;
