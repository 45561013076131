import { lazy } from 'react';
import Loader from '@/components/common/Loader';

export const InstallersRoute = Loader(lazy(() => import('@/pages/dashboard/Installers/irve/Installers')));

export const InstallerProfileRoute = Loader(lazy(() => import('@/pages/dashboard/Installers/irve/Profile/InstallerProfile')));

export const InstallerProfileAddStationRoute = Loader(lazy(() => import('@/pages/dashboard/Installers/irve/Profile/StationsAdd')));

export const InstallerAddDepartmentsProfileRoute = Loader(lazy(() => import('@/pages/dashboard/Installers/irve/Profile/DepartmentsAdd')));

export const InstallerAddRoute = Loader(lazy(() => import('@/pages/dashboard/Installers/irve/Add/Installer')));

export const InstallerAddLegalInformationsRoute = Loader(lazy(() => import('@/pages/dashboard/Installers/irve/Add/LegalInformations')));

export const InstallerAddStationsRoute = Loader(lazy(() => import('@/pages/dashboard/Installers/irve/Add/Stations')));

export const InstallerAddInstallationsRoute = Loader(lazy(() => import('@/pages/dashboard/Installers/irve/Add/Installations')));

export const InstallerAddAccessoriesRoute = Loader(lazy(() => import('@/pages/dashboard/Installers/irve/Add/Accessories')));

export const InstallerAddDepartmentsRoute = Loader(lazy(() => import('@/pages/dashboard/Installers/irve/Profile/DepartmentsAdd')));
