import { CSSProperties } from 'react';
import { Theme } from '@mui/material';
import { IrveBaseTheme } from './schemes/IrveTheme';
import { PvBaseTheme } from './schemes/PvTheme';
import { Keyframes } from '@emotion/react';

export function themeCreator(theme: string): Theme {
    return themeMap[theme];
}

declare module '@mui/material/styles' {
    interface Theme {
        colors: {
            gradients: {
                blue1: string;
                blue2: string;
                blue3: string;
                blue4: string;
                blue5: string;
                orange1: string;
                orange2: string;
                orange3: string;
                purple1: string;
                purple3: string;
                pink1: string;
                pink2: string;
                green1: string;
                green2: string;
                green3: string;
                green4: string;

                black1: string;
                black2: string;

                primary: string;
                secondary: string;
                merge: string;
            };
            shadows: {
                success: string;
                error: string;
                primary: string;
                warning: string;
                info: string;
            };
            alpha: {
                white: {
                    5: string;
                    10: string;
                    30: string;
                    50: string;
                    70: string;
                    100: string;
                };
                trueWhite: {
                    5: string;
                    10: string;
                    30: string;
                    50: string;
                    70: string;
                    100: string;
                };
                black: {
                    5: string;
                    10: string;
                    30: string;
                    50: string;
                    70: string;
                    100: string;
                };
                grey: {
                    5: string;
                    10: string;
                    30: string;
                    50: string;
                    70: string;
                    100: string;
                };
            };
            secondary: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
                background: string;
                text: string;
            };
            primary: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
                grey: string;
                background: string;
                text: string;
                secondary: string;
            };

            footer: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
                background: string;
                text: string;
            };
            success: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            warning: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            error: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            info: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            status1: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            status2: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            status3: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            status4: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            status5: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            status6: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            status7: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            status8: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            status9: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            status10: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            status11: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            status12: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            status13: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            status14: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            status15: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            statusDefault: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
        };
        general: {
            reactFrameworkColor: CSSProperties['color'];
            borderRadiusSm: string;
            borderRadius: string;
            borderRadiusLg: string;
            borderRadiusXl: string;
        };
        sidebar: {
            bg: CSSProperties['color'];
            color: CSSProperties['color'];
            hoverBg: CSSProperties['color'];
            activeBg: CSSProperties['color'];
            labelColor: CSSProperties['color'];
            width: string;
        };
        topbar: {
            bg: CSSProperties['color'];
            color: CSSProperties['color'];
        };
        header: {
            height: string;
            background: CSSProperties['color'];
            boxShadow: CSSProperties['color'];
            textColor: CSSProperties['color'];
        };
        modalStyle: {
            position: string;
            top: string;
            left: string;
            transform: string;
            width: number;
            bgcolor: string;
            border: string;
            borderRadius: string;
            boxShadow: number;
            p: number;
        };

        boxShadow?: {
            first?: string;
            second?: string;
            third?: string;
            fourth?: string;
        };

        animations?: {
            speed: {
                verySlow?: number;
                slow?: number;
                average?: number;
                fast?: number;
                veryFast?: number;
            };
            fadeIn?: Keyframes;
            zoomIn?: Keyframes;
            zoomInReverse?: Keyframes;

            zoomOut?: Keyframes;
            zoomInFromBottom?: Keyframes;
        };
    }

    interface ThemeOptions {
        colors: {
            gradients: {
                blue1: string;
                blue2: string;
                blue3: string;
                blue4: string;
                blue5: string;
                orange1: string;
                orange2: string;
                orange3: string;
                purple1: string;
                purple3: string;
                pink1: string;
                pink2: string;
                green1: string;
                green2: string;
                green3: string;
                green4: string;

                black1: string;
                black2: string;

                primary: string;
                secondary: string;
                merge: string;
            };
            shadows: {
                success: string;
                error: string;
                primary: string;
                warning: string;
                info: string;
            };
            alpha: {
                white: {
                    5: string;
                    10: string;
                    30: string;
                    50: string;
                    70: string;
                    100: string;
                };
                trueWhite: {
                    5: string;
                    10: string;
                    30: string;
                    50: string;
                    70: string;
                    100: string;
                };
                black: {
                    5: string;
                    10: string;
                    30: string;
                    50: string;
                    70: string;
                    100: string;
                };
                grey: {
                    5: string;
                    10: string;
                    30: string;
                    50: string;
                    70: string;
                    100: string;
                };
            };
            secondary: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
                background: string;
                text: string;
            };
            primary: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
                grey: string;
                background: string;
                text: string;
                secondary: string;
            };

            footer: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
                background: string;
                text: string;
            };
            success: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            warning: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            error: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            info: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            status1: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            status2: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            status3: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            status4: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            status5: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            status6: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            status7: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            status8: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            status9: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            status10: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            status11: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            status12: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            status13: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            status14: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            status15: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
            statusDefault: {
                lighter: string;
                light: string;
                main: string;
                dark: string;
            };
        };
        general: {
            reactFrameworkColor: CSSProperties['color'];
            borderRadiusSm: string;
            borderRadius: string;
            borderRadiusLg: string;
            borderRadiusXl: string;
        };
        sidebar: {
            bg: CSSProperties['color'];
            color: CSSProperties['color'];
            hoverBg: CSSProperties['color'];
            activeBg: CSSProperties['color'];
            labelColor: CSSProperties['color'];
            width: string;
        };
        topbar: {
            bg: CSSProperties['color'];
            color: CSSProperties['color'];
        };
        header: {
            height: string;
            background: CSSProperties['color'];
            boxShadow: CSSProperties['color'];
            textColor: CSSProperties['color'];
        };
        modalStyle: {
            position: string;
            top: string;
            left: string;
            transform: string;
            width: number | string;
            bgcolor: string;
            border: string;
            borderRadius: string;
            boxShadow: number;
            p: number;
        };

        boxShadow?: {
            first?: string;
            second?: string;
            third?: string;
            fourth?: string;
        };

        animations?: {
            speed: {
                verySlow?: number;
                slow?: number;
                average?: number;
                fast?: number;
                veryFast?: number;
            };
            fadeIn?: Keyframes;
            zoomIn?: Keyframes;
            zoomInReverse?: Keyframes;
            zoomOut?: Keyframes;
            zoomInFromBottom?: Keyframes;
        };
    }
}

const themeMap: { [key: string]: Theme } = {
    IrveTheme: IrveBaseTheme,
    PvTheme: PvBaseTheme
};
