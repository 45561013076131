import TypographyCustom from '@/components/common/Typography';
import { userCustomerPaths } from '@/routes/routesPathsIrve';
import {
    Box,
    CircularProgress,
    Container,
    styled,
    useTheme
} from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const StyledNotFound = styled(Box)`
    padding: 96px 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 900px;
`;

const ResultRedirect = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigate(userCustomerPaths.project);
        }, 3000);
    });

    return (
        <Container
            component={'main'}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <StyledNotFound>
                <Box display={'flex'} justifyContent={'center'}>
                    <CircularProgress size={70} />
                </Box>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    textAlign={'center'}
                    gap={'8px'}
                >
                    <Box
                        sx={{
                            background: theme.colors.gradients.primary,
                            backgroundClip: 'text'
                        }}
                    >
                        <TypographyCustom
                            font="poppins"
                            variant="h1"
                            color="transparent"
                        >
                            Redirection vers votre espace personnel
                        </TypographyCustom>
                    </Box>
                    <TypographyCustom font="inter" variant="body2">
                        Vous recevrez bientôt un mail contenant vos identifiants
                        pour vous connecter à votre espace.
                    </TypographyCustom>
                </Box>
            </StyledNotFound>
        </Container>
    );
};

export default ResultRedirect;
