import { login, resetPassword, meChangePassword } from './auth.services';
import { meProfile } from './user.services';
import { getfilePresignedUrl } from './file.services';
import {
    meCustomerProfile,
    registerCustomerB2C,
    getPossibleStations,
    getPossibleInstallers,
    getOpportunity,
    getAcessoriesInstallerInstaller,
    getSelectedAccessoriesInstallerInstaller,
    addDoc,
    updateSelectedAccessoriesInstallerInstaller,
    addSelectedInstaller,
    addSelectedStation,
    meCustomerUpdateProfile,
    opportunityUpdateStatus,
    getOpportunityAccessories,
    setOpportunitySetAllowedToValidate,
    getYousignLink,
    registerCustomerB2CAndLogin
} from './customer.services';

import { getYousignSignatureLink } from './yousign.services';

import { getVehiclesList } from './vehicle.services';
import { getAccessoriesInstallerList } from './accessory.services';
import {
    boMeAdminProfile,
    boCreateAccessoryStationMonophased,
    boUpdateAccessoryStationMonophased,
    boCreateAccessoryInstaller,
    boCreateAccessoryStationTriphased,
    boGetAllAccessoriesInstaller,
    boGetAllAccessoriesStationMonophased,
    boGetAllAccessoriesStationTriphased,
    boGetOneAccessoryInstaller,
    boGetOneAccessoryStationMonophased,
    boGetOneAccessoryStationTriphased,
    boUpdateAccessoryInstaller,
    boUpdateAccessoryStationTriphased,
    boCreateStation,
    boGetAllStations,
    boGetOneStation,
    boUpdateStation,
    boCreateAdmin,
    boGetAllAdmins,
    boGetOneAdmin,
    boUpdateAdmin,
    boCreateManager,
    boGetAllManagers,
    boGetOneManager,
    boUpdateManager,
    boCreateProjectManager,
    boGetAllProjectManagers,
    boGetOneProjectManager,
    boUpdateProjectManager,
    boAddMultipleStationsToInstaller,
    boCreateInstallerWAllInformations,
    boDeleteOneInstaller,
    boGetAllInstallers,
    boGetOneInstaller,
    boUpdateOneInstaller,
    boOpportunitySetStatus,
    boGetOneOpportunity,
    boOpportunityAddComment,
    boGetOpportunityComments,
    boOpportunitiesHousingType,
    boAddSelectedInstaller,
    boAddSelectedStation,
    boGetPossibleInstaller,
    boGetPossibleStations,
    boGetAcessoriesInstallerInstaller,
    boUpdateSelectedAccessoriesInstallerInstaller,
    boGetSelectedAccessoriesInstallerInstaller,
    boGetOpportunityAccessories,
    boGetOpportunityAllMessages,
    boAddOpportunityMessage,
    boCreateInstallerProfile,
    boInstallerAddLegalInformations,
    boInstallerAddSelectedStations,
    boInstallerAddInstallations,
    boInstallerAddAccessories,
    boInstallerAddDepartments,
    boGetAllStudies,
    boAddStudyComment,
    boAddOneStudy,
    boGetOneStudy,
    boAddMessageB2B,
    boGetStudyConversationB2B,
    boGetStudyConversationMessagesB2B,
    boGetComments,
    boAddComment,
    boStudySetStatus,
    boGetStudiesOnGoingArchived,
    boStudyAddStudyFile,
    boStudySetInstallerStatusAccepted,
    boStudyGetInstallerStatus,
    boStudySetInstallerStatus,
    boStudyGetInstallers,
    boAddOpportunityDoc,
    boSendMailNrp,
    boSendMailSdr,
    boInstallerUpdateLogo,
    boInstallerUpdateProfile,
    boInstallerUpdateLegalInformations,
    boInstallerUpdateLegalInformationsDoc,
    boInstallerSetActive,
    boInstallerGetSelectedStations,
    boInstallerAddSelectedStationsProfile,
    boInstallerGetAllStationsWPrices,
    boOpportunityAddDiscount,
    boOpportunitySetPriceWFinancialHelps,
    boOpportunitySetYousignLink,
    boInstallerUpdateInstallations,
    boUpdateStationInformations,
    boUpdateStationLogo,
    boUpdateStationTechnicalDoc,
    boAdminSetActive,
    boManagerSetActive,
    boProjectManagerSetActive,
    boInstallerUpdateInstallerAccessories,
    boStudyUpdateInstallers,
    boOpportunitiesHousingTypeTableHeader,
    boStudyUpdateStudyFile,
    boSendInvoiceProposal,
    boOpportunitiesTableHeader,
    boOpportunitiesHousingTypeV2,
    boGetStudiesOnGoingArchivedTableHeader,
    boCreateInstallerProfileSimple,
    boStudyUpdateInfos,
    boAccessoryStationMonophasedActiveInActive,
    boAccessoryStationTriphasedActiveInActive,
    boAccessoryInstallerActiveInActive,
    boInstallerCheckValidity
} from './bo.services';

import { getAllRegions, getDepartmentsFromSelectedRegions, getAllRegionsWDeparmtents, getMeRegions, getInstallerRegions } from './region.services';

import {
    installerMeProfile,
    installerGetOneStationInstaller,
    installerGetOneOpportunity,
    installerAddMessage,
    installerAddInvoiceFile,
    installerGetStudies,
    installerGetStudyConversation,
    installerGetOneStudy,
    installerGetOpportunitiesHousingTypeOngoingArchived,
    installerMeUpdateProfile,
    installerMeGetProfile,
    installerMeUpdateLogo,
    installerMeUpdateLegalInformations,
    installerMeUpdateLegalInformationsDoc,
    installerAddSelectedStations,
    installeurMeGetSelectedStations,
    installeurMeGetAllStations,
    installeurMeGetSelectedAccessoriesInstallers,
    installerAddSelectedAccessoriesInstaller,
    installeurMeGetAllAccessorieInstaller,
    installerMeUpdateInstallations,
    installerMeUpdateDepartments,
    installerMeCheckValidity,
    installerMeSetOpportunityStatus,
    installerMeUpdateAccessoriesInstaller,
    installerOpportunitiesTableHeader,
    installerOpportunitiesHousingType,
    installerOpportunitySetStatus,
    installerSendMailSdr,
    installerSendMailNrp,
    installerGetOpportunityComments,
    installerOpportunityAddComment,
    installerGetAcessoriesInstaller,
    installetGetSelectedAccessoriesInstallerInstaller,
    installerUpdateSelectedAccessoriesInstallerInstaller,
    installerGetOpportunityAccessories,
    installerOpportunityAddDiscount,
    installerAddSelectedStation,
    installerGetPossibleStations,
    installerSendInvoiceProposal
} from './installer.services';

import { notifAdminSetStudyMessagesRead, notifAdminSetInvoiceRead, notifAdminGetAll, notifAdminSetOpportunityMessageRead } from './notifications.services';

export const fileServices = {
    getfilePresignedUrl
};

export const notificationServices = {
    notifAdminSetStudyMessagesRead,
    notifAdminGetAll,
    notifAdminSetInvoiceRead,
    notifAdminSetOpportunityMessageRead
};

export const loginServices = {
    login,
    resetPassword,
    meChangePassword
};

export const userServices = {
    meProfile
};

export const yousignServices = {
    getYousignSignatureLink
};

export const userInstallerServices = {
    installerMeProfile,
    installerGetOneStationInstaller,
    installerGetOneOpportunity,
    installerGetOpportunitiesHousingTypeOngoingArchived,
    installerAddMessage,
    installerAddInvoiceFile,
    installerGetStudies,
    installerGetStudyConversation,
    installerGetOneStudy,
    installerMeUpdateProfile,
    installerMeGetProfile,
    installerMeUpdateLogo,
    installerMeUpdateLegalInformations,
    installerMeUpdateLegalInformationsDoc,
    installeurMeGetSelectedStations,
    installerAddSelectedStations,
    installeurMeGetAllStations,
    installeurMeGetSelectedAccessoriesInstallers,
    installerAddSelectedAccessoriesInstaller,
    installeurMeGetAllAccessorieInstaller,
    installerMeUpdateInstallations,
    installerMeUpdateDepartments,
    installerMeCheckValidity,
    installerMeSetOpportunityStatus,
    installerMeUpdateAccessoriesInstaller,
    installerOpportunitiesTableHeader,
    installerOpportunitiesHousingType,
    installerOpportunitySetStatus,
    installerSendMailSdr,
    installerSendMailNrp,
    installerGetOpportunityComments,
    installerOpportunityAddComment,
    installerGetAcessoriesInstaller,
    installetGetSelectedAccessoriesInstallerInstaller,
    installerUpdateSelectedAccessoriesInstallerInstaller,
    installerGetOpportunityAccessories,
    installerOpportunityAddDiscount,
    installerAddSelectedStation,
    installerGetPossibleStations,
    installerSendInvoiceProposal
};

export const userCustomerServices = {
    meCustomerProfile,
    registerCustomerB2C,
    getPossibleStations,
    getPossibleInstallers,
    getOpportunity,
    getAcessoriesInstallerInstaller,
    getSelectedAccessoriesInstallerInstaller,
    addDoc,
    updateSelectedAccessoriesInstallerInstaller,
    addSelectedInstaller,
    addSelectedStation,
    meCustomerUpdateProfile,
    opportunityUpdateStatus,
    getOpportunityAccessories,
    setOpportunitySetAllowedToValidate,
    getYousignLink,
    registerCustomerB2CAndLogin
};

export const vehicleServices = {
    getVehiclesList
};

export const accessoryServices = {
    getAccessoriesInstallerList
};

export const boServices = {
    boMeAdminProfile,
    boCreateAccessoryStationMonophased,
    boUpdateAccessoryStationMonophased,
    boCreateAccessoryInstaller,
    boCreateAccessoryStationTriphased,
    boGetAllAccessoriesInstaller,
    boGetAllAccessoriesStationMonophased,
    boGetAllAccessoriesStationTriphased,
    boGetOneAccessoryInstaller,
    boGetOneAccessoryStationMonophased,
    boGetOneAccessoryStationTriphased,
    boUpdateAccessoryInstaller,
    boUpdateAccessoryStationTriphased,
    boCreateStation,
    boGetAllStations,
    boGetOneStation,
    boUpdateStation,
    boCreateAdmin,
    boGetAllAdmins,
    boGetOneAdmin,
    boUpdateAdmin,
    boCreateManager,
    boGetAllManagers,
    boGetOneManager,
    boUpdateManager,
    boCreateProjectManager,
    boGetAllProjectManagers,
    boGetOneProjectManager,
    boUpdateProjectManager,
    boAddMultipleStationsToInstaller,
    boCreateInstallerWAllInformations,
    boDeleteOneInstaller,
    boGetAllInstallers,
    boGetOneInstaller,
    boUpdateOneInstaller,
    boOpportunitySetStatus,
    boGetOneOpportunity,
    boOpportunitiesHousingType,
    boOpportunityAddComment,
    boGetOpportunityComments,
    boAddSelectedInstaller,
    boAddSelectedStation,
    boGetPossibleInstaller,
    boGetPossibleStations,
    boGetAcessoriesInstallerInstaller,
    boUpdateSelectedAccessoriesInstallerInstaller,
    boGetSelectedAccessoriesInstallerInstaller,
    boGetOpportunityAccessories,
    boGetOpportunityAllMessages,
    boAddOpportunityMessage,
    boCreateInstallerProfile,
    boInstallerAddLegalInformations,
    boInstallerAddSelectedStations,
    boInstallerAddInstallations,
    boInstallerAddAccessories,
    boInstallerAddDepartments,
    boGetAllStudies,
    boAddStudyComment,
    boAddOneStudy,
    boGetOneStudy,
    boAddMessageB2B,
    boGetStudyConversationB2B,
    boGetStudyConversationMessagesB2B,
    boGetComments,
    boAddComment,
    boStudySetStatus,
    boGetStudiesOnGoingArchived,
    boStudyAddStudyFile,
    boStudySetInstallerStatusAccepted,
    boStudyGetInstallerStatus,
    boStudySetInstallerStatus,
    boStudyGetInstallers,
    boAddOpportunityDoc,
    boSendMailNrp,
    boSendMailSdr,
    boInstallerUpdateLogo,
    boInstallerUpdateProfile,
    boInstallerUpdateLegalInformations,
    boInstallerUpdateLegalInformationsDoc,
    boInstallerSetActive,
    boInstallerGetSelectedStations,
    boInstallerAddSelectedStationsProfile,
    boInstallerGetAllStationsWPrices,
    boOpportunityAddDiscount,
    boOpportunitySetPriceWFinancialHelps,
    boOpportunitySetYousignLink,
    boInstallerUpdateInstallations,
    boUpdateStationInformations,
    boUpdateStationLogo,
    boUpdateStationTechnicalDoc,
    boAdminSetActive,
    boManagerSetActive,
    boProjectManagerSetActive,
    boInstallerUpdateInstallerAccessories,
    boStudyUpdateInstallers,
    boOpportunitiesHousingTypeTableHeader,
    boStudyUpdateStudyFile,
    boSendInvoiceProposal,
    boOpportunitiesTableHeader,
    boOpportunitiesHousingTypeV2,
    boGetStudiesOnGoingArchivedTableHeader,
    boCreateInstallerProfileSimple,
    boStudyUpdateInfos,
    boAccessoryStationMonophasedActiveInActive,
    boAccessoryStationTriphasedActiveInActive,
    boAccessoryInstallerActiveInActive,
    boInstallerCheckValidity
};

export const regionServices = {
    getAllRegions,
    getDepartmentsFromSelectedRegions,
    getAllRegionsWDeparmtents,
    getMeRegions,
    getInstallerRegions
};
