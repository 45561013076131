import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QnA, Form2HouseStore } from '@/models/irve/form.model';

type UpdateQnAAction = PayloadAction<QnA>;

const initialState: Form2HouseStore = {
    qna1: null,
    qna2: null,
    qna3: null,
    qna4: null,
    qna5: null,
    qna6: null
};

const form2HouseSlice = createSlice({
    name: 'form2House',
    initialState,
    reducers: {
        setStateForm2HouseQnA1Irve: (state, action: UpdateQnAAction) => {
            state.qna1 = action.payload;
        },
        setStateForm2HouseQnA2Irve: (state, action: UpdateQnAAction) => {
            state.qna2 = action.payload;
        },
        setStateForm2HouseQnA3Irve: (state, action: UpdateQnAAction) => {
            state.qna3 = action.payload;
        },
        setStateForm2HouseQnA4Irve: (state, action: UpdateQnAAction) => {
            state.qna4 = action.payload;
        },
        setStateForm2HouseQnA5Irve: (state, action: UpdateQnAAction) => {
            state.qna5 = action.payload;
        },
        setStateForm2HouseQnA6Irve: (state, action: UpdateQnAAction) => {
            state.qna6 = action.payload;
        },
        clearStateForm2HouseIrve: (state) => {
            state.qna1 = null;
            state.qna2 = null;
            state.qna3 = null;
            state.qna4 = null;
            state.qna5 = null;
            state.qna6 = null;
        }
    }
});

export const {
    setStateForm2HouseQnA1Irve,
    setStateForm2HouseQnA2Irve,
    setStateForm2HouseQnA3Irve,
    setStateForm2HouseQnA4Irve,
    setStateForm2HouseQnA5Irve,
    setStateForm2HouseQnA6Irve,
    clearStateForm2HouseIrve
} = form2HouseSlice.actions;

export default form2HouseSlice.reducer;
