import { lazy } from 'react';
import Loader from '@/components/common/Loader';

export const RegionsRoute = Loader(
    lazy(() => import('@/pages/accountInstaller/Me/irve/Departments'))
);

export const RegionsAddRoute = Loader(
    lazy(() => import('@/pages/accountInstaller/Me/irve/DepartmentsAdd'))
);
