import { lazy } from 'react';
import Loader from '@/components/common/Loader';

export const SolarPanels = Loader(
    lazy(() => import('@/pages/dashboard/SolarPanels/pv/SolarPanels'))
);

export const SolarPanelsProfile = Loader(
    lazy(() => import('@/pages/dashboard/SolarPanels/pv/Profile'))
);

export const SolarPanelsAdd = Loader(
    lazy(() => import('@/pages/dashboard/SolarPanels/pv/SolarPanelAdd'))
);
