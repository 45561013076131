import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QnA, FormProjectPv } from '@/models/irve/form.model';

type UpdateQ1Action = PayloadAction<QnA>;

const initialState: FormProjectPv = {
    qna1: null,
    qna2: null,
    qna3: null,
    qna4: null,
    qna5: null,
    qna6: null
};
const { actions, reducer } = createSlice({
    name: 'formProjectPv',
    initialState,
    reducers: {
        setStateFormProjectQnA1Pv: (state, action: UpdateQ1Action) => {
            state.qna1 = action.payload;
        },
        setStateFormProjectQnA2Pv: (state, action: UpdateQ1Action) => {
            state.qna2 = action.payload;
        },
        setStateFormProjectQnA3Pv: (state, action: UpdateQ1Action) => {
            state.qna3 = action.payload;
        },
        setStateFormProjectQnA4Pv: (state, action: UpdateQ1Action) => {
            state.qna4 = action.payload;
        },
        setStateFormProjectQnA5Pv: (state, action: UpdateQ1Action) => {
            state.qna5 = action.payload;
        },
        setStateFormProjectQnA6Pv: (state, action: UpdateQ1Action) => {
            state.qna6 = action.payload;
        },

        clearStateFormProjectPv: (state) => {
            state.qna1 = null;
            state.qna2 = null;
            state.qna3 = null;
            state.qna4 = null;
            state.qna5 = null;
            state.qna6 = null;
        }
    }
});

export const {
    setStateFormProjectQnA1Pv,
    setStateFormProjectQnA2Pv,
    setStateFormProjectQnA3Pv,
    setStateFormProjectQnA4Pv,
    setStateFormProjectQnA5Pv,
    setStateFormProjectQnA6Pv,
    clearStateFormProjectPv
} = actions;

export default reducer;
