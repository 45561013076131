import { RootState, store } from '@/store';

const state: RootState = store.getState();

export const basePath = {
    index: '/'
};

export const loginPaths = {
    index: `/login`
};

// IRVE
export const userCustomerPaths = {
    project: `/irve/account-customer-b2c/project`,
    meInformations: `/irve/account-customer-b2c/me`,
    documents: `/irve/account-customer-b2c/documents`,
    recap: `/irve/account-customer-b2c/recap`,
    messenger: `/irve/account-customer-b2c/messenger`,
    changeStation: `/irve/account-customer-b2c/change-station`,
    changeInstaller: `/irve/account-customer-b2c/change-installer`,
    selectStation: `/irve/account-customer-b2c/select-station`,
    selectInstaller: `/irve/account-customer-b2c/select-installer`,
    yousign: `/irve/account-customer-b2c/yousign`,
    contact: `/irve/account-customer-b2c/contact`
};

export const userInstallerPaths = {
    overview: `/irve/account-installer/overview`,
    meInformations: `/irve/account-installer/me/informations`,
    meLegalInformations: `/irve/account-installer/me/legal-informations`,
    meInstallations: `/irve/account-installer/me/legal-installations`,
    meStations: `/irve/account-installer/me/stations`,
    meAccessories: `/irve/account-installer/me/accessories`
};

export const userInstallerB2BPaths = {
    index: `/irve/account-installer/opportunities-b2b`,
    onGoing: `/irve/account-installer/opportunities-b2b/on-going`,
    onGoingProfile: `/irve/account-installer/opportunity-b2b/on-going/profile`,
    archived: `/irve/account-installer/opportunities-b2b/archived`,
    archivedProfile: `/irve/account-installer/opportunity-b2b/archived/profile`,
    messenger: `/irve/account-installer/opportunity-b2b/messenger`
};

export const userInstallerB2CApartmentPaths = {
    index: `/irve/account-installer/opportunities-b2c/apartment`,
    onGoing: `/irve/account-installer/opportunities-b2c/apartment/on-going`,
    onGoingProfile: `/irve/account-installer/opportunity-b2c/apartment/on-going/profile`,
    archived: `/irve/account-installer/opportunities-b2c/apartment/archived`,
    archivedProfile: `/irve/account-installer/opportunity-b2c/apartment/archived/profile`
};

export const userInstallerB2CHousePaths = {
    index: `/irve/account-installer/opportunities-b2c/house`,
    onGoing: `/irve/account-installer/opportunities-b2c/house/on-going`,
    onGoingProfile: `/irve/account-installer/opportunity-b2c/house/on-going/profile`,
    archived: `/irve/account-installer/opportunities-b2c/house/archived`,
    archivedProfile: `/irve/account-installer/opportunity-b2c/house/archived/profile`,
    changeStation: `/irve/account-installer/opportunity-b2c/house/change-station`,
    changeInstaller: `/irve/account-installer/opportunity-b2c/house/change-installer`,
    messenger: `/irve/account-installer/opportunity-b2c/house/opportunity-b2c/messenger`,
    preview: `/irve/account-installer/opportunity-b2c/house/opportunity-b2c/preview`
};

export const userInstallerSetupPaths = {
    profile: `/irve/account-installer/setup/profile`,
    legalInformations: `/irve/account-installer/setup/informations`,
    installations: `/irve/account-installer/setup/installations`,
    stations: `/irve/account-installer/setup/stations`,
    stationsAdd: `/irve/account-installer/setup/stations-add`,
    accessories: `/irve/account-installer/setup/accessoires`,
    accessoriesAdd: `/irve/account-installer/setup/accessoires-add`,
    regions: `/irve/account-installer/setup/regions`,
    regionsAdd: `/irve/account-installer/setup/regions-add`,
    offerSimulation: '/irve/account-installer/setup/offer-simulation'
};

export const comparatorPathsIrve = {
    base: '/',
    landing: `/irve/`,
    formHousing: `/irve/comparator/form-housing`,
    formVehicle: `/irve/comparator/form-vehicle`,
    formMyProject: `/irve/comparator/form-project`,
    formUserInformation: `/irve/comparator/form-information`,
    formRecap: `/irve/comparator/recap-form`,

    form2House: `/irve/comparator/form2-house`,
    form2Apartment: `/irve/comparator/form2-apartment`,
    form5: `/irve/comparator/form5`,

    formElecConsum: `/irve/comparator/form-elec-consum`,
    resultRecapStations: `/irve/comparator/recap-stations`,
    resultRecapInstallers: `/irve/comparator/recap-installers`,
    resultRecapAll: `/irve/comparator/recap-all`,
    resultLoader: `/irve/comparator/recap-loader`,
    resultLoaderV2: `/irve/comparator/recap-loader-v2`,
    resultAccountAlreadyExists: `/irve/comparator/account-already-exists`,
    resultRedirect: `/irve/comparator/redirect`,
    notFound: `/irve/comparator/*`
};

export const boAccessoriesPath = {
    accessories: `/irve/dashboard/accessories`,
    accessoriesStationMonophasedProfile: `/irve/dashboard/accessories-station-monophased/profile`,
    accessoriesStationMonophasedAdd: `/irve/dashboard/accessories-station-monophased/add`,
    accessoriesStationTriphasedProfile: `/irve/dashboard/accessories-station-triphased/profile`,
    accessoriesStationTriphasedAdd: `/irve/dashboard/accessories-station-triphased/add`,
    accessoriesInstallerProfile: `/irve/dashboard/accessories-installer/profile`,
    accessoriesInstallerAdd: `/irve/dashboard/accessories-installer/add`
};

export const boAdminsPath = {
    admins: `/irve/dashboard/admins`,
    adminsProfile: `/irve/dashboard/admins/profile`,
    adminAdd: `/irve/dashboard/admins/add`
};

export const boManagersPath = {
    managers: `/irve/dashboard/managers`,
    managersProfile: `/irve/dashboard/managers/profile`,
    managerAdd: `/irve/dashboard/managers/add`
};

export const boPmsPath = {
    pms: `/irve/dashboard/pms`,
    pmsProfile: `/irve/dashboard/pms/profile`,
    pmAdd: `/irve/dashboard/pms/add`
};

export const boInstallersPath = {
    installers: `/irve/dashboard/installers`,
    installersProfile: `/irve/dashboard/installers/profile`,
    installersProfileStationsAdd: `/irve/dashboard/installers/profile/add-stations`,
    installerAdd: `/irve/dashboard/installers/add`,
    installerAddSimple: `/irve/dashboard/installers/add-simple`,
    installerAddLegalInformations: `/irve/dashboard/installers/add-legal-informations`,
    installerAddLSelectedStations: `/irve/dashboard/installers/add-selected-stations`,
    installerAddInstallations: `/irve/dashboard/installers/add-selected-installations`,
    installerAddAccessories: `/irve/dashboard/installers/add-accessories`,
    installerAddDepartments: `/irve/dashboard/installers/add-departments`,
    installerRegionsAdd: `/irve/dashboard/installers/regions-add`,
    installerAddDepartmentsProfile: `/irve/dashboard/installers/add-departments-profile`
};

export const boB2BPath = {
    index: `/irve/dashboard/opportunities-b2b`,
    onGoing: `/irve/dashboard/opportunities-b2b/on-going`,
    onGoingProfile: `/irve/dashboard/opportunity-b2b/on-going/profile`,
    archived: `/irve/dashboard/opportunities-b2b/archived`,
    archivedProfile: `/irve/dashboard/opportunity-b2b/archived/profile`,
    studyAdd: `/irve/dashboard/opportunity-b2b/study/add`,
    installersUpdate: `/irve/dashboard/opportunity-b2b/installers-update`,
    messenger: `/irve/dashboard/opportunity-b2b/messenger`
};

export const boB2CApartmentPath = {
    index: `/irve/dashboard/opportunities-b2c/apartment`,
    onGoing: `/irve/dashboard/opportunities-b2c/apartment/on-going`,
    onGoingProfile: `/irve/dashboard/opportunity-b2c/apartment/on-going/profile`,
    archived: `/irve/dashboard/opportunities-b2c/apartment/archived`,
    archivedProfile: `/irve/dashboard/opportunity-b2c/apartment/archived/profile`,
    changeStation: `/irve/dashboard/change-station`,
    changeInstaller: `/irve/dashboard/change-installer`,
    messenger: `/irve/dashboard/opportunity-b2c/messenger`
};

export const boB2CHousePath = {
    index: `/irve/dashboard/opportunities-b2c/house`,
    onGoing: `/irve/dashboard/opportunities-b2c/house/on-going`,
    onGoingProfile: `/irve/dashboard/opportunity-b2c/house/on-going/profile`,
    archived: `/irve/dashboard/opportunities-b2c/house/archived`,
    archivedProfile: `/irve/dashboard/opportunity-b2c/house/archived/profile`,
    changeStation: `/irve/dashboard/change-station`,
    changeInstaller: `/irve/dashboard/change-installer`,
    messenger: `/irve/dashboard/opportunity-b2c/messenger`
};

export const boB2CInvoicePath = {
    preview: `/irve/dashboard/opportunity-b2c/invoice/preview`
};

export const boStationsPath = {
    stations: `/irve/dashboard/stations`,
    stationsProfile: `/irve/dashboard/stations/Profile`,
    stationAdd: `/irve/dashboard/stations/add`
};

export const boOverviewsPath = {
    index: `/irve/dashboard/overview`
};

// PV
