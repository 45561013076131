import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QnA, QnA2Form4, Form4Store } from '@/models/irve/form.model';

type UpdateQnAAction = PayloadAction<QnA>;
type UpdateQnA2Action = PayloadAction<QnA2Form4>;

const initialState: Form4Store = {
    qna1: null,
    qna2: null
};

const form4Slice = createSlice({
    name: 'form4',
    initialState,
    reducers: {
        setStateForm4QnA1Irve: (state, action: UpdateQnAAction) => {
            state.qna1 = action.payload;
        },
        setStateForm4QnA2Irve: (state, action: UpdateQnA2Action) => {
            state.qna2 = action.payload;
        },
        clearStateForm4Irve: (state) => {
            state.qna1 = null;
            state.qna2 = null;
        }
    }
});

export const {
    setStateForm4QnA1Irve,
    setStateForm4QnA2Irve,
    clearStateForm4Irve
} = form4Slice.actions;

export default form4Slice.reducer;
