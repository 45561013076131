import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    User,
    UserManager,
    UserPM,
    UserAdmin,
    UserCustomer,
    UserInstaller,
    UserStore,
    UserCustomerSubscriber
} from '../../../models/common/user.models';

type UpdateUserAction = PayloadAction<User>;
type UpdateUserAdminAction = PayloadAction<UserAdmin>;
type UpdateUserAdminListAction = PayloadAction<Array<UserAdmin>>;

type UpdateUserCustomerAction = PayloadAction<UserCustomer>;
type UpdateUserInstallerAction = PayloadAction<UserInstaller>;
type UpdateUserListInstallerAction = PayloadAction<Array<UserInstaller>>;

type UpdateUserUserManagerAction = PayloadAction<UserManager>;
type UpdateUserPMAction = PayloadAction<UserPM>;
type UpdateUserCustomerSubscriber = PayloadAction<UserCustomerSubscriber>;

const initialState: UserStore = {
    user: null,
    userAdmin: null,
    userCustomer: null,
    userListAdmin: null,
    userListInstaller: null,
    userInstaller: null,
    UserCustomerSubscriber: null,
    userManager: null,
    userPM: null,
    isLogged: false
};

const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        setStateUserIrve: (state, action: UpdateUserAction) => {
            state.user = action.payload;
            state.isLogged = true;
        },
        setStateUserAdminIrve: (state, action: UpdateUserAdminAction) => {
            state.userAdmin = action.payload;
            state.isLogged = true;
        },

        setStateUserListAdmin: (state, action: UpdateUserAdminListAction) => {
            state.userListAdmin = action.payload;
        },
        setStateUserCustomerIrve: (state, action: UpdateUserCustomerAction) => {
            state.userCustomer = action.payload;
            state.isLogged = true;
        },
        setStateUserInstallerIrve: (
            state,
            action: UpdateUserInstallerAction
        ) => {
            state.userInstaller = action.payload;
            state.isLogged = true;
        },

        setStateUserInstallerList: (
            state,
            action: UpdateUserListInstallerAction
        ) => {
            state.userListInstaller = action.payload;
        },
        setStateUserManagerIrve: (
            state,
            action: UpdateUserUserManagerAction
        ) => {
            state.userManager = action.payload;
            state.isLogged = true;
        },
        setStateUserPMIrve: (state, action: UpdateUserPMAction) => {
            state.userPM = action.payload;
            state.isLogged = true;
        },

        setStateUserCustomerSubscriberIrve: (
            state,
            action: UpdateUserCustomerSubscriber
        ) => {
            state.UserCustomerSubscriber = action.payload;
        },

        clearStateUsersIrve: (state) => {
            state.user = null;
            state.userAdmin = null;
            state.userCustomer = null;
            state.userInstaller = null;
            state.userManager = null;
            state.userListAdmin = null;
            state.userListInstaller = null;
            state.userPM = null;
            state.isLogged = false;
            state.UserCustomerSubscriber = null;
        }
    }
});

export const {
    setStateUserIrve,
    setStateUserAdminIrve,
    setStateUserListAdmin,
    setStateUserCustomerIrve,
    setStateUserInstallerIrve,
    setStateUserPMIrve,
    setStateUserCustomerSubscriberIrve,
    setStateUserManagerIrve,
    setStateUserInstallerList,
    clearStateUsersIrve
} = userSlice.actions;

export default userSlice.reducer;
