import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    ResultComparatorStation,
    ResultComparatorInstaller,
    ResultComparatorStore
} from '../../../models/irve/resultsComparator.models';

type UpdatePossibleStationsAction = PayloadAction<ResultComparatorStation[]>;
type UpdateSelectedStationAction = PayloadAction<ResultComparatorStation>;
type UpdatePossibleInstallersAction = PayloadAction<
    ResultComparatorInstaller[]
>;
type UpdateSelectedInstallerAction = PayloadAction<ResultComparatorInstaller>;
type UpdateLoadingResultAction = PayloadAction<true | false>;

const initialState: ResultComparatorStore = {
    possibleStations: null,
    possibleInstallers: null,
    selectedStation: null,
    selectedInstaller: null,
    loadingResult: false
};

const resultComparatorSlice = createSlice({
    name: 'resultComparatorSlice',
    initialState,
    reducers: {
        setStatePossibleStationsIrve: (
            state,
            action: UpdatePossibleStationsAction
        ) => {
            state.possibleStations = action.payload;
        },
        setStatePossibleInstallersIrve: (
            state,
            action: UpdatePossibleInstallersAction
        ) => {
            state.possibleInstallers = action.payload;
        },
        setStateSelectedStationIrve: (
            state,
            action: UpdateSelectedStationAction
        ) => {
            state.selectedStation = action.payload;
        },
        setStateSelectedInstallerIrve: (
            state,
            action: UpdateSelectedInstallerAction
        ) => {
            state.selectedInstaller = action.payload;
        },
        setStateLoadingResultIrve: (
            state,
            action: UpdateLoadingResultAction
        ) => {
            state.loadingResult = action.payload;
        },
        clearStateResultComparatiorIrve: (state) => {
            state.possibleStations = null;
            state.possibleInstallers = null;
            state.selectedStation = null;
            state.selectedInstaller = null;
            state.loadingResult = false;
        }
    }
});

export const {
    setStatePossibleStationsIrve,
    setStateSelectedStationIrve,
    setStateSelectedInstallerIrve,
    setStatePossibleInstallersIrve,
    setStateLoadingResultIrve,
    clearStateResultComparatiorIrve
} = resultComparatorSlice.actions;

export default resultComparatorSlice.reducer;
