import { RouteType } from '@/models/common/route.models';
import { userInstallerB2BPaths, userInstallerB2CApartmentPaths, userInstallerB2CHousePaths, userInstallerPaths, userInstallerSetupPaths } from '@/routes/routesPathsIrve';

import {
    AccountBalanceTwoTone,
    ApartmentTwoTone,
    BusinessTwoTone,
    DashboardCustomizeTwoTone,
    EarbudsBatteryTwoTone,
    EvStationTwoTone,
    HandymanTwoTone,
    HomeTwoTone,
    LanguageTwoTone,
    LocalOfferTwoTone,
    ManageAccountsTwoTone
} from '@mui/icons-material';

import PageLayout from '@/layouts/PageLayout';

import { RegionsAddRoute, RegionsRoute } from './Regions.routes';

import { B2BArchivedRoute, B2BMessengerRoute, B2BOnGoingProfileRoute, B2BOnGoingRoute, B2COnGoingProfileRoute } from './B2B.routes';

import { B2CApartmentsArchivedRoute, B2CApartmentsOnGoingRoute, B2CChangeStationsRoute, B2CHouseOnGoingRoute, B2CHousesArchivedRoute, B2CInvoicePreviewRoute } from './B2C.routes';

import { StationsAddRoute, StationsRoute } from './Stations.routes';

import { AccessoriesAddRoute, AccessoriesRoute } from './Accessories.routes';

import { MeProfileInstallationsRoute, MeProfileLegalInformationsRoute, MeProfileOfferSimulationRoute, MeProfileRoute } from './Me.routes';

import { OverviewsRoute } from './Overviews.routes';

const accountInstallerRoutes: RouteType[] = [
    {
        path: userInstallerPaths.overview,
        element: <OverviewsRoute />,
        state: 'userInstallerPaths.overview',
        sidebarProps: {
            displayText: 'Accueil',
            icon: <DashboardCustomizeTwoTone />
        },
        show: true,
        index: null,
        child: null,
        label: null
    },
    {
        path: userInstallerB2BPaths.onGoingProfile,
        element: <B2BOnGoingProfileRoute />,
        state: 'userInstallerB2BPaths.opportunitiesB2BOnGoingProfile',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: userInstallerB2BPaths.messenger,
        element: <B2BMessengerRoute />,
        state: 'userInstallerB2BPaths.messenger',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },

    {
        path: undefined,
        element: null,
        state: 'undefined',
        sidebarProps: null,
        show: true,
        index: null,
        child: null,
        label: 'Opportunités'
    },

    {
        path: userInstallerB2CHousePaths.index,
        element: <PageLayout />,
        state: 'userInstallerB2CHousePaths.index',
        sidebarProps: {
            displayText: 'Maisons',
            icon: <HomeTwoTone />
        },
        show: true,
        label: null,
        child: [
            {
                path: userInstallerB2CHousePaths.onGoing,
                element: <B2CHouseOnGoingRoute />,
                state: 'userInstallerB2CHousePaths.onGoing',
                sidebarProps: {
                    displayText: 'En cours',
                    icon: null
                },
                show: true,
                label: null,
                child: null,
                index: null
            },
            {
                path: userInstallerB2CHousePaths.archived,
                element: <B2CHousesArchivedRoute />,
                state: 'userInstallerB2CHousePaths.archived',
                sidebarProps: {
                    displayText: 'Archivées',
                    icon: null
                },
                show: true,
                label: null,
                child: null,
                index: null
            }
        ],
        index: null
    },
    {
        path: userInstallerB2CHousePaths.onGoingProfile,
        element: <B2COnGoingProfileRoute />,
        state: 'userInstallerB2CHousePaths.onGoingProfile',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: userInstallerB2CHousePaths.changeStation,
        element: <B2CChangeStationsRoute />,
        state: 'userInstallerB2CHousePaths.changeStation',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: userInstallerB2CHousePaths.preview,
        element: <B2CInvoicePreviewRoute />,
        state: 'userInstallerB2CHousePaths.preview',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },

    {
        path: userInstallerB2CApartmentPaths.index,
        element: <PageLayout />,
        state: 'userInstallerB2CApartmentPaths.index',
        sidebarProps: {
            displayText: 'Appartements',
            icon: <ApartmentTwoTone />
        },
        show: true,
        label: null,
        child: [
            {
                path: userInstallerB2CApartmentPaths.onGoing,
                element: <B2CApartmentsOnGoingRoute />,
                state: 'userInstallerB2CApartmentPaths.onGoing',
                sidebarProps: {
                    displayText: 'En cours',
                    icon: null
                },
                show: true,
                label: null,
                child: null,
                index: null
            },
            {
                path: userInstallerB2CApartmentPaths.archived,
                element: <B2CApartmentsArchivedRoute />,
                state: 'userInstallerB2CApartmentPaths.archived',
                sidebarProps: {
                    displayText: 'Archivées',
                    icon: null
                },
                show: true,
                label: null,
                child: null,
                index: null
            }
        ],
        index: null
    },
    {
        path: userInstallerB2CApartmentPaths.onGoingProfile,
        element: <OverviewsRoute />,
        state: 'userInstallerB2CApartmentPaths.onGoingProfile',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },

    {
        path: userInstallerB2BPaths.index,
        element: <PageLayout />,
        state: 'userInstallerB2BPaths.index',
        sidebarProps: {
            displayText: 'Etudes',
            icon: <BusinessTwoTone />
        },
        show: true,
        label: null,
        child: [
            {
                path: userInstallerB2BPaths.onGoing,
                element: <B2BOnGoingRoute />,
                state: 'userInstallerB2BPaths.onGoing',
                sidebarProps: {
                    displayText: 'En cours',
                    icon: null
                },
                show: true,
                label: null,
                child: null,
                index: null
            },
            {
                path: userInstallerB2BPaths.archived,
                element: <B2BArchivedRoute />,
                state: 'userInstallerB2BPaths.archived',
                sidebarProps: {
                    displayText: 'Archivées',
                    icon: null
                },
                show: true,
                label: null,
                child: null,
                index: null
            }
        ],
        index: null
    },
    {
        path: userInstallerB2BPaths.onGoingProfile,
        element: <OverviewsRoute />,
        state: 'userInstallerB2BPaths.onGoingProfile',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },

    {
        path: undefined,
        element: null,
        state: 'undefined',
        sidebarProps: null,
        show: true,
        index: null,
        child: null,
        label: 'Mes données'
    },

    {
        path: userInstallerSetupPaths.profile,
        element: <MeProfileRoute />,
        state: 'userInstallerSetupPaths.informations',
        sidebarProps: {
            displayText: 'Mon profil',
            icon: <ManageAccountsTwoTone />
        },
        show: true,
        index: false,
        child: null,
        label: null
    },
    {
        path: userInstallerSetupPaths.legalInformations,
        element: <MeProfileLegalInformationsRoute />,
        state: 'userInstallerSetupPaths.legalInformations',
        sidebarProps: {
            displayText: 'Mes informations legales',
            icon: <AccountBalanceTwoTone />
        },
        show: true,
        index: false,
        child: null,
        label: null
    },
    {
        path: userInstallerSetupPaths.installations,
        element: <MeProfileInstallationsRoute />,
        state: 'userInstallerSetupPaths.installations',
        sidebarProps: {
            displayText: 'Mes prix installations',
            icon: <EarbudsBatteryTwoTone />
        },
        show: true,
        index: false,
        child: null,
        label: null
    },
    {
        path: userInstallerSetupPaths.stations,
        element: <StationsRoute />,
        state: 'userInstallerSetupPaths.stations',
        sidebarProps: {
            displayText: 'Mes bornes',
            icon: <EvStationTwoTone />
        },
        show: true,
        index: false,
        child: null,
        label: null
    },
    {
        path: userInstallerSetupPaths.stationsAdd,
        element: <StationsAddRoute />,
        state: 'userInstallerB2BPaths.onGoingProfile',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: userInstallerSetupPaths.accessories,
        element: <AccessoriesRoute />,
        state: 'userInstallerSetupPaths.accessories',
        sidebarProps: {
            displayText: 'Mes accessoires',
            icon: <HandymanTwoTone />
        },
        show: true,
        index: false,
        child: null,
        label: null
    },
    {
        path: userInstallerSetupPaths.accessoriesAdd,
        element: <AccessoriesAddRoute />,
        state: 'userInstallerB2BPaths.accessoriesAdd',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: userInstallerSetupPaths.regions,
        element: <RegionsRoute />,
        state: 'userInstallerSetupPaths.regions',
        sidebarProps: {
            displayText: 'Mes regions',
            icon: <LanguageTwoTone />
        },
        show: true,
        index: false,
        child: null,
        label: null
    },
    {
        path: userInstallerSetupPaths.regionsAdd,
        element: <RegionsAddRoute />,
        state: 'userInstallerSetupPaths.regionsAdd',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    }
    /*   {
        path: userInstallerSetupPaths.offerSimulation,
        element: <MeProfileOfferSimulationRoute />,
        state: 'userInstallerSetupPaths.offerSimulation',
        sidebarProps: {
            displayText: 'Simulations de mes offres',
            icon: <LocalOfferTwoTone />
        },
        show: true,
        index: false,
        child: null,
        label: null
    } */
];

export default accountInstallerRoutes;
