import { api } from './config';
import { OvhPresignedUrl } from '@/models/irve/file.models';

export const getfilePresignedUrl = async (
    fileName: string
): Promise<OvhPresignedUrl> => {
    try {
        const response = await api.get(
            `api/file/get-ovh-s3-presigned-url/${fileName}`
        );
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};
