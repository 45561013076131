import Advenir from './logoAdvenir.svg';
import ChargeInForLoader from './logoChargeInForLoader.svg';
import ChargeInLong from './logoChargeinLong.svg';
import ChargeInPv from './logo-pv.svg';
import ChargeInPvSide from './logo-charge-in-pv.svg';
import ChargeInIrveSide from './logo-charge-in-irve.svg';

import ChargeInSqaure from './logoChargeinSquare.svg';

export const LogoAdvenir = Advenir;
export const LogoChargeInForLoader = ChargeInForLoader;
export const LogoChargeInLongIrve = ChargeInLong;
export const LogoChargeInLongPv = ChargeInPv;
export const LogoChargeInSidePv = ChargeInPvSide;
export const LogoChargeInSideIrve = ChargeInIrveSide;

export const LogoChargeInSquare = ChargeInSqaure;
