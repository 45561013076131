import { lazy } from 'react';
import Loader from '@/components/common/Loader';

export const B2CInvoicePreviewRoute = Loader(
    lazy(() => import('@/pages/dashboard/B2C/irve/Profile/InvoicePreview'))
);

export const B2CMessengerRoute = Loader(
    lazy(() => import('@/pages/dashboard/B2C/irve/Profile/Messenger'))
);
