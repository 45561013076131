import { ReactNode } from 'react';
import { Route, Navigate, useLocation } from 'react-router-dom';
import PageWrapper from '@/layouts/PageWrapper';
import { RouteType } from '@/models/common/route.models';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { selectUser } from '@/store/selector';

import LoginRoutes from '../pages/routes/login/irve';
import accountCustomerRoutes from '../pages/routes/accountCustomer/irve';
import accountInstallerRoutes from '../pages/routes/accountInstaller/irve';
import { comparatorRoutesIrve } from '@/pages/routes/comparator/irve';
import { DashboardRoutesIrve } from '@/pages/routes/dashboard/irve';
import { comparatorRoutesPv } from '@/pages/routes/comparator/pv';
import { DashboardRoutesPv } from '@/pages/routes/dashboard/pv';
import accountCustomerRoutesPv from '@/pages/routes/accountCustomer/pv';
import accountInstallerRoutesPv from '@/pages/routes/accountInstaller/pv';

const generateRoute = (routes: RouteType[]): ReactNode => {
    // const {user} = useSelector((state: RootState) => selectUser(state));
    //
    // const { pathname } = window.location;

    return routes.map((route, index) =>
        route.index ? (
            route.path?.includes('/account-installer/') ? (
                <Route
                    index
                    path={route.path}
                    element={
                        <PrivateRouteInstaller
                            component={
                                <PageWrapper state={route.state}>
                                    {route.element}
                                </PageWrapper>
                            }
                        />
                    }
                    key={index}
                />
            ) : route.path?.includes('/account-customer-b2c/') ? (
                <Route
                    index
                    path={route.path}
                    element={
                        <PrivateRouteCustomer
                            component={
                                <PageWrapper state={route.state}>
                                    {route.element}
                                </PageWrapper>
                            }
                        />
                    }
                    key={index}
                />
            ) : route.path?.includes('/dashboard/') ? (
                <Route
                    index
                    path={route.path}
                    element={
                        <PrivateRouteAdmin
                            component={
                                <PageWrapper state={route.state}>
                                    {route.element}
                                </PageWrapper>
                            }
                        />
                    }
                    key={index}
                />
            ) : (
                <Route
                    index
                    path={route.path}
                    element={
                        <PageWrapper state={route.state}>
                            {route.element}
                        </PageWrapper>
                    }
                    key={index}
                />
            )
        ) : route.path?.includes('/account-installer/') ? (
            <Route
                path={route.path}
                element={
                    <PrivateRouteInstaller
                        component={
                            <PageWrapper
                                state={route.child ? undefined : route.state}
                            >
                                {route.element}
                            </PageWrapper>
                        }
                    />
                }
                key={index}
            >
                {route.child && generateRoute(route.child)}
            </Route>
        ) : route.path?.includes('/account-customer-b2c/') ? (
            <Route
                path={route.path}
                element={
                    <PrivateRouteCustomer
                        component={
                            <PageWrapper
                                state={route.child ? undefined : route.state}
                            >
                                {route.element}
                            </PageWrapper>
                        }
                    />
                }
                key={index}
            >
                {route.child && generateRoute(route.child)}
            </Route>
        ) : route.path?.includes('/dashboard/') ? (
            <Route
                path={route.path}
                element={
                    <PrivateRouteAdmin
                        component={
                            <PageWrapper
                                state={route.child ? undefined : route.state}
                            >
                                {route.element}
                            </PageWrapper>
                        }
                    />
                }
                key={index}
            >
                {route.child && generateRoute(route.child)}
            </Route>
        ) : (
            <Route
                path={route.path}
                element={
                    <PageWrapper state={route.child ? undefined : route.state}>
                        {route.element}
                    </PageWrapper>
                }
                key={index}
            >
                {route.child && generateRoute(route.child)}
            </Route>
        )
    );
};

const PrivateRouteAdmin = ({
    component: Component
}: {
    component: JSX.Element;
}) => {
    const { userAdmin } = useSelector((state: RootState) => selectUser(state));
    return !userAdmin?.roles[0].includes('ROLE_ADMIN') ? (
        <Navigate to="/login" />
    ) : (
        Component
    );
    // return  Component;
};

const PrivateRouteInstaller = ({
    component: Component
}: {
    component: JSX.Element;
}) => {
    const { userInstaller } = useSelector((state: RootState) =>
        selectUser(state)
    );
    return !userInstaller?.roles[0].includes('ROLE_INSTALLER') ? (
        <Navigate to="/login" />
    ) : (
        Component
    );
    // return  Component;
};

const PrivateRouteCustomer = ({
    component: Component
}: {
    component: JSX.Element;
}) => {
    const { userCustomer } = useSelector((state: RootState) =>
        selectUser(state)
    );
    return !userCustomer?.roles[0].includes('ROLE_CUSTOMER') ? (
        <Navigate to="/login" />
    ) : (
        Component
    );
    // return  Component;
};

//
// const allRoutes: Route[] = [
//     @.DashboardRoutes,
//     @.ComparatorRoutes,
//     @.CustomerB2CRoutes,
//     @.InstallerRoutes,
//     @.LoginERoutes
// ];

export const routes: ReactNode = generateRoute([
    ...LoginRoutes,
    ...accountCustomerRoutes,
    ...accountCustomerRoutesPv,
    ...comparatorRoutesIrve,
    ...accountInstallerRoutesPv,
    ...comparatorRoutesPv,
    ...DashboardRoutesIrve,
    ...DashboardRoutesPv,

    ...accountInstallerRoutes
]);
