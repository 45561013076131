import { lazy } from 'react';
import Loader from '@/components/common/Loader';

export const B2CChangeStationsRoute = Loader(
    lazy(() => import('@/pages/accountInstaller/B2C/pv/Profile/ChangeStations'))
);
export const B2CInvoicePreviewRoute = Loader(
    lazy(() => import('@/pages/accountInstaller/B2C/pv/Profile/InvoicePreview'))
);

export const B2CHouseOnGoingRoute = Loader(
    lazy(() => import('@/pages/accountInstaller/B2C/pv/HousesOnGoing'))
);

export const B2CHousesArchivedRoute = Loader(
    lazy(() => import('@/pages/accountInstaller/B2C/pv/HousesArchived'))
);

export const B2CApartmentsOnGoingRoute = Loader(
    lazy(() => import('@/pages/accountInstaller/B2C/pv/ApartmentsOnGoing'))
);

export const B2CApartmentsArchivedRoute = Loader(
    lazy(() => import('@/pages/accountInstaller/B2C/pv/ApartmentsArchived'))
);
