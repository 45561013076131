import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QnA, FormHousingPv } from '@/models/irve/form.model';

type UpdateQ1Action = PayloadAction<QnA>;

const initialState: FormHousingPv = {
    qna1: null,
    qna2: null,
    qna3: null,
    qna4: null
};

const { actions, reducer } = createSlice({
    name: 'formHousingPv',
    initialState,
    reducers: {
        setStateFormHousingQnA1Pv: (state, action: UpdateQ1Action) => {
            state.qna1 = action.payload;
        },
        setStateFormHousingQnA2Pv: (state, action: UpdateQ1Action) => {
            state.qna2 = action.payload;
        },
        setStateFormHousingQnA3Pv: (state, action: UpdateQ1Action) => {
            state.qna3 = action.payload;
        },
        setStateFormHousingQnA4Pv: (state, action: UpdateQ1Action) => {
            state.qna4 = action.payload;
        },

        clearStateFormHousingPv: (state) => {
            state.qna1 = null;
            state.qna2 = null;
            state.qna3 = null;
            state.qna4 = null;
        }
    }
});

export const {
    setStateFormHousingQnA1Pv,
    setStateFormHousingQnA2Pv,
    setStateFormHousingQnA3Pv,
    setStateFormHousingQnA4Pv,
    clearStateFormHousingPv
} = actions;

export default reducer;
