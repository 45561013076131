import { lazy } from 'react';
import Loader from '@/components/common/Loader';

export const HousesOngoingRoute = Loader(
    lazy(() => import('@/pages/dashboard/B2C/irve/HousesOnGoing'))
);

export const HousesArchivedRoute = Loader(
    lazy(() => import('@/pages/dashboard/B2C/irve/HousesArchived'))
);

export const HousesOngoingProfileRoute = Loader(
    lazy(() => import('@/pages/dashboard/B2C/irve/Profile/Profile'))
);

export const HousesOngoingProfileChangeStationsRoute = Loader(
    lazy(() => import('@/pages/dashboard/B2C/irve/Profile/ChangeStations'))
);

export const HousesOngoingProfileChangeInstallersRoute = Loader(
    lazy(() => import('@/pages/dashboard/B2C/irve/Profile/ChangeInstallers'))
);
