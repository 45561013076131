import { api } from './config';
import { UserAdmin, UserAdminSubscriber, UserInstaller, UserInstallerValidityIrve, UserManager, UserManagerSubscriber, UserPM, UserPMSubscriber } from '@/models/common/user.models';
import { AccessoryInstaller, AccessoryInstallerInstaller, AccessoryInstallerSubscriber, AccessoryStation, AccessoryStationSubscriber } from '@/models/irve/accessory.models.type';
import { Station, StationInformations, StationInstaller, StationInstallerProfile } from '@/models/irve/station.models';
import { apiFormdata } from './configFormData';
import { B2C, B2CAccessories, B2CComment, B2CMessage, B2CTableHeader } from '@/models/irve/b2c.models';
import { ResultComparatorInstaller, ResultComparatorStation } from '@/models/irve/resultsComparator.models';
import { B2B, B2BComment, B2BConversation, B2BInstallersV2, B2BMessage, B2BStatus, B2BTableHeader } from '@/models/irve/b2b.models';
import { UserInstallerLegalInformationsToUpdate, UserInstallerProfileToUpdate } from '@/models/irve/installer.models';
import { installerMeUpdateLegalInformationsDoc } from './installer.services';
import { YousignResponse } from '@/models/irve/yousign.models';

export const boMeAdminProfile = async (): Promise<UserAdmin> => {
    try {
        const response = await api.get(`api/bo/me/admin`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boGetAllAccessoriesInstaller = async (): Promise<AccessoryInstaller[]> => {
    try {
        const response = await api.get(`api/bo/accessory/installer`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boGetOneAccessoryInstaller = async (id: number): Promise<AccessoryInstaller> => {
    try {
        const response = await api.get(`api/bo/accessory/installer/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boCreateAccessoryInstaller = async (accessory: AccessoryInstallerSubscriber): Promise<AccessoryInstaller> => {
    try {
        const response = await api.post(`api/bo/accessory/installer/create`, accessory);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boUpdateAccessoryInstaller = async (id: number, accessoryInstaller: AccessoryInstallerSubscriber | null): Promise<AccessoryInstaller> => {
    try {
        const response = await api.put(`api/bo/accessory/installer/update/${id}`, accessoryInstaller);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boGetAllAccessoriesStationMonophased = async (): Promise<AccessoryStation[]> => {
    try {
        const response = await api.get(`api/bo/accessory/station/monophased`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boGetAllAccessoriesStationTriphased = async (): Promise<AccessoryStation[]> => {
    try {
        const response = await api.get(`api/bo/accessory/station/triphased`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boGetOneAccessoryStationMonophased = async (id: number): Promise<AccessoryStation> => {
    try {
        const response = await api.get(`api/bo/accessory/station/monophased/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boGetOneAccessoryStationTriphased = async (id: number): Promise<AccessoryStation> => {
    try {
        const response = await api.get(`api/bo/accessory/station/triphased/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boCreateAccessoryStationMonophased = async (accessory: AccessoryStationSubscriber): Promise<AccessoryStation> => {
    try {
        const response = await api.post(`api/bo/accessory/station/monophased/create`, accessory);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boCreateAccessoryStationTriphased = async (accessory: AccessoryStationSubscriber): Promise<AccessoryStation> => {
    try {
        const response = await api.post(`api/bo/accessory/station/triphased/create`, accessory);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boUpdateAccessoryStationMonophased = async (id: number, accessoryStationSubscriber: AccessoryStationSubscriber | null): Promise<AccessoryStation> => {
    try {
        const response = await api.put(`api/bo/accessory/station/monophased/update/${id}`, accessoryStationSubscriber);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boAccessoryStationMonophasedActiveInActive = async (id: number, trueFalse: boolean): Promise<AccessoryStation> => {
    try {
        const response = await api.put(`api/bo/accessory/station/monophased/activation/${id}/${trueFalse}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boUpdateAccessoryStationTriphased = async (id: number, accessoryStationSubscriber: AccessoryStationSubscriber | null): Promise<AccessoryStation> => {
    try {
        const response = await api.put(`api/bo/accessory/station/triphased/update/${id}`, accessoryStationSubscriber);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boAccessoryStationTriphasedActiveInActive = async (id: number, trueFalse: boolean): Promise<AccessoryStation> => {
    try {
        const response = await api.put(`api/bo/accessory/station/triphased/activation/${id}/${trueFalse}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boAccessoryInstallerActiveInActive = async (id: number, trueFalse: boolean): Promise<AccessoryStation> => {
    try {
        const response = await api.put(`api/bo/accessory/installer/activation/${id}/${trueFalse}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boGetAllStations = async (): Promise<Station[]> => {
    try {
        const response = await api.get(`api/bo/station`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boGetOneStation = async (id: number): Promise<Station> => {
    try {
        const response = await api.get(`api/bo/station/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boCreateStation = async (station: FormData): Promise<Station> => {
    try {
        const response = await apiFormdata.post(`api/bo/station/create`, station);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boUpdateStation = async (id: number, station: FormData | null): Promise<Station> => {
    try {
        const response = await apiFormdata.post(`api/bo/station/update/${id}`, station);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boUpdateStationInformations = async (id: number, station: StationInformations): Promise<Station> => {
    try {
        const response = await api.put(`api/bo/station/${id}/update-informations`, station);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boUpdateStationTechnicalDoc = async (id: number, station: FormData): Promise<Station> => {
    try {
        const response = await apiFormdata.post(`api/bo/station/${id}/update-technical-file`, station);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boUpdateStationLogo = async (id: number, station: FormData): Promise<Station> => {
    try {
        const response = await apiFormdata.post(`api/bo/station/${id}/update-logo`, station);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boGetAllAdmins = async (): Promise<UserAdmin[]> => {
    try {
        const response = await api.get(`api/bo/admin`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boGetOneAdmin = async (id: number): Promise<UserAdmin> => {
    try {
        const response = await api.get(`api/bo/admin/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boCreateAdmin = async (user: UserAdminSubscriber): Promise<UserAdmin> => {
    try {
        const response = await api.post(`api/bo/admin/create`, user);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boUpdateAdmin = async (id: number, user: UserAdminSubscriber | null): Promise<UserAdmin> => {
    try {
        const response = await api.put(`api/bo/admin/update/${id}`, user);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boAdminSetActive = async (id: number, active: boolean): Promise<UserAdmin> => {
    try {
        const response = await api.put(`api/bo/admin/${id}/set-active/${active}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boGetAllManagers = async (): Promise<UserManager[]> => {
    try {
        const response = await api.get(`api/bo/ceo`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boGetOneManager = async (id: number): Promise<UserManager> => {
    try {
        const response = await api.get(`api/bo/ceo/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boCreateManager = async (user: UserManagerSubscriber): Promise<UserManager> => {
    try {
        const response = await api.post(`api/bo/ceo/create`, user);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boUpdateManager = async (id: number, user: UserManagerSubscriber | null): Promise<UserManager> => {
    try {
        const response = await api.put(`api/bo/ceo/update/${id}`, user);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boManagerSetActive = async (id: number, active: boolean): Promise<UserManager> => {
    try {
        const response = await api.put(`api/bo/ceo/${id}/set-active/${active}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boGetAllProjectManagers = async (): Promise<UserPM[]> => {
    try {
        const response = await api.get(`api/bo/pm`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boGetOneProjectManager = async (id: number): Promise<UserPM> => {
    try {
        const response = await api.get(`api/bo/pm/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boCreateProjectManager = async (user: UserPMSubscriber): Promise<UserPM> => {
    try {
        const response = await api.post(`api/bo/pm/create`, user);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boUpdateProjectManager = async (id: number, user: UserPMSubscriber | null): Promise<UserPM> => {
    try {
        const response = await api.put(`api/bo/pm/update/${id}`, user);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boProjectManagerSetActive = async (id: number, active: boolean): Promise<UserPM> => {
    try {
        const response = await api.put(`api/bo/pm/${id}/set-active/${active}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boGetAllInstallers = async (): Promise<UserInstaller[]> => {
    try {
        const response = await api.get('api/bo/installer');
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boGetOneInstaller = async (id: number): Promise<UserInstaller> => {
    try {
        const response = await api.get(`api/bo/installer/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boUpdateOneInstaller = async (id: number, user: UserInstaller | null) => {
    try {
        const response = await apiFormdata.put(`api/bo/installer/update/${id}`, user);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boCreateInstallerWAllInformations = async (user: FormData): Promise<UserInstaller> => {
    try {
        const response = await apiFormdata.post(`api/bo/installer/create-w-all-informations`, user);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boDeleteOneInstaller = async (id: number): Promise<UserInstaller> => {
    try {
        const response = await api.delete(`api/bo/installer/delete/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boAddMultipleStationsToInstaller = async (id: number, stationsList: Station[]) => {
    try {
        const response = await api.post(`api/bo/installer/${id}/station/add-multiple`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boOpportunitiesHousingType = async (housingType: string, ongoinArchived: string): Promise<B2C[]> => {
    try {
        const response = await api.get(`api/bo/opportunity/${housingType}/${ongoinArchived}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boOpportunitiesHousingTypeTableHeader = async (housingType: string, ongoinArchived: string): Promise<B2CTableHeader[]> => {
    try {
        const response = await api.get(`api/bo/opportunity/${housingType}/${ongoinArchived}/table-header`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boGetOneOpportunity = async (id: number): Promise<B2C> => {
    try {
        const response = await api.get(`api/bo/opportunity/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boOpportunitySetStatus = async (id: number, status: string): Promise<B2C> => {
    try {
        const response = await api.post(`api/bo/opportunity/status/sellsy/${id}/set/${status}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boOpportunityAddComment = async (id: number, comment: string): Promise<B2C> => {
    try {
        const response = await api.post(`api/bo/opportunity/${id}/opportunity-add-comment`, { comment: comment });
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boGetOpportunityComments = async (id: number): Promise<B2CComment[]> => {
    try {
        const response = await api.get(`api/bo/opportunity/${id}/opportunity-comments`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boGetPossibleStations = async (id: number): Promise<ResultComparatorStation[]> => {
    try {
        const response = await api.get(`api/bo/opportunity/station/${id}/get-possible-stations`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const boGetPossibleInstaller = async (id: number, stationInstaller: StationInstaller): Promise<ResultComparatorInstaller[]> => {
    try {
        const response = await api.post(`api/bo/opportunity/installer/${id}/get-possible-installers`, stationInstaller);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const boAddSelectedStation = async (id: number, selectedStation: ResultComparatorStation): Promise<B2C> => {
    try {
        const response = await api.post(`api/bo/opportunity/station/${id}/add-selected-station`, selectedStation);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const boAddSelectedInstaller = async (id: number, selectedInstaller: ResultComparatorInstaller): Promise<B2C> => {
    try {
        const response = await api.post(`api/bo/opportunity/installer/${id}/add-selected-installer`, selectedInstaller);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const boGetAcessoriesInstallerInstaller = async (id: number): Promise<AccessoryInstallerInstaller[]> => {
    try {
        const response = await api.get(`api/bo/opportunity/${id}/get-all-acessories-installers`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const boGetSelectedAccessoriesInstallerInstaller = async (id: number): Promise<AccessoryInstallerInstaller[]> => {
    try {
        const response = await api.get(`api/bo/opportunity/${id}/get-selected-acessories-installers`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const boUpdateSelectedAccessoriesInstallerInstaller = async (id: number, accessoriesList: AccessoryInstallerInstaller[], accessoryStation: boolean): Promise<B2C> => {
    try {
        const response = await api.post(`api/bo/opportunity/${id}/update-selected-acessories-installers`, {
            accessoriesInstallerInstaller: accessoriesList,
            accessoryStationInstaller: accessoryStation
        });
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const boGetOpportunityAccessories = async (id: number): Promise<B2CAccessories> => {
    try {
        const response = await api.get(`api/bo/opportunity/${id}/get-opportunity-accessories`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const boGetOpportunityAllMessages = async (id: number | undefined): Promise<B2CMessage[]> => {
    try {
        const response = await api.get(`api/bo/opportunity/${id}/get-all-messages`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const boAddOpportunityMessage = async (id: number | undefined, message: string): Promise<B2CMessage[]> => {
    try {
        const response = await api.post(`api/bo/opportunity/${id}/add-message`, { message: message });
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const boAddOpportunityDoc = async (id: number | undefined, form: FormData): Promise<B2C> => {
    try {
        const response = await apiFormdata.post(`api/bo/opportunity/${id}/add-doc`, form);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const boOpportunityAddDiscount = async (id: number, discount: string): Promise<B2C> => {
    try {
        const response = await api.post(`api/bo/opportunity/${id}/add-discount`, { discount });
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const boOpportunitySetYousignLink = async (id: number, yousignLink: string): Promise<B2C> => {
    try {
        const response = await api.post(`api/bo/opportunity/${id}/set-yousign-link`, { yousignLink });
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const boOpportunitySetPriceWFinancialHelps = async (id: number): Promise<B2C> => {
    try {
        const response = await api.post(`api/bo/opportunity/${id}/add-discount`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const boCreateInstallerProfile = async (formData: FormData): Promise<UserInstaller> => {
    try {
        const response = await apiFormdata.post(`api/bo/installer/profile/create`, formData);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI::boCreateInstallerProfile');
    }
};

export const boCreateInstallerProfileSimple = async (formData: FormData): Promise<UserInstaller> => {
    try {
        const response = await apiFormdata.post(`api/bo/installer/profile/create-simple`, formData);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI::boCreateInstallerProfileSimple');
    }
};

export const boInstallerAddLegalInformations = async (id: number, formData: FormData): Promise<UserInstaller> => {
    try {
        const response = await apiFormdata.post(`api/bo/installer/${id}/add-legal-informations`, formData);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boInstallerAddSelectedStations = async (id: number, formData: FormData): Promise<UserInstaller> => {
    try {
        const response = await apiFormdata.post(`api/bo/installer/${id}/add-selected-stations`, formData);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boInstallerGetAllStationsWPrices = async (id: number): Promise<StationInstallerProfile[]> => {
    try {
        const response = await api.get(`api/bo/installer/stations/${id}/get-all-stations-wprices`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boInstallerAddSelectedStationsProfile = async (
    id: number,
    stations: StationInstallerProfile[],
    priceWoVatStations: string[],
    priceWoVatAccMono: string[],
    priceWoVatAccTri: string[]
): Promise<UserInstaller> => {
    try {
        const response = await api.put(`api/bo/installer/stations/${id}/update`, {
            stations,
            priceWoVatStations,
            priceWoVatAccMono,
            priceWoVatAccTri
        });
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boInstallerGetSelectedStations = async (id: number): Promise<StationInstallerProfile[]> => {
    try {
        const response = await api.get(`api/bo/installer/stations/${id}/get-selected`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boInstallerAddInstallations = async (id: number, formData: FormData): Promise<UserInstaller> => {
    try {
        const response = await apiFormdata.post(`api/bo/installer/${id}/add-installations`, formData);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boInstallerUpdateInstallations = async (id: number, data: any): Promise<UserInstaller> => {
    try {
        const response = await api.put(`api/bo/installer/installations/${id}/update`, data);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boInstallerCheckValidity = async (id: number): Promise<UserInstallerValidityIrve> => {
    try {
        const response = await api.get(`api/bo/installer/check-validity/irve/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boInstallerUpdateInstallerAccessories = async (id: number, accessoryInstallerInstallers: AccessoryInstallerInstaller[]): Promise<UserInstaller> => {
    try {
        const response = await api.put(`api/bo/installer/accessories/${id}/update`, { accessoryInstallerInstallers });
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boInstallerAddAccessories = async (id: number, formData: FormData): Promise<UserInstaller> => {
    try {
        const response = await apiFormdata.post(`api/bo/installer/${id}/add-selected-accessories`, formData);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boInstallerAddDepartments = async (id: number, installerDepartments: any): Promise<UserInstaller> => {
    try {
        const response = await api.post(`api/bo/installer/departments/${id}/add`, { installerDepartments });
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boInstallerUpdateLogo = async (id: number, formData: FormData): Promise<UserInstaller> => {
    try {
        const response = await apiFormdata.post(`api/bo/installer/profile/${id}/update-logo`, formData);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boInstallerUpdateProfile = async (id: number, userInstaller: UserInstallerProfileToUpdate): Promise<UserInstaller> => {
    try {
        const response = await api.put(`api/bo/installer/profile/${id}/update-informations`, userInstaller);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boInstallerUpdateLegalInformationsDoc = async (id: number, formData: FormData): Promise<UserInstaller> => {
    try {
        const response = await apiFormdata.post(`api/bo/installer/legal-informations/${id}/update-file`, formData);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boInstallerUpdateLegalInformations = async (id: number, userInstaller: UserInstallerLegalInformationsToUpdate): Promise<UserInstaller> => {
    try {
        const response = await api.put(`api/bo/installer/legal-informations/${id}/update`, userInstaller);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boInstallerSetActive = async (id: number, active: boolean, business: 'irve' | 'pv'): Promise<UserInstaller> => {
    try {
        const response = await api.put(`api/bo/installer/activation/${id}/${business}/${active}`);
        // console.log(response);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boGetAllStudies = async (): Promise<B2B[]> => {
    try {
        const response = await api.get('api/bo/study');
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const boGetStudiesOnGoingArchived = async (ongoinArchived: string): Promise<B2B[]> => {
    try {
        const response = await api.get(`api/bo/study/table/${ongoinArchived}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boGetStudiesOnGoingArchivedTableHeader = async (ongoinArchived: string, status: string): Promise<B2BTableHeader[]> => {
    // console.log('test');
    try {
        const response = await api.get(`api/bo/study/table/${ongoinArchived}/header/${status}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boGetOneStudy = async (id: number): Promise<B2B> => {
    try {
        const response = await api.get(`api/bo/study/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const boStudySetStatus = async (id: number, status: string): Promise<B2B> => {
    try {
        const response = await api.post(`api/bo/study/status/${id}/set/${status}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boStudySetInstallerStatus = async (id: number, installerId: number, status: string): Promise<B2BStatus> => {
    try {
        const response = await api.put(`api/bo/study/${id}/update-installer-status/${installerId}/${status}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boStudySetInstallerStatusAccepted = async (id: number, installerId: number): Promise<B2BStatus> => {
    try {
        const response = await api.put(`api/bo/study/installer/${id}/update-status-accepted/${installerId}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boStudyGetInstallerStatus = async (id: number, installerId: number): Promise<B2BStatus> => {
    try {
        const response = await api.get(`api/bo/study/${id}/get-installer-status/${installerId}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boStudyGetInstallers = async (id: number): Promise<B2BInstallersV2[]> => {
    try {
        const response = await api.get(`api/bo/study/installer/${id}/get-installers`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const boAddOneStudy = async (study: FormData) => {
    try {
        const response = await apiFormdata.post(`api/bo/study/profile/create`, study);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const boStudyUpdateInfos = async (id: number, study: FormData) => {
    try {
        const response = await apiFormdata.post(`api/bo/study/profile/${id}/update-infos`, study);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const boAddStudyComment = async (id: number, comment: string) => {
    try {
        const response = await api.post(`api/bo/study/add-comment/${id}`, comment);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const boAddMessageB2B = async (id: number, message: string, installerId: number): Promise<B2BMessage[]> => {
    // return await api.get("api/me");
    try {
        const response = await api.post(`api/bo/study/installer/${id}/add-message`, { message, installerId });
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const boGetStudyConversationMessagesB2B = async (id: number, installerId: number): Promise<B2BMessage[]> => {
    // return await api.get("api/me");
    try {
        const response = await api.post(`api/bo/study/installer/${id}/get-conversation-messages`, { installerId });
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const boGetStudyConversationB2B = async (id: number, installerId: number): Promise<B2BConversation> => {
    // return await api.get("api/me");
    try {
        const response = await api.post(`api/bo/study/installer/${id}/get-conversation`, { installerId });
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const boStudyAddStudyFile = async (id: number, formData: FormData): Promise<B2B> => {
    // return await api.get("api/me");
    try {
        const response = await apiFormdata.post(`api/bo/study/file/${id}/add`, formData);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const boStudyUpdateStudyFile = async (id: number, formData: FormData): Promise<B2B> => {
    // return await api.get("api/me");
    try {
        const response = await apiFormdata.post(`api/bo/study/file/${id}/update`, formData);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const boStudyUpdateInstallers = async (id: number, installersId: number[]): Promise<B2B> => {
    // return await api.get("api/me");
    try {
        const response = await api.put(`api/bo/study/installer/${id}/update`, {
            installersId
        });
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const boGetComments = async (id: number): Promise<B2BComment[]> => {
    // return await api.get("api/me");
    try {
        const response = await api.get(`api/bo/study/comment/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const boAddComment = async (id: number, comment: string): Promise<B2BComment> => {
    // return await api.get("api/me");
    try {
        const response = await api.post(`api/bo/study/comment/${id}/add`, {
            comment
        });
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const boSendMailNrp = async (id: number): Promise<B2C> => {
    // return await api.get("api/me");
    try {
        const response = await api.post(`api/bo/opportunity/mail/${id}/nrp`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const boSendMailSdr = async (id: number): Promise<B2C> => {
    // return await api.get("api/me");
    try {
        const response = await api.post(`api/bo/opportunity/mail/${id}/sdr`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

// export const boSendInvoiceProposal = async (id:number, formData: FormData): Promise<YousignResponse> => {
//     // return await api.get("api/me");
//     try {
//         const response = await apiFormdata.post(`api/bo/opportunity/yousign/${id}/send-invoice-proposal`, formData);
//         return response.data;
//     } catch (error) {
//         console.error(error);
//         throw new Error('Failed to log in');
//     }
// };

export const boSendInvoiceProposal = async (id: number | undefined, form: FormData): Promise<YousignResponse> => {
    try {
        const response = await apiFormdata.post(`api/bo/opportunity/yousign/${id}/send-invoice-proposal`, form);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

//// FROM NEW BUSINESS MODEL
export const boOpportunitiesTableHeader = async (housingType: string, ongoingArchived: string, status: string): Promise<B2CTableHeader[]> => {
    // return await api.get("api/me");
    try {
        const response = await api.get(`api/bo/opportunity/table/${housingType}/${ongoingArchived}/header/${status}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const boOpportunitiesHousingTypeV2 = async (housingType: string, ongoingArchived: string): Promise<B2C[]> => {
    // return await api.get("api/me");
    try {
        const response = await api.get(`api/bo/opportunity/table/${housingType}/${ongoingArchived}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};
