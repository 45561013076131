import { RouteType } from '@/models/common/route.models';
import { loginPaths } from '@/routes/routesPathsIrve';
import { LoginRoute } from './login.routes';

const LoginRoutes: RouteType[] = [
    {
        state: 'login',
        index: null,
        path: loginPaths.index,
        child: null,
        sidebarProps: null,
        label: null,
        element: <LoginRoute />,
        show: false
    }
];

export default LoginRoutes;
