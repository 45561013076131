import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Station, StationStore, StationInstaller, StationInstallerProfile } from '../../../models/irve/station.models';

type UpdateStationAction = PayloadAction<Station>;
type UpdateStationsAction = PayloadAction<Station[]>;
type UpdateStationsListInstallerAction = PayloadAction<StationInstallerProfile[]>;

type UpdateStationInstallersAction = PayloadAction<StationInstaller[]>;

const initialState: StationStore = {
    station: null,
    stations: null,
    stationInstallers: null,
    stationsListInstaller: null
};

const stationSlice = createSlice({
    name: 'stationSlice',
    initialState,
    reducers: {
        setStateStationIrve: (state, action: UpdateStationAction) => {
            state.station = action.payload;
        },
        setStateStationsIrve: (state, action: UpdateStationsAction) => {
            state.stations = action.payload;
        },

        setStateStationsListInstaller: (state, action: UpdateStationsListInstallerAction) => {
            state.stationsListInstaller = action.payload;
        },

        setStateStationsListIrve: (state, action: UpdateStationsAction) => {
            state.stations = action.payload;
        },
        setStateStationInstallersIrve: (state, action: UpdateStationInstallersAction) => {
            state.stationInstallers = action.payload;
        },
        clearStateStationIrve: (state) => {
            state.station = null;
            state.stations = null;
            state.stationInstallers = null;
        }
    }
});

export const { setStateStationsIrve, setStateStationInstallersIrve, setStateStationsListInstaller, setStateStationIrve, clearStateStationIrve } = stationSlice.actions;

export default stationSlice.reducer;
