import { api } from './config';
import {
    Department,
    Region,
    regionStore
} from '../../models/common/region.models';
export const getAllRegions = async (): Promise<Region[]> => {
    try {
        const response = await api.get('api/region');
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('API Request failed');
    }
};

export const getMeRegions = async (): Promise<regionStore[]> => {
    try {
        const response = await api.get('api/installer/me/region');
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('API Request failed');
    }
};

export const getAllRegionsWDeparmtents = async (): Promise<regionStore[]> => {
    try {
        const response = await api.get('api/region/regions-wdepartments');
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('API Request failed');
    }
};

export const getDepartmentsFromSelectedRegions = async (
    regions: Region[]
): Promise<Department[]> => {
    try {
        const response = await api.post(`api/region/departments`, regions);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const getInstallerRegions = async (id: number): Promise<regionStore[]> => {
    try {
        const response = await api.get(`api/bo/installer/region/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('API Request failed');
    }
};
