import { NetworkBackgroundPicture, NetworkBackgroundPicturePv } from '@/assets/images';
import { store } from '@/store';
import { selectRoutesIrvePV } from '@/store/selector';
import { Box, styled } from '@mui/material';
import { useSelector } from 'react-redux';

const BackgroundLayoutComparator = () => {
    const { route } = useSelector(selectRoutesIrvePV);
    return <StyledBackground className={route === 'irve' ? 'irve' : route === 'pv' ? 'pv' : ''}></StyledBackground>;
};

const StyledBackground = styled(Box)`
    &.irve {
        background: url(${NetworkBackgroundPicture}) no-repeat;
        background-position: 0;
        background-size: cover;
    }

    &.pv {
        background: url(${NetworkBackgroundPicturePv}) no-repeat;
        background-position: 0;
        background-size: cover;
    }

    background-size: cover;
    position: fixed;
    width: 100%;
    height: 100%;
    bottom: 0;
    z-index: 1;
    left: 0;
`;

export default BackgroundLayoutComparator;
