import { Outlet, useNavigate } from 'react-router-dom';
import { Box, Button, Toolbar, useTheme } from '@mui/material';
import Sidebar from '@/components/common/Sidebar';
import Topbar from '@/components/common/Topbar';
import accountCustomerB2CRoutes from '@/pages/routes/accountCustomer/irve';
import { useSelector } from 'react-redux';
import { selectRoutesIrvePV, selectUser } from '@/store/selector';
import { setStateRouteIrvePv } from '@/store/reducers/routesIrvePv.slice';
import { useDispatch } from 'react-redux';
import ButtonPrimary from '@/components/buttons/ButtonPrimary';
import TypographyCustom from '@/components/common/Typography';
import accountCustomerRoutesPv from '@/pages/routes/accountCustomer/pv';
// import {DashboardRoutes, CustomerB2CRoutes } from "@/routes/appRoutes";

const CustomerLayout = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { route } = useSelector(selectRoutesIrvePV);
    const { user } = useSelector(selectUser);

    const handleClick = () => {
        if (route === 'pv') {
            dispatch(setStateRouteIrvePv('irve'));

            if (user?.roles.includes('ROLE_CUSTOMER')) {
                navigate(`/irve/account-customer-b2c/project`);
            } else if (user?.roles.includes('ROLE_ADMIN')) {
                navigate(`/irve/dashboard/overview`);
            } else if (user?.roles.includes('ROLE_INSTALLER')) {
                navigate(`/irve/account-installer/overview`);
            }
        } else {
            dispatch(setStateRouteIrvePv('pv'));

            if (user?.roles.includes('ROLE_CUSTOMER')) {
                navigate(`/pv/account-customer-b2c/project`);
            } else if (user?.roles.includes('ROLE_ADMIN')) {
                navigate(`/pv/dashboard/overview`);
            } else if (user?.roles.includes('ROLE_INSTALLER')) {
                navigate(`/pv/account-installer/overview`);
            }
        }
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <Topbar />
            <Box
                component="nav"
                sx={{
                    width: theme.sidebar.width,
                    flexShrink: 0
                }}
            >
                <Sidebar
                    routesData={
                        route === 'irve'
                            ? accountCustomerB2CRoutes
                            : accountCustomerRoutesPv
                    }
                />
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    width: `calc(100% - ${theme.sidebar.width})`,
                    minHeight: '100vh',
                    backgroundColor: theme.general.reactFrameworkColor
                }}
            >
                <Toolbar />

                <Outlet />
            </Box>
        </Box>
    );
};

export default CustomerLayout;
