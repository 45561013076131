import { lazy } from 'react';
import Loader from '@/components/common/Loader';

export const MeProfileRoute = Loader(lazy(() => import('@/pages/accountInstaller/Me/pv/Profile')));

export const MeProfileLegalInformationsRoute = Loader(lazy(() => import('@/pages/accountInstaller/Me/pv/LegalInformations')));

export const MeProfileInstallationsRoute = Loader(lazy(() => import('@/pages/accountInstaller/Me/pv/Installations')));

export const MeProfileOfferSimulationRoute = Loader(lazy(() => import('@/pages/accountInstaller/Me/pv/OfferSimulation')));
