import {
    installationsPvData,
    InstallerStore
} from '@/models/pv/installer.models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: InstallerStore = {
    installations: null
};

export const { actions, reducer } = createSlice({
    name: 'installationPv',
    initialState,
    reducers: {
        setStateInstallationPv: (
            state,
            action: PayloadAction<installationsPvData>
        ) => {
            state.installations = action.payload;
        },
        clearStateInstallerPv: (state) => {
            state.installations = null;
        }
    }
});

export const { setStateInstallationPv, clearStateInstallerPv } = actions;

export default reducer;
