import { lazy } from 'react';
import Loader from '@/components/common/Loader';

export const AccessoriesRoute = Loader(
    lazy(() => import('@/pages/accountInstaller/Me/irve/Accessories'))
);

export const AccessoriesAddRoute = Loader(
    lazy(() => import('@/pages/accountInstaller/Me/irve/AccessoriesAdd'))
);
