import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QnA, Form2ApartmentStore } from '@/models/irve/form.model';

type updateQnAAction = PayloadAction<QnA>;

const initialState: Form2ApartmentStore = {
    qna1: null,
    qna2: null,
    qna3: null,
    qna4: null,
    qna5: null,
    qna6: null,
    qna7: null
};

const form2ApartmentSlice = createSlice({
    name: 'form2Apartment',
    initialState,
    reducers: {
        setStateForm2ApartmentQnA1Irve: (state, action: updateQnAAction) => {
            state.qna1 = action.payload;
        },
        setStateForm2ApartmentQnA2Irve: (state, action: updateQnAAction) => {
            state.qna2 = action.payload;
        },
        setStateForm2ApartmentQnA3Irve: (state, action: updateQnAAction) => {
            state.qna3 = action.payload;
        },
        setStateForm2ApartmentQnA4Irve: (state, action: updateQnAAction) => {
            state.qna4 = action.payload;
        },
        setStateForm2ApartmentQnA5Irve: (state, action: updateQnAAction) => {
            state.qna5 = action.payload;
        },
        setStateForm2ApartmentQnA6Irve: (state, action: updateQnAAction) => {
            state.qna6 = action.payload;
        },
        setStateForm2ApartmentQnA7Irve: (state, action: updateQnAAction) => {
            state.qna7 = action.payload;
        },

        clearStateForm2ApartmentIrve: (state) => {
            state.qna1 = null;
            state.qna2 = null;
            state.qna3 = null;
            state.qna4 = null;
            state.qna5 = null;
            state.qna6 = null;
            state.qna7 = null;
        }
    }
});

export const {
    setStateForm2ApartmentQnA1Irve,
    setStateForm2ApartmentQnA2Irve,
    setStateForm2ApartmentQnA3Irve,
    setStateForm2ApartmentQnA4Irve,
    setStateForm2ApartmentQnA5Irve,
    setStateForm2ApartmentQnA6Irve,
    setStateForm2ApartmentQnA7Irve,
    clearStateForm2ApartmentIrve
} = form2ApartmentSlice.actions;

export default form2ApartmentSlice.reducer;
