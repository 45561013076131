import { lazy } from 'react';
import Loader from '@/components/common/Loader';

export const ResultLoaderRoute = Loader(
    lazy(() => import('@/pages/comparator/irve/ResultLoader'))
);

export const ResultRecapStationsRoute = Loader(
    lazy(() => import('@/pages/comparator/irve/ResultRecapStations'))
);

export const ResultRecapInstallersRoute = Loader(
    lazy(() => import('@/pages/comparator/irve/ResultRecapInstallers'))
);

export const ResultRecapAllRoute = Loader(
    lazy(() => import('@/pages/comparator/irve/ResultRecapAll'))
);

export const ResultAccountAlreadyExistsRoute = Loader(
    lazy(() => import('@/pages/comparator/irve/ResultAccountAlreadyExists'))
);

export const ResultStationsNotFoundRoute = Loader(
    lazy(() => import('@/pages/comparator/irve/ResultStationsNotFound'))
);

export const ResultLoaderRedirectRoute = Loader(
    lazy(() => import('@/pages/comparator/irve/ResultRedirectDashboard'))
);
