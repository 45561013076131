import { lazy } from 'react';
import Loader from '@/components/common/Loader';

export const AccessoriesRoute = Loader(
    lazy(() => import('@/pages/dashboard/Accessories/irve/Accessories'))
);

export const AccessoryInstallerProfileRoute = Loader(
    lazy(
        () =>
            import(
                '@/pages/dashboard/Accessories/irve//Profile/AccessoryInstallerProfile'
            )
    )
);

export const AccessoryInstallerAddRoute = Loader(
    lazy(
        () =>
            import(
                '@/pages/dashboard/Accessories/irve//Add/AccessoryInstallerAdd'
            )
    )
);

export const AccessoryStationMonophasedProfileRoute = Loader(
    lazy(
        () =>
            import(
                '@/pages/dashboard/Accessories/irve//Profile/AccessoryStationMonophasedProfile'
            )
    )
);

export const AccessoryStationMonophasedAddRoute = Loader(
    lazy(
        () =>
            import(
                '@/pages/dashboard/Accessories/irve//Add/AccessoryStationMonophasedAdd'
            )
    )
);

export const AccessoryStationTriphasedProfileRoute = Loader(
    lazy(
        () =>
            import(
                '@/pages/dashboard/Accessories/irve/Profile/AccessoryStationTriphasedProfile'
            )
    )
);

export const AccessoryStationTriphasedAddRoute = Loader(
    lazy(
        () =>
            import(
                '@/pages/dashboard/Accessories/irve//Add/AccessoryStationTriphasedAdd'
            )
    )
);
