import React, { ChangeEvent, useRef, useState } from 'react';
import {
    Badge,
    Box,
    Button,
    Divider,
    Hidden,
    IconButton,
    lighten,
    List,
    ListItem,
    ListItemText,
    Modal,
    Popover,
    Typography,
    useTheme
} from '@mui/material';
import { styled } from '@mui/material';

import KeyTwoToneIcon from '@mui/icons-material/KeyTwoTone';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, store } from '@/store';
import { clearStateAuth } from '@/store/reducers/auth.slice';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';

import { UserChangePassword } from '@/models/common/user.models';
import { loginServices } from '@/services/irve';
import { clearStateAccessoriesIrve } from '@/store/reducers/irve/accessory.slice';
import { loginPaths } from '@/routes/routesPathsIrve';
import { useNavigate } from 'react-router-dom';
import { selectUser } from '@/store/selector';
import {
    ExpandMoreTwoTone,
    LockOpenTwoTone,
    Visibility,
    VisibilityOff
} from '@mui/icons-material';
import { persistStore } from 'redux-persist';
import Cookies from 'js-cookie';
import { clearStateAppIrve } from '@/store/reducers/irve/appSlice';
import { clearStateForm2ApartmentIrve } from '@/store/reducers/irve/form2Apartment.slice';
import { clearStateForm1Irve } from '@/store/reducers/irve/form1.slice';
import { clearStateForm2HouseIrve } from '@/store/reducers/irve/form2House.slice';
import { clearStateForm3Irve } from '@/store/reducers/irve/form3.slice';
import { clearStateForm4Irve } from '@/store/reducers/irve/form4.slice';
import { clearStateForm5Irve } from '@/store/reducers/irve/form5.slice';
import { clearStateForm6Irve } from '@/store/reducers/irve/form6.slice';
import { clearStateFormElecConsumIrve } from '@/store/reducers/irve/formElecConsum.slice';
import { clearStateUsersIrve } from '@/store/reducers/irve/user.slice';
import { clearStateNotificationIrve } from '@/store/reducers/irve/notification.slice';
import { clearStateResultComparatiorIrve } from '@/store/reducers/irve/resultComparator.slice';
import { clearStateOpportunityIrve } from '@/store/reducers/irve/opportunity.slice';
import { clearStateStationIrve } from '@/store/reducers/irve/station.slice';
import { clearStateStudyIrve } from '@/store/reducers/irve/study.slice';
import { clearStateVehicleIrve } from '@/store/reducers/irve/vehicle.slice';

const UserBoxButton = styled(Button)(
    ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
    ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
    ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
    ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
    ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

const modalStylePassword = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '20px',
    boxShadow: 24,
    p: 4
};

function TopBarUserBox() {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { user } = useSelector((state: RootState) => selectUser(state));

    //
    // const user = {
    //   name: 'Catherine Pike',
    //   avatar: '/static/images/avatars/1.jpg',
    //   jobtitle: 'Project Manager'
    // };

    const ref = useRef<any>(null);
    const [isOpen, setOpen] = useState<boolean>(false);
    const [openPassword, setOpenPassword] = useState(false);
    const [password, setPassword] = useState<UserChangePassword>({
        oldPassword: '',
        newPassword1: '',
        newPassword2: ''
    });

    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [openWrongSecondPassword, setOpenWrongSecondPassword] =
        useState(false);

    const handleOpenWrongSecondPassword = () =>
        setOpenWrongSecondPassword(true);
    const handleCloseWrongSecondPassword = () =>
        setOpenWrongSecondPassword(false);

    const handleOpenConfirmation = () => setOpenConfirmation(true);
    const handleCloseConfirmation = () => {
        setOpenConfirmation(false);
        setOpen(false);
        setOpenPassword(false);
    };

    const handleChangePasswordValues = (e: ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;
        setPassword({
            ...password,
            [name]: value
        });
    };
    const handleOpenPassword = () => setOpenPassword(true);
    const handleClosePassword = () => setOpenPassword(false);

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    const handleLogout = (): void => {
        dispatch(clearStateAppIrve());
        dispatch(clearStateAccessoriesIrve());
        dispatch(clearStateAuth());
        dispatch(clearStateForm2ApartmentIrve());
        dispatch(clearStateForm1Irve());

        dispatch(clearStateForm2HouseIrve());
        dispatch(clearStateForm3Irve());
        dispatch(clearStateForm4Irve());
        dispatch(clearStateForm5Irve());
        dispatch(clearStateForm6Irve());
        dispatch(clearStateFormElecConsumIrve());
        dispatch(clearStateUsersIrve());

        dispatch(clearStateNotificationIrve());
        dispatch(clearStateResultComparatiorIrve());
        dispatch(clearStateOpportunityIrve());
        dispatch(clearStateStationIrve());
        dispatch(clearStateStudyIrve());
        dispatch(clearStateVehicleIrve());

        navigate(loginPaths.index);
    };

    const handleChangePassword = async () => {
        const response = await loginServices.meChangePassword(
            password.oldPassword,
            password.newPassword1,
            password.newPassword2
        );
        if (response.id) {
            handleOpenConfirmation();
            setPassword({
                oldPassword: '',
                newPassword2: '',
                newPassword1: ''
            });
        } else {
            handleOpenWrongSecondPassword();
            setPassword({
                oldPassword: '',
                newPassword2: '',
                newPassword1: ''
            });
        }
    };

    return (
        <>
            {/*<Badge badgeContent={4} color="primary">*/}
            {/*  <NotificationsNoneTwoToneIcon color="action" />*/}
            {/*</Badge>*/}

            <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
                <Hidden mdDown>
                    <UserBoxText>
                        <UserBoxLabel variant="body1">
                            Bonjour {user?.firstname} {user?.lastname}
                        </UserBoxLabel>
                    </UserBoxText>
                </Hidden>
                <Hidden smDown>
                    <ExpandMoreTwoTone sx={{ ml: 1 }} />
                </Hidden>
            </UserBoxButton>
            <Popover
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <MenuUserBox sx={{ minWidth: 210 }} display="flex">
                    {/*<Avatar sx={{ bgcolor: theme.colors.primary.main, color: theme.colors.alpha.white[100] }}>N</Avatar>*/}
                    <UserBoxText>
                        <UserBoxLabel variant="body1">
                            {user?.firstname} {user?.lastname}
                        </UserBoxLabel>
                        {/*<UserBoxDescription variant="body2">*/}
                        {/*  {user.jobtitle}*/}
                        {/*</UserBoxDescription>*/}
                    </UserBoxText>
                </MenuUserBox>
                <Divider sx={{ mb: 0 }} />
                <Box sx={{ m: 1 }}>
                    <Button
                        color="primary"
                        fullWidth
                        onClick={handleOpenPassword}
                    >
                        <KeyTwoToneIcon sx={{ mr: 1 }} />
                        Changer mon mot de passe
                    </Button>
                </Box>
                <Box sx={{ m: 1 }}>
                    <Button color="primary" fullWidth onClick={handleLogout}>
                        <LockOpenTwoTone sx={{ mr: 1 }} />
                        Me déconnecter
                    </Button>
                </Box>

                <Modal
                    open={openPassword}
                    onClose={handleClosePassword}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={modalStylePassword}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <Typography variant={'h4'}>
                                Changer mon mot de passe
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                marginTop: '20px',
                                marginLeft: '15px'
                            }}
                        >
                            <Typography variant={'body1'}>
                                Mot de passe actuel
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center'
                            }}
                        >
                            <FormControl
                                id={'currentPassword'}
                                sx={{ m: 1, width: '100%' }}
                                variant="outlined"
                                size={'small'}
                            >
                                <InputLabel htmlFor="outlined-adornment-password">
                                    Actuel
                                </InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={
                                                    handleClickShowPassword
                                                }
                                                onMouseDown={
                                                    handleMouseDownPassword
                                                }
                                                edge="end"
                                            >
                                                {showPassword ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                    value={password.oldPassword}
                                    name={'oldPassword'}
                                    onChange={handleChangePasswordValues}
                                />
                            </FormControl>
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                marginTop: '20px',
                                marginLeft: '15px'
                            }}
                        >
                            <Typography variant={'body1'}>
                                Nouveau mot de passe
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center'
                            }}
                        >
                            <FormControl
                                id={'newPassword1'}
                                sx={{ m: 1, width: '100%' }}
                                variant="outlined"
                                size={'small'}
                            >
                                <InputLabel htmlFor="outlined-adornment-password">
                                    Nouveau
                                </InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password-new1"
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={
                                                    handleClickShowPassword
                                                }
                                                onMouseDown={
                                                    handleMouseDownPassword
                                                }
                                                edge="end"
                                            >
                                                {showPassword ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                    value={password.newPassword1}
                                    name={'newPassword1'}
                                    onChange={handleChangePasswordValues}
                                />
                            </FormControl>
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                marginTop: '20px',
                                marginLeft: '15px'
                            }}
                        >
                            <Typography variant={'body1'}>
                                Confirmer nouveau mot de passe
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center'
                            }}
                        >
                            <FormControl
                                id={'newPassword2'}
                                sx={{ m: 1, width: '100%' }}
                                variant="outlined"
                                size={'small'}
                            >
                                <InputLabel htmlFor="outlined-adornment-password">
                                    Confirmer
                                </InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password-new2"
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={
                                                    handleClickShowPassword
                                                }
                                                onMouseDown={
                                                    handleMouseDownPassword
                                                }
                                                edge="end"
                                            >
                                                {showPassword ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                    value={password.newPassword2}
                                    name={'newPassword2'}
                                    onChange={handleChangePasswordValues}
                                />
                            </FormControl>
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                marginTop: '20px',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <Button
                                variant="contained"
                                onClick={handleChangePassword}
                            >
                                Enregistrer
                            </Button>
                            <Button
                                sx={{ marginLeft: '20px' }}
                                variant="contained"
                                onClick={handleClosePassword}
                            >
                                Annuler
                            </Button>
                        </Box>
                    </Box>
                </Modal>

                <Modal
                    open={openConfirmation}
                    onClose={handleCloseConfirmation}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={theme.modalStyle}>
                        <Box>
                            <Typography variant={'body1'}>
                                Votre mot de passe a bien été changé
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', marginTop: '20px' }}>
                            <Button
                                sx={{ marginLeft: '20px' }}
                                variant="contained"
                                onClick={handleCloseConfirmation}
                            >
                                OK
                            </Button>
                        </Box>
                    </Box>
                </Modal>

                <Modal
                    open={openWrongSecondPassword}
                    onClose={handleCloseWrongSecondPassword}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={theme.modalStyle}>
                        <Box>
                            <Typography variant={'body1'}>
                                Vos mots de passe ne correspondent pas
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', marginTop: '20px' }}>
                            <Button
                                sx={{ marginLeft: '20px' }}
                                variant="contained"
                                onClick={handleCloseWrongSecondPassword}
                            >
                                OK
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            </Popover>
        </>
    );
}

export default TopBarUserBox;
