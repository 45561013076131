import { RouteType } from '@/models/common/route.models';

import { AdminPanelSettingsTwoTone, ApartmentTwoTone, BusinessTwoTone, DashboardCustomizeTwoTone, EvStationTwoTone, HandymanTwoTone, HomeTwoTone, ManageAccountsTwoTone } from '@mui/icons-material';

import {
    boAccessoriesPath,
    boAdminsPath,
    boManagersPath,
    boInstallersPath,
    boB2BPath,
    boB2CApartmentPath,
    boB2CHousePath,
    boOverviewsPath,
    boPmsPath,
    boStationsPath,
    boB2CInvoicePath
} from '@/routes/routesPathsIrve';

import {
    AccessoriesRoute,
    AccessoryInstallerAddRoute,
    AccessoryInstallerProfileRoute,
    AccessoryStationMonophasedAddRoute,
    AccessoryStationMonophasedProfileRoute,
    AccessoryStationTriphasedAddRoute,
    AccessoryStationTriphasedProfileRoute
} from './Accessories.routes';

import { StationAddRoute, StationProfileRoute, StationsRoute } from './Stations.routes';

import { OverviewsRoute } from './Overview.routes';

import { AdminAddRoute, AdminsProfileRoute, AdminsRoute } from './Admin.routes';

import {
    ApartmentsArchivedRoute,
    ApartmentsOngoingProfileChangeInstallersRoute,
    ApartmentsOngoingProfileChangeStationsRoute,
    ApartmentsOngoingProfileRoute,
    ApartmentsOngoingRoute
} from './Apartments.routes';

import { HousesArchivedRoute, HousesOngoingProfileChangeInstallersRoute, HousesOngoingProfileChangeStationsRoute, HousesOngoingProfileRoute, HousesOngoingRoute } from './Houses.routes';

import PageLayout from '@/layouts/PageLayout';

import { B2CInvoicePreviewRoute, B2CMessengerRoute } from './B2C.routes';

import { B2BAddRoute, B2BArchivedRoute, B2BInstallersUpdateRoute, B2BMessengerRoute, B2BOnGoingRoute, B2BProfileRoute } from './B2B.routes';

import { ManagerAddRoute, ManagersProfileRoute, ManagersRoute } from './Manager.routes';

import { ProjectManagerAddRoute, ProjectManagersProfileRoute, ProjectManagersRoute } from './ProjectManagers.routes';

import {
    InstallerAddAccessoriesRoute,
    InstallerAddDepartmentsProfileRoute,
    InstallerAddDepartmentsRoute,
    InstallerAddInstallationsRoute,
    InstallerAddLegalInformationsRoute,
    InstallerAddRoute,
    InstallerAddStationsRoute,
    InstallerProfileAddStationRoute,
    InstallerProfileRoute,
    InstallersRoute
} from './Installer.routes';

export const DashboardRoutesIrve: RouteType[] = [
    {
        path: boOverviewsPath.index,
        element: <OverviewsRoute />,
        state: 'dashboard.overview',
        sidebarProps: {
            displayText: 'Accueil',
            icon: <DashboardCustomizeTwoTone />
        },
        show: true,
        index: false,
        child: null,
        label: null
    },

    {
        path: undefined,
        element: null,
        state: 'undefined',
        sidebarProps: null,
        show: true,
        index: null,
        child: null,
        label: 'Opportunités'
    },

    {
        path: boB2CHousePath.index,
        element: <PageLayout />,
        state: 'dashboard.boOpportunitiesB2CHousePath.index',
        sidebarProps: {
            displayText: 'Maisons',
            icon: <HomeTwoTone />
        },
        show: true,
        label: null,
        child: [
            {
                path: boB2CHousePath.onGoing,
                element: <HousesOngoingRoute />,
                state: 'dashboard.boOpportunitiesB2CHousePath.onGoing',
                sidebarProps: {
                    displayText: 'En cours',
                    icon: null
                },
                show: true,
                label: null,
                child: null,
                index: null
            },
            {
                path: boB2CHousePath.archived,
                element: <HousesArchivedRoute />,
                state: 'dashboard.boOpportunitiesB2CHousePath.archived',
                sidebarProps: {
                    displayText: 'Archivées',
                    icon: null
                },
                show: true,
                label: null,
                child: null,
                index: null
            }
        ],
        index: null
    },
    {
        path: boB2CHousePath.onGoingProfile,
        element: <HousesOngoingProfileRoute />,
        state: 'boOpportunitiesB2CHousePath.onGoingProfile',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boB2CHousePath.changeStation,
        element: <HousesOngoingProfileChangeStationsRoute />,
        state: 'boOpportunitiesB2CHousePath.onGoingProfileChangeStation',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boB2CHousePath.changeInstaller,
        element: <HousesOngoingProfileChangeInstallersRoute />,
        state: 'boOpportunitiesB2CHousePath.onGoingProfileChangeInstaller',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boB2CHousePath.messenger,
        element: <B2CMessengerRoute />,
        state: 'boOpportunitiesB2CHousePath.Messenger',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },

    {
        path: boB2CApartmentPath.index,
        element: <PageLayout />,
        state: 'dashboard.boOpportunitiesB2CApartmentPath.index',
        sidebarProps: {
            displayText: 'Appartements',
            icon: <ApartmentTwoTone />
        },
        show: true,
        label: null,
        child: [
            {
                path: boB2CApartmentPath.onGoing,
                element: <ApartmentsOngoingRoute />,
                state: 'dashboard.boOpportunitiesB2CApartmentPath.onGoing',
                sidebarProps: {
                    displayText: 'En cours',
                    icon: null
                },
                show: true,
                label: null,
                child: null,
                index: null
            },
            {
                path: boB2CApartmentPath.archived,
                element: <ApartmentsArchivedRoute />,
                state: 'dashboard.boOpportunitiesB2CApartmentPath.archived',
                sidebarProps: {
                    displayText: 'Archivées',
                    icon: null
                },
                show: true,
                label: null,
                child: null,
                index: null
            }
        ],
        index: null
    },

    {
        path: boB2CApartmentPath.onGoingProfile,
        element: <ApartmentsOngoingProfileRoute />,
        state: 'boOpportunitiesB2CApartmentPath.onGoingProfile',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boB2CApartmentPath.changeStation,
        element: <ApartmentsOngoingProfileChangeStationsRoute />,
        state: 'boOpportunitiesB2CApartmentPath.onGoingProfileChangeStation',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boB2CApartmentPath.changeInstaller,
        element: <ApartmentsOngoingProfileChangeInstallersRoute />,
        state: 'boOpportunitiesB2CApartmentPath.onGoingProfileChangeInstaller',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },

    {
        path: boB2CApartmentPath.messenger,
        element: <B2CMessengerRoute />,
        state: 'boOpportunitiesB2CApartmentPath.Messenger',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boB2CInvoicePath.preview,
        element: <B2CInvoicePreviewRoute />,
        state: 'boOpportunitiesB2CInvoicePath.preview',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },

    {
        path: boB2BPath.index,
        element: <PageLayout />,
        state: 'dashboard.boOpportunitiesB2BPath.index',
        sidebarProps: {
            displayText: 'Etudes',
            icon: <BusinessTwoTone />
        },
        show: true,
        label: null,
        child: [
            {
                path: boB2BPath.onGoing,
                element: <B2BOnGoingRoute />,
                state: 'dashboard.boOpportunitiesB2BPath.onGoing',
                sidebarProps: {
                    displayText: 'En cours',
                    icon: null
                },
                show: true,
                label: null,
                child: null,
                index: null
            },
            {
                path: boB2BPath.archived,
                element: <B2BArchivedRoute />,
                state: 'dashboard.boOpportunitiesB2BPath.archived',
                sidebarProps: {
                    displayText: 'Archivées',
                    icon: null
                },
                show: true,
                label: null,
                child: null,
                index: null
            }
        ],
        index: null
    },
    {
        path: boB2BPath.studyAdd,
        element: <B2BAddRoute />,
        state: 'boOpportunitiesB2BPath.studyAdd',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boB2BPath.onGoingProfile,
        element: <B2BProfileRoute />,
        state: 'boOpportunitiesB2BPath.onGoingProfile',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boB2BPath.messenger,
        element: <B2BMessengerRoute />,
        state: 'boOpportunitiesB2BPath.messenger',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boB2BPath.installersUpdate,
        element: <B2BInstallersUpdateRoute />,
        state: 'boOpportunitiesB2BPath.installersUpdate',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },

    {
        path: undefined,
        element: null,
        state: 'undefined',
        sidebarProps: null,
        show: true,
        index: null,
        child: null,
        label: 'Utilisateurs'
    },

    {
        path: boAdminsPath.admins,
        element: <AdminsRoute />,
        state: 'dashboard.boAdminsPath.admins',
        sidebarProps: {
            displayText: 'Admins',
            icon: <AdminPanelSettingsTwoTone />
        },
        show: true,
        index: false,
        child: null,
        label: null
    },
    {
        path: boAdminsPath.adminsProfile,
        element: <AdminsProfileRoute />,
        state: 'dashboard.boAdminsPath.adminsProfile',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boAdminsPath.adminAdd,
        element: <AdminAddRoute />,
        state: 'dashboard.boAdminsPath.adminAdd',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },

    {
        path: boManagersPath.managers,
        element: <ManagersRoute />,
        state: 'dashboard.boManagersPath.managers',
        sidebarProps: {
            displayText: 'Managers',
            icon: <AdminPanelSettingsTwoTone />
        },
        show: true,
        index: false,
        child: null,
        label: null
    },
    {
        path: boManagersPath.managersProfile,
        element: <ManagersProfileRoute />,
        state: 'dashboard.boManagersPath.managersProfile',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boManagersPath.managerAdd,
        element: <ManagerAddRoute />,
        state: 'dashboard.boManagersPath.managerAdd',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },

    {
        path: boPmsPath.pms,
        element: <ProjectManagersRoute />,
        state: 'dashboard.boAdminsPath.pms',
        sidebarProps: {
            displayText: 'Project Managers',
            icon: <ManageAccountsTwoTone />
        },
        show: true,
        index: false,
        child: null,
        label: null
    },
    {
        path: boPmsPath.pmsProfile,
        element: <ProjectManagersProfileRoute />,
        state: 'dashboard.boPmsPath.pmsProfile',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boPmsPath.pmAdd,
        element: <ProjectManagerAddRoute />,
        state: 'dashboard.boPmsPath.pmAdd',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },

    {
        path: boInstallersPath.installers,
        element: <InstallersRoute />,
        state: 'dashboard.boInstallersPath.installers',
        sidebarProps: {
            displayText: 'Installateurs',
            icon: <ManageAccountsTwoTone />
        },
        show: true,
        index: false,
        child: null,
        label: null
    },
    {
        path: boInstallersPath.installerAdd,
        element: <InstallerAddRoute />,
        state: 'dashboard.boInstallersPath.installerAdd',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boInstallersPath.installerAddLegalInformations,
        element: <InstallerAddLegalInformationsRoute />,
        state: 'dashboard.boInstallersPath.installerAddLegalInformations',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boInstallersPath.installerAddLSelectedStations,
        element: <InstallerAddStationsRoute />,
        state: 'dashboard.boInstallersPath.installerAddLSelectedStations',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boInstallersPath.installerAddInstallations,
        element: <InstallerAddInstallationsRoute />,
        state: 'dashboard.boInstallersPath.installerAddInstallations',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boInstallersPath.installerAddAccessories,
        element: <InstallerAddAccessoriesRoute />,
        state: 'dashboard.boInstallersPath.installerAddAccessories',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boInstallersPath.installerAddDepartments,
        element: <InstallerAddDepartmentsRoute />,
        state: 'dashboard.boInstallersPath.installerAddDepartments',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boInstallersPath.installerRegionsAdd,
        element: <InstallerAddDepartmentsRoute />,
        state: 'dashboard.boInstallersPathsPv.installerRegionsAdd',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boInstallersPath.installersProfile,
        element: <InstallerProfileRoute />,
        state: 'dashboard.boInstallersPath.installersProfile',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boInstallersPath.installersProfileStationsAdd,
        element: <InstallerProfileAddStationRoute />,
        state: 'dashboard.boInstallersPath.installersProfileStationsAdd',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boInstallersPath.installerAddDepartmentsProfile,
        element: <InstallerAddDepartmentsProfileRoute />,
        state: 'dashboard.boInstallersPath.InstallerAddDepartmentsProfile',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: undefined,
        element: null,
        state: 'undefined',
        sidebarProps: null,
        show: true,
        index: null,
        child: null,
        label: 'Données'
    },
    {
        path: boAccessoriesPath.accessories,
        element: <AccessoriesRoute />,
        state: 'dashboard.boAccessoriesPath.accessories',
        sidebarProps: {
            displayText: 'Accessoires',
            icon: <HandymanTwoTone />
        },
        show: true,
        index: false,
        child: null,
        label: null
    },
    {
        path: boAccessoriesPath.accessoriesInstallerProfile,
        element: <AccessoryInstallerProfileRoute />,
        state: 'dashboard.boAccessoriesPath.accessoriesInstallerProfile',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boAccessoriesPath.accessoriesInstallerAdd,
        element: <AccessoryInstallerAddRoute />,
        state: 'dashboard.boAccessoriesPath.accessoriesInstallerAdd',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boAccessoriesPath.accessoriesStationMonophasedProfile,
        element: <AccessoryStationMonophasedProfileRoute />,
        state: 'dashboard.boAccessoriesPath.accessoriesStationMonophasedProfile',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boAccessoriesPath.accessoriesStationMonophasedAdd,
        element: <AccessoryStationMonophasedAddRoute />,
        state: 'dashboard.boAccessoriesPath.accessoriesStationMonophasedAdd',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boAccessoriesPath.accessoriesStationTriphasedProfile,
        element: <AccessoryStationTriphasedProfileRoute />,
        state: 'dashboard.boAccessoriesPath.accessoriesStationTriphasedProfile',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boAccessoriesPath.accessoriesStationTriphasedAdd,
        element: <AccessoryStationTriphasedAddRoute />,
        state: 'dashboard.boAccessoriesPath.accessoriesStationTriphasedAdd',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boStationsPath.stations,
        element: <StationsRoute />,
        state: 'dashboard.boStationsPath.stations',
        sidebarProps: {
            displayText: 'Bornes',
            icon: <EvStationTwoTone />
        },
        show: true,
        index: false,
        child: null,
        label: null
    },
    {
        path: boStationsPath.stationsProfile,
        element: <StationProfileRoute />,
        state: 'dashboard.boStationsPath.stationsProfile',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: boStationsPath.stationAdd,
        element: <StationAddRoute />,
        state: 'dashboard.boStationsPath.stationsProfile',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    }
];
