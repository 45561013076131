import { lazy } from 'react';
import Loader from '@/components/common/Loader';

export const FormHousingRoute = Loader(
    lazy(() => import('@/pages/comparator/irve/FormHousing'))
);

export const FormVehiclesRoute = Loader(
    lazy(() => import('@/pages/comparator/irve/FormVehicles'))
);

export const FormMyProjectRoute = Loader(
    lazy(() => import('@/pages/comparator/irve/FormMyProject'))
);

export const FormUserInformationRoute = Loader(
    lazy(() => import('@/pages/comparator/irve/FormUserInformation'))
);

export const FormRecapRoute = Loader(
    lazy(() => import('@/pages/comparator/irve/RecapForm'))
);
