import { api } from './config';
import { Vehicle } from '../../models/common/vehicle.models';

export const getVehiclesList = async (): Promise<Vehicle[]> => {
    try {
        const response = await api.get('api/vehicle');

        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI::api/vehicle');
    }
};
