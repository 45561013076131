import { lazy } from 'react';
import Loader from '@/components/common/Loader';

export const StationsRoute = Loader(
    lazy(() => import('@/pages/dashboard/Stations/irve/Stations'))
);

export const StationProfileRoute = Loader(
    lazy(() => import('@/pages/dashboard/Stations/irve//Profile/Profile'))
);

export const StationAddRoute = Loader(
    lazy(() => import('@/pages/dashboard/Stations/irve/Add/StationAdd'))
);
