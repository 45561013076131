import irveAppartement from './irve-appartment.png';
import irveCarKeys from './irve-car-keys.png';
import irveCarport from './irve-carport.png';
import irveChargingCar from './irve-charging-car.png';
import irveChecklist from './irve-checklist.png';
import irveClipboard from './irve-clipboard.png';
import irveElectricCar from './irve-electric-car.png';
import irveElectricPanel from './irve-electric-panel.png';
import irveElectricity from './irve-electricity.png';
import irveEuro from './irve-euro.png';
import irveFeetStation from './irve-feet-station.png';
import irveFree from './irve-free.png';
import irveHouse from './irve-house.png';
import irveIdk from './irve-idk.png';
import irveLess2Years from './irve-less-2-years.png';
import irveLivingRoom from './irve-living-room.png';
import irveMetalRoof from './irve-metal-roof.png';
import irveMetal from './irve-metal.png';
import irve from './irve-.png';
import irveMixte from './irve-mixte.png';
import irveMonophased from './irve-monophasé.png';
import irveMore2Years from './irve-more-2-years.png';
import irveNo from './irve-no.png';
import irveOtherEnergy from './irve-other-energy.png';
import irveOtherHeater from './irve-other-heater.png';
import irveOtherRoof from './irve-other-roof.png';
import irveOut from './irve-out.png';
import irveOutside from './irve-outside.png';
import irvePac from './irve-pac.png';
import irvePaper from './irve-paper.png';
import irveTime from './irve-time.png';
import irveTriphased from './irve-triphasé.png';
import irveWallStation from './irve-wall-station.png';
import irveWaterHeater from './irve-water-heater.png';
import irveWires from './irve-wires.png';
import irveWood from './irve-wood.png';
import irveGiftCar from './irve-car-gift.png';

// Recap
import irveStation from './irve-borne-station.png';
import irveAccessories from './irve-accessories-cable.png';
import irveInstallerPower from './irve-installer-power-drill.png';
import irvePigMoney from './irve-pig-money-fill.png';

export const imageIrveGiftCar = irveGiftCar;

export const imageIrveAppartement = irveAppartement;
export const imageIrveCarKeys = irveCarKeys;
export const imageIrveCarport = irveCarport;
export const imageIrveChargingCar = irveChargingCar;
export const imageIrveChecklist = irveChecklist;
export const imageIrveClipboard = irveClipboard;
export const imageIrveElectricCar = irveElectricCar;
export const imageIrveElectricPanel = irveElectricPanel;
export const imageIrveElectricity = irveElectricity;
export const imageIrveEuro = irveEuro;
export const imageIrveFeetStation = irveFeetStation;
export const imageIrveFree = irveFree;
export const imageIrveHouse = irveHouse;
export const imageIrveIdk = irveIdk;
export const imageIrveLess2Years = irveLess2Years;
export const imageIrveLivingRoom = irveLivingRoom;
export const imageIrveMetalRoof = irveMetalRoof;
export const imageIrveMetal = irveMetal;
export const imageIrveMixte = irveMixte;
export const imageIrveMonophased = irveMonophased;
export const imageIrveMore2Years = irveMore2Years;
export const imageIrveNo = irveNo;
export const imageIrveOtherEnergy = irveOtherEnergy;
export const imageIrveOtherHeater = irveOtherHeater;
export const imageIrveOtherRoof = irveOtherRoof;
export const imageIrveOut = irveOut;
export const imageIrveOutside = irveOutside;
export const imageIrvePac = irvePac;
export const imageIrvePaper = irvePaper;
export const imageIrveTime = irveTime;
export const imageIrveTriphased = irveTriphased;
export const imageIrveWallStation = irveWallStation;
export const imageIrveWaterHeater = irveWaterHeater;
export const imageIrveWires = irveWires;
export const imageIrveWood = irveWood;

export const imageIrvePigMoney = irvePigMoney;
export const imageIrveAccessories = irveAccessories;
export const imageIrveStation = irveStation;
export const imageIrveInstallerPower = irveInstallerPower;
