import { lazy } from 'react';
import Loader from '@/components/common/Loader';

export const ManagersRoute = Loader(
    lazy(() => import('@/pages/dashboard/Managers/irve/Managers'))
);

export const ManagersProfileRoute = Loader(
    lazy(() => import('@/pages/dashboard/Managers/irve/Profile/ManagerProfile'))
);

export const ManagerAddRoute = Loader(
    lazy(() => import('@/pages/dashboard/Managers/irve/Add/ManagerAdd'))
);
