import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type appState = {
    appState: string;
};

const initialState: appState = {
    appState: ''
};

export const { actions, reducer } = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setStateAppIrve: (state, action: PayloadAction<string>) => {
            state.appState = action.payload;
        },
        clearStateAppIrve: (state) => {
            state = initialState;
        }
    }
});

export const { setStateAppIrve, clearStateAppIrve } = actions;

export default reducer;
