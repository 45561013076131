import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QnA, QnAVehicle, Form3Store } from '@/models/irve/form.model';

type UpdateQnAAction = PayloadAction<QnA>;
type UpdateQnAVehicleAction = PayloadAction<QnAVehicle>;

const initialState: Form3Store = {
    qna1: null,
    qna2: null,
    qna3: null,
    qna4: null
};

const form3Slice = createSlice({
    name: 'form3',
    initialState,
    reducers: {
        setStateForm3QnA1Irve: (state, action: UpdateQnAAction) => {
            state.qna1 = action.payload;
        },
        setStateForm3QnA2Irve: (state, action: UpdateQnAVehicleAction) => {
            state.qna2 = action.payload;
        },
        setStateForm3QnA3Irve: (state, action: UpdateQnAAction) => {
            state.qna3 = action.payload;
        },
        setStateForm3QnA4Irve: (state, action: UpdateQnAAction) => {
            state.qna4 = action.payload;
        },
        clearStateForm3Irve: (state) => {
            state.qna1 = null;
            state.qna2 = null;
            state.qna3 = null;
            state.qna4 = null;
        }
    }
});

export const {
    setStateForm3QnA3Irve,
    setStateForm3QnA2Irve,
    setStateForm3QnA1Irve,
    setStateForm3QnA4Irve,
    clearStateForm3Irve
} = form3Slice.actions;

export default form3Slice.reducer;
