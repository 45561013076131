import { regionStore } from '@/models/common/region.models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type state = {
    region: regionStore[] | null;
};

const initialState: state = {
    region: null
};

export const { actions, reducer } = createSlice({
    name: 'region',
    initialState,
    reducers: {
        setStateRegionList: (state, action: PayloadAction<regionStore[]>) => {
            state.region = action.payload;
        },
        clearStateRegion: (state) => {
            state.region = null;
        }
    }
});

export const { setStateRegionList, clearStateRegion } = actions;

export default reducer;
