import { RouteType } from '@/models/common/route.models';
import { userCustomerPathsPv } from '@/routes/routesPathsIPV';

import {
    DescriptionTwoTone,
    EditNoteTwoTone,
    EvStationTwoTone,
    FilePresentTwoTone,
    MessageTwoTone,
    PersonTwoTone
} from '@mui/icons-material';

import {
    ChangeInstallersRoute,
    DocumentsRoute,
    MeRoute,
    MessengerRoute,
    ProjectRoute,
    RecapRoute,
    YousignRoute,
    ContactRoute
} from './Customer.routes';

const accountCustomerRoutesPv: RouteType[] = [
    {
        path: userCustomerPathsPv.project,
        element: <ProjectRoute />,
        state: 'account-customer-b2c.project',
        sidebarProps: {
            displayText: 'Mon projet',
            icon: <EvStationTwoTone />
        },
        show: true,
        index: null,
        child: null,
        label: null
    },
    {
        path: userCustomerPathsPv.meInformations,
        element: <MeRoute />,
        state: 'account-customer-b2c.me',
        sidebarProps: {
            displayText: 'Mes informations',
            icon: <PersonTwoTone />
        },
        show: true,
        index: null,
        child: null,
        label: null
    },
    {
        path: userCustomerPathsPv.documents,
        element: <DocumentsRoute />,
        state: 'account-customer-b2c.documents',
        sidebarProps: {
            displayText: 'Mes documents',
            icon: <FilePresentTwoTone />
        },
        show: true,
        index: null,
        child: null,
        label: null
    },
    {
        path: userCustomerPathsPv.recap,
        element: <RecapRoute />,
        state: 'account-customer-b2c.recap',
        sidebarProps: {
            displayText: 'Mon offre',
            icon: <DescriptionTwoTone />
        },
        show: true,
        index: null,
        child: null,
        label: null
    },
    {
        path: userCustomerPathsPv.contact,
        element: <ContactRoute />,
        state: 'account-customer-b2c.contact',
        sidebarProps: {
            displayText: 'Contact',
            icon: <MessageTwoTone />
        },
        show: true,
        index: null,
        child: null,
        label: null
    },
    {
        path: userCustomerPathsPv.changeInstaller,
        element: <ChangeInstallersRoute />,
        state: 'account-customer-b2c.changeInstaller',
        sidebarProps: null,
        show: true,
        index: null,
        child: null,
        label: null
    }
];

export default accountCustomerRoutesPv;
