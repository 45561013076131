import { lazy } from 'react';
import Loader from '@/components/common/Loader';

export const B2CChangeStationsRoute = Loader(
    lazy(
        () => import('@/pages/accountInstaller/B2C/irve/Profile/ChangeStations')
    )
);
export const B2CInvoicePreviewRoute = Loader(
    lazy(
        () => import('@/pages/accountInstaller/B2C/irve/Profile/InvoicePreview')
    )
);

export const B2CHouseOnGoingRoute = Loader(
    lazy(() => import('@/pages/accountInstaller/B2C/irve/HousesOnGoing'))
);

export const B2CHousesArchivedRoute = Loader(
    lazy(() => import('@/pages/accountInstaller/B2C/irve/HousesArchived'))
);

export const B2CApartmentsOnGoingRoute = Loader(
    lazy(() => import('@/pages/accountInstaller/B2C/irve/ApartmentsOnGoing'))
);

export const B2CApartmentsArchivedRoute = Loader(
    lazy(() => import('@/pages/accountInstaller/B2C/irve/ApartmentsArchived'))
);
