import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QnA, Form1Store } from '@/models/irve/form.model';

type UpdateQ1Action = PayloadAction<QnA>;

const initialState: Form1Store = {
    qna1: null
};

const form1Slice = createSlice({
    name: 'formHousing',
    initialState,
    reducers: {
        setStateForm1QnA1Irve: (state, action: UpdateQ1Action) => {
            state.qna1 = action.payload;
        },
        clearStateForm1Irve: (state) => {
            state.qna1 = null;
        }
    }
});

export const { setStateForm1QnA1Irve, clearStateForm1Irve } =
    form1Slice.actions;

export default form1Slice.reducer;
