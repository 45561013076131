import { Drawer, List, ListItemText, Stack, Toolbar, useTheme } from '@mui/material';
import SidebarItem from './SidebarItem';
import SidebarItemCollapse from './SidebarItemCollapse';
import LogoChargeIn from '@/assets/logos/logoChargeinLong.svg';
import { FC } from 'react';
import ButtonSwitchRoute from '../buttons/ButtonSwitchRoute';
import { useSelector } from 'react-redux';
import { selectRoutesIrvePV, selectUser } from '@/store/selector';
import TypographyCustom from './Typography';
import { LogoChargeInSideIrve, LogoChargeInSidePv } from '@/assets/logos';

interface Props {
    routesData: any;
}

const Sidebar: FC<Props> = ({ routesData }) => {
    const theme = useTheme();

    const { route } = useSelector(selectRoutesIrvePV);
    const { user } = useSelector(selectUser);

    return (
        <Drawer
            variant="permanent"
            sx={{
                minWidth: theme.sidebar.width,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    minWidth: theme.sidebar.width,
                    boxSizing: 'border-box',
                    borderRight: '0px',
                    backgroundColor: theme.sidebar.bg,
                    color: theme.sidebar.color,
                    zIndex: 900
                }
            }}
        >
            <List disablePadding>
                <Toolbar sx={{ marginBottom: '20px' }}>
                    <Stack sx={{ width: '100%' }} direction="row" justifyContent="center" padding={'10px'}>
                        <img src={route === 'irve' ? LogoChargeInSideIrve : LogoChargeInSidePv} alt="Logo" style={{ height: '32px' }} />
                    </Stack>
                </Toolbar>

                {!user?.roles.includes('ROLE_CUSTOMER') && <ButtonSwitchRoute />}

                {routesData.map((route: any, index: number) =>
                    route.sidebarProps ? (
                        route.child ? (
                            <SidebarItemCollapse item={route} key={index} />
                        ) : (
                            route.show === true && <SidebarItem item={route} key={index} />
                        )
                    ) : route.label ? (
                        <ListItemText
                            id="switch-list-label-wifi"
                            primary={route.label}
                            key={index}
                            sx={{
                                fontSize: '12px',
                                marginTop: '20px',
                                marginLeft: '15px',
                                marginBottom: '10px',
                                textTransform: 'uppercase',
                                color: theme.sidebar.labelColor
                            }}
                        />
                    ) : null
                )}
            </List>
        </Drawer>
    );
};

export default Sidebar;
