import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SolarPanel, SolarPanelInstaller, SolarPanelStore } from '@/models/pv/solarPanel.models';
import { Pannel } from '@/models/pv/installer.models';

type UpdateSolarPanelAction = PayloadAction<SolarPanel>;
type UpdateSolarPanelInstallersAction = PayloadAction<SolarPanelInstaller>;

const initialState: SolarPanelStore = {
    solarPanel: null,
    solarPanelList: null,
    solarPanelInstaller: null
};

const solarPanelSlice = createSlice({
    name: 'solarPanelSlice',
    initialState,
    reducers: {
        setStateSolarPanel: (state, action: UpdateSolarPanelAction) => {
            state.solarPanel = action.payload;
        },
        setStateSolarPanelList: (state, action: PayloadAction<Pannel[]>) => {
            state.solarPanelList = action.payload;
        },
        setStateSolarPanelInstallers: (state, action: UpdateSolarPanelInstallersAction) => {
            state.solarPanelInstaller = action.payload;
        },
        clearStateSolarPanel: (state) => {
            state.solarPanel = null;
            state.solarPanelInstaller = null;
        }
    }
});

export const { setStateSolarPanel, setStateSolarPanelList, setStateSolarPanelInstallers, clearStateSolarPanel } = solarPanelSlice.actions;

export default solarPanelSlice.reducer;
