import {
    alpha,
    Badge,
    Box,
    Divider,
    IconButton,
    Link,
    List,
    ListItem,
    Popover,
    Tooltip,
    Typography,
    useTheme
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import { styled } from '@mui/material/styles';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import { boServices, notificationServices } from '@/services/irve';

import { B2B, B2BMessageWDetails } from '@/models/irve/b2b.models';
import { boB2BPath, boB2CHousePath } from '@/routes/routesPathsIrve';
import { useNavigate } from 'react-router-dom';
import { setStateNotificationsIrve } from '@/store/reducers/irve/notification.slice';
import { InvoiceFileWDetails } from '@/models/irve/invoice.models';
import {
    setStateOpportunityIrve,
    setStateOpportunityAllMessagesIrve
} from '@/store/reducers/irve/opportunity.slice';
import { B2CMessageWDetails } from '@/models/irve/b2c.models';
import { selectNotification, selectUser } from '@/store/selector';
import {
    NotificationsActiveTwoTone,
    NotificationsOffTwoTone
} from '@mui/icons-material';

const NotificationsBadge = styled(Badge)(
    ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)};
        color: ${theme.palette.error.main};
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);

function Notification() {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();

    const ref = useRef<any>(null);
    const [isOpen, setOpen] = useState<boolean>(false);
    const [studies, setStudies] = useState<B2B[]>([]);
    const [valueNumberOfNotifications, setValueNumberOfNotifications] =
        useState<number>(0);
    const isMounted = useRef(false);

    const getAllStudiesFromDb = async () => {
        const response = await boServices.boGetStudiesOnGoingArchived(
            'ongoing'
        );
        setStudies(response);
    };

    const { user } = useSelector((state: RootState) => selectUser(state));
    //console.log('Notification::user => ', user);
    const { notifications } = useSelector((state: RootState) =>
        selectNotification(state)
    );
    //console.log('Notification::notifications => ', notifications);

    const handleOpenStudyConversation = (studyMessage: B2BMessageWDetails) => {
        handleClose();
        navigate(boB2BPath.messenger, {
            state: {
                studyId: studyMessage.study.id,
                allStudies: studies,
                studyName: studyMessage.study.name,
                study: studyMessage.study,
                installerId: studyMessage.createdBy.id,
                conversationId: studyMessage.conversationId
            }
        });
    };

    const handleOpenStudyInvoice = (invoiceFile: InvoiceFileWDetails) => {
        handleClose();
        navigate(boB2BPath.onGoingProfile, {
            state: {
                invoiceFileId: invoiceFile.file.id,
                studyId: invoiceFile.study.id,
                allStudies: studies
            }
        });
    };

    const handleOpenOpportunityMessage = async (
        opportunityMessage: B2CMessageWDetails
    ) => {
        handleClose();
        const response = await boServices.boGetOneOpportunity(
            opportunityMessage.opportunity.id
        );
        if (response) {
            dispatch(setStateOpportunityIrve(response));

            const response2 = await boServices.boGetOpportunityAllMessages(
                opportunityMessage.opportunity.id
            );
            if (response2) {
                dispatch(setStateOpportunityAllMessagesIrve(response2));
                navigate(boB2CHousePath.messenger);
            }
        }
    };

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    const getNotificationsAdminsFromDB = async () => {
        const response = await notificationServices.notifAdminGetAll();
        if (response.hasNotifications) {
            dispatch(setStateNotificationsIrve(response));
        } else {
            dispatch(setStateNotificationsIrve(null));
        }
    };

    useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true;
            if (user?.roles.includes('ROLE_ADMIN')) {
                // getAllStudiesFromDb();
                getNotificationsAdminsFromDB();
            }
        }
    }, []);

    //console.log('valueNumberOfNotifications => ', valueNumberOfNotifications);

    return (
        <>
            {notifications ? (
                <Box>
                    <Tooltip arrow title="Notifications">
                        <IconButton
                            color="primary"
                            ref={ref}
                            onClick={handleOpen}
                        >
                            <NotificationsBadge
                                badgeContent={
                                    notifications.numberOfNotifications
                                }
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                            >
                                <NotificationsActiveTwoTone />
                            </NotificationsBadge>
                        </IconButton>
                    </Tooltip>
                    <Popover
                        anchorEl={ref.current}
                        onClose={handleClose}
                        open={isOpen}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                    >
                        <Box
                            sx={{ p: 2 }}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Typography variant="h5">Notifications</Typography>
                        </Box>
                        <Divider />
                        <List sx={{ p: 0 }}>
                            {notifications.notificationsStudies
                                ? notifications.notificationsStudies.notificationsMessages?.map(
                                      (
                                          notificationsMessage: B2BMessageWDetails
                                      ) => {
                                          return (
                                              <Box
                                                  flex="1"
                                                  key={
                                                      notificationsMessage
                                                          .studyMessage.id
                                                  }
                                              >
                                                  <ListItem
                                                      sx={{
                                                          p: 2,
                                                          minWidth: 350,
                                                          display: {
                                                              xs: 'block',
                                                              sm: 'flex'
                                                          }
                                                      }}
                                                  >
                                                      <Box
                                                          display="flex"
                                                          justifyContent="space-between"
                                                      >
                                                          <Link
                                                              onClick={() =>
                                                                  handleOpenStudyConversation(
                                                                      notificationsMessage
                                                                  )
                                                              }
                                                              sx={{
                                                                  margin: '10px',
                                                                  cursor: 'pointer'
                                                              }}
                                                              underline="hover"
                                                              target="_blank"
                                                          >
                                                              <Typography
                                                                  color={
                                                                      'secondary'
                                                                  }
                                                                  sx={{
                                                                      fontWeight:
                                                                          'bold'
                                                                  }}
                                                              >
                                                                  Nouveau
                                                                  message étude:{' '}
                                                                  {
                                                                      notificationsMessage
                                                                          .study
                                                                          .name
                                                                  }
                                                              </Typography>
                                                              <Typography
                                                                  variant={
                                                                      'body1'
                                                                  }
                                                              >
                                                                  Installateur:{' '}
                                                                  {
                                                                      notificationsMessage
                                                                          .createdBy
                                                                          .firstname
                                                                  }
                                                              </Typography>
                                                          </Link>
                                                      </Box>
                                                  </ListItem>
                                              </Box>
                                          );
                                      }
                                  )
                                : null}

                            {notifications.notificationsStudies
                                ? notifications.notificationsStudies.notificationsInvoices?.map(
                                      (
                                          notificationsInvoice: InvoiceFileWDetails
                                      ) => {
                                          return (
                                              <Box
                                                  flex="1"
                                                  key={
                                                      notificationsInvoice.file
                                                          .id
                                                  }
                                              >
                                                  <ListItem
                                                      sx={{
                                                          p: 2,
                                                          minWidth: 350,
                                                          display: {
                                                              xs: 'block',
                                                              sm: 'flex'
                                                          }
                                                      }}
                                                  >
                                                      <Box
                                                          display="flex"
                                                          justifyContent="space-between"
                                                      >
                                                          <Link
                                                              onClick={() =>
                                                                  handleOpenStudyInvoice(
                                                                      notificationsInvoice
                                                                  )
                                                              }
                                                              sx={{
                                                                  margin: '10px',
                                                                  cursor: 'pointer'
                                                              }}
                                                              underline="hover"
                                                              target="_blank"
                                                          >
                                                              <Typography
                                                                  color={
                                                                      'secondary'
                                                                  }
                                                                  sx={{
                                                                      fontWeight:
                                                                          'bold'
                                                                  }}
                                                              >
                                                                  Nouveau devis
                                                                  étude:{' '}
                                                                  {
                                                                      notificationsInvoice
                                                                          .study
                                                                          .name
                                                                  }
                                                              </Typography>
                                                              <Typography
                                                                  variant={
                                                                      'body1'
                                                                  }
                                                              >
                                                                  Installateur:{' '}
                                                                  {
                                                                      notificationsInvoice
                                                                          .createdBy
                                                                          .firstname
                                                                  }
                                                              </Typography>
                                                          </Link>
                                                      </Box>
                                                  </ListItem>
                                              </Box>
                                          );
                                      }
                                  )
                                : null}

                            {notifications.notificationsOpportunities
                                ? notifications.notificationsOpportunities.notificationsMessages?.map(
                                      (
                                          notificationsMessage: B2CMessageWDetails
                                      ) => {
                                          return (
                                              <Box
                                                  flex="1"
                                                  key={
                                                      notificationsMessage
                                                          .opportunityMessage.id
                                                  }
                                              >
                                                  <ListItem
                                                      sx={{
                                                          p: 2,
                                                          minWidth: 350,
                                                          display: {
                                                              xs: 'block',
                                                              sm: 'flex'
                                                          }
                                                      }}
                                                  >
                                                      <Box
                                                          display="flex"
                                                          justifyContent="space-between"
                                                      >
                                                          <Link
                                                              onClick={() =>
                                                                  handleOpenOpportunityMessage(
                                                                      notificationsMessage
                                                                  )
                                                              }
                                                              sx={{
                                                                  margin: '10px',
                                                                  cursor: 'pointer'
                                                              }}
                                                              underline="hover"
                                                              target="_blank"
                                                          >
                                                              <Typography
                                                                  color={
                                                                      'secondary'
                                                                  }
                                                                  sx={{
                                                                      fontWeight:
                                                                          'bold'
                                                                  }}
                                                              >
                                                                  Nouveau
                                                                  message
                                                                  opportunité:{' '}
                                                                  {
                                                                      notificationsMessage
                                                                          .opportunity
                                                                          .sellsyId
                                                                  }
                                                              </Typography>
                                                              <Typography
                                                                  variant={
                                                                      'body1'
                                                                  }
                                                              >
                                                                  Client:{' '}
                                                                  {
                                                                      notificationsMessage
                                                                          .opportunity
                                                                          .customerB2C
                                                                          .lastname
                                                                  }{' '}
                                                                  {
                                                                      notificationsMessage
                                                                          .opportunity
                                                                          .customerB2C
                                                                          .firstname
                                                                  }
                                                              </Typography>
                                                          </Link>
                                                      </Box>
                                                  </ListItem>
                                              </Box>
                                          );
                                      }
                                  )
                                : null}
                        </List>
                    </Popover>
                </Box>
            ) : (
                <Box>
                    <Tooltip arrow title="Notifications">
                        <IconButton color="primary" ref={ref}>
                            <NotificationsOffTwoTone />
                        </IconButton>
                    </Tooltip>
                </Box>
            )}
        </>
    );
}

export default Notification;
