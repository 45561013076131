import NetworkBackground from './bg-irve-1.png';
import NetworkBackgroundpv from './bg-pv-1.png';

import Free from './free.png';
import Note from './note.png';
import Time from './time.png';

export const NetworkBackgroundPicture = NetworkBackground;
export const NetworkBackgroundPicturePv = NetworkBackgroundpv;

export const FreeComparatorPicture = Free;
export const NoteComparatorPicture = Note;
export const TimeComparatorPicture = Time;
