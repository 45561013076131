import { RouteType } from '@/models/common/route.models';
import { comparatorPathsPv } from '@/routes/routesPathsIPV';
import { LandingRoutePv } from './Landing.routes';

import {
    FormConsumPvRoute,
    FormHousingPvRoute,
    FormInformationsPvRoute,
    FormProjectPvRoute
} from './Form.routes';

import {
    ComparatorNotFoundRoutePv,
    RecapOffersRoute,
    ResultLoaderRedirectRoute,
    ResultLoaderRoutePv,
    ResultOffersRoute
} from './Result.routes';
import FormOtherPv from '@/pages/comparator/pv/FormOther';

export const comparatorRoutesPv: RouteType[] = [
    {
        path: comparatorPathsPv.landing,
        element: <LandingRoutePv />,
        state: 'comparator.landing',
        show: false,
        index: null,
        child: null,
        sidebarProps: null,
        label: null
    },
    {
        path: comparatorPathsPv.formOther,
        element: <FormOtherPv />,
        state: 'comparator.landing',
        show: false,
        index: null,
        child: null,
        sidebarProps: null,
        label: null
    },
    {
        path: comparatorPathsPv.formHousing,
        element: <FormHousingPvRoute />,
        state: 'comparator.formHousing',
        show: false,
        index: null,
        child: null,
        sidebarProps: null,
        label: null
    },
    {
        path: comparatorPathsPv.formConsum,
        element: <FormConsumPvRoute />,
        state: 'comparator.formConsumPv',
        show: false,
        index: null,
        child: null,
        sidebarProps: null,
        label: null
    },
    {
        path: comparatorPathsPv.formProject,
        element: <FormProjectPvRoute />,
        state: 'comparator.formProjectPv',
        show: false,
        index: null,
        child: null,
        sidebarProps: null,
        label: null
    },
    {
        path: comparatorPathsPv.formInformation,
        element: <FormInformationsPvRoute />,
        state: 'comparator.formInformationPv',
        show: false,
        index: null,
        child: null,
        sidebarProps: null,
        label: null
    },
    {
        path: comparatorPathsPv.resultLoader,
        element: <ResultLoaderRoutePv />,
        state: 'comparator.resultPvLoader',
        show: false,
        index: null,
        child: null,
        sidebarProps: null,
        label: null
    },
    {
        path: comparatorPathsPv.notFound,
        element: <ComparatorNotFoundRoutePv />,
        state: 'comparator.notfoundPv',
        show: false,
        index: null,
        child: null,
        sidebarProps: null,
        label: null
    },
    {
        path: comparatorPathsPv.resultRedirect,
        element: <ResultLoaderRedirectRoute />,
        state: 'comparator.resultRedirect',
        show: false,
        index: null,
        child: null,
        sidebarProps: null,
        label: null
    },
    {
        path: comparatorPathsPv.resultOffers,
        element: <ResultOffersRoute />,
        state: 'comparator.resultRedirect',
        show: false,
        index: null,
        child: null,
        sidebarProps: null,
        label: null
    },
    {
        path: comparatorPathsPv.resultRecapAll,
        element: <RecapOffersRoute />,
        state: 'comparator.resultRedirect',
        show: false,
        index: null,
        child: null,
        sidebarProps: null,
        label: null
    }
];
