import { imageIrveOut } from '@/assets/images/irve';
import { imagePvOut } from '@/assets/images/pv';
import ButtonPrimary from '@/components/buttons/ButtonPrimary';
import TypographyCustom from '@/components/common/Typography';
import { Login } from '@mui/icons-material';
import { Box, Container, styled, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const StyledNotFound = styled(Box)`
    padding: 96px 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const FormOtherPv = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    return (
        <Container
            component={'main'}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <StyledNotFound>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    textAlign={'center'}
                    gap={'8px'}
                >
                    <Box
                        sx={{
                            background: theme.colors.gradients.primary,
                            backgroundClip: 'text'
                        }}
                    >
                        <TypographyCustom
                            font="poppins"
                            variant="h1"
                            color="transparent"
                        >
                            Votre projet demande une étude plus approfondie,
                            merci de prendre contact avec l'equipe Charge-in via
                            contact@charge-in.fr{' '}
                        </TypographyCustom>
                    </Box>
                </Box>
                <Box display={'flex'} justifyContent={'center'}>
                    <ButtonPrimary handleClick={() => navigate('/pv')}>
                        Retourner à l’accueil <Login />
                    </ButtonPrimary>
                </Box>
            </StyledNotFound>
        </Container>
    );
};

export default FormOtherPv;
