import { CircularProgress, useTheme } from '@mui/material';
import { Box, styled } from '@mui/system';
import { LogoChargeInSquare } from '@/assets/logos';

const StyledLoading = styled('div')`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .circleProgress,
    .img-container {
        position: fixed;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .circleProgress {
        z-index: 20;
        top: 50%;
    }
    .img-container {
        border-radius: 100%;
        overflow: hidden;
        height: 35px;
        width: 35px;
        top: 25px;
    }
`; /* (() => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
        width: 'auto',
        height: '25px'
    },
    '& .circleProgress': {
        position: 'absolute',
        left: '50%',
        top: 'calc(50% - 25px)'
    }
})); */

const SuspenseLoader = () => {
    const theme = useTheme();

    return (
        <StyledLoading>
            <Box className="circleProgress">
                <Box className="img-container">
                    <img
                        src={LogoChargeInSquare}
                        alt="logoChargeinSquare"
                        width="35"
                    />
                </Box>
                <CircularProgress
                    size={50}
                    sx={{
                        border: `2px solid ${theme.colors.gradients.primary}`
                    }}
                />
            </Box>
        </StyledLoading>
    );
};

export default SuspenseLoader;
