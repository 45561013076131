import { lazy } from 'react';
import Loader from '@/components/common/Loader';

export const ProjectRoute = Loader(
    lazy(() => import('@/pages/accountCustomer/pv/Project'))
);

export const MeRoute = Loader(
    lazy(() => import('@/pages/accountCustomer/pv/Me'))
);

export const DocumentsRoute = Loader(
    lazy(() => import('@/pages/accountCustomer/pv/Documents'))
);

export const RecapRoute = Loader(
    lazy(() => import('@/pages/accountCustomer/pv/Recap'))
);

export const MessengerRoute = Loader(
    lazy(() => import('@/pages/accountCustomer/pv/Messenger'))
);

export const YousignRoute = Loader(
    lazy(() => import('@/pages/accountCustomer/pv/Yousign'))
);

export const ChangeInstallersRoute = Loader(
    lazy(() => import('@/pages/accountCustomer/pv/ChangeInstallers'))
);

export const ContactRoute = Loader(
    lazy(() => import('@/pages/accountCustomer/pv/Contact'))
);
