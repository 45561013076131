import { CssBaseline } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import App from './App';
import { persistor, store } from './store';
import reportWebVitals from './reportWebVitals';
import { PersistGate } from 'redux-persist/integration/react';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: 'GTM-PKJWB2D'
};
TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <HelmetProvider>
        <React.StrictMode>
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <CssBaseline />
                    <App />
                </PersistGate>
            </Provider>
        </React.StrictMode>
    </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
