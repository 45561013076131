import { RouteType } from '@/models/common/route.models';
import { comparatorPathsIrve } from '@/routes/routesPathsIrve';
import {
    FormHousingRoute,
    FormVehiclesRoute,
    FormMyProjectRoute,
    FormUserInformationRoute,
    FormRecapRoute
} from './Form.routes';

import {
    ResultAccountAlreadyExistsRoute,
    ResultLoaderRoute,
    ResultRecapAllRoute,
    ResultRecapInstallersRoute,
    ResultRecapStationsRoute,
    ResultStationsNotFoundRoute
} from './Result.routes';

import { LandingRoute } from './Landing.routes';
import { BaseRoute } from './base.routes';
import ComparatorNotFound from '@/pages/comparator/irve/NotFound';
import ResultRedirect from '@/pages/comparator/irve/ResultRedirectDashboard';

export const comparatorRoutesIrve: RouteType[] = [
    {
        path: comparatorPathsIrve.base,
        element: <BaseRoute />,
        state: 'comparator.base',
        show: false,
        index: null,
        child: null,
        sidebarProps: null,
        label: null
    },
    {
        path: comparatorPathsIrve.resultRedirect,
        element: <ResultRedirect />,
        state: 'comparator.resultRedirect',
        show: false,
        index: null,
        child: null,
        sidebarProps: null,
        label: null
    },
    {
        path: comparatorPathsIrve.landing,
        element: <LandingRoute />,
        state: 'comparator.landing',
        show: false,
        index: null,
        child: null,
        sidebarProps: null,
        label: null
    },
    {
        path: comparatorPathsIrve.notFound,
        element: <ComparatorNotFound />,
        state: 'comparator.notFound',
        show: false,
        index: null,
        child: null,
        sidebarProps: null,
        label: null
    },
    {
        path: comparatorPathsIrve.formHousing,
        element: <FormHousingRoute />,
        state: 'comparator.formHousing',
        show: false,
        index: null,
        child: null,
        sidebarProps: null,
        label: null
    },
    {
        path: comparatorPathsIrve.formVehicle,
        element: <FormVehiclesRoute />,
        state: 'comparator.formVehicle',
        show: false,
        index: null,
        child: null,
        sidebarProps: null,
        label: null
    },

    {
        path: comparatorPathsIrve.formMyProject,
        element: <FormMyProjectRoute />,
        state: 'comparator.form4',
        show: false,
        index: null,
        child: null,
        sidebarProps: null,
        label: null
    },

    {
        path: comparatorPathsIrve.formUserInformation,
        element: <FormUserInformationRoute />,
        state: 'comparator.form6',
        show: false,
        index: null,
        child: null,
        sidebarProps: null,
        label: null
    },
    {
        path: comparatorPathsIrve.formRecap,
        element: <FormRecapRoute />,
        state: 'comparator.recapForm',
        show: false,
        index: null,
        child: null,
        sidebarProps: null,
        label: null
    },
    {
        path: comparatorPathsIrve.resultLoader,
        element: <ResultLoaderRoute />,
        state: 'comparator.resultLoader',
        show: false,
        index: null,
        child: null,
        sidebarProps: null,
        label: null
    },
    {
        path: comparatorPathsIrve.resultLoaderV2,
        element: <ResultLoaderRoute />,
        state: 'comparator.resultLoader',
        show: false,
        index: null,
        child: null,
        sidebarProps: null,
        label: null
    },
    {
        path: comparatorPathsIrve.resultRecapStations,
        element: <ResultRecapStationsRoute />,
        state: 'comparator.resultRecapStations',
        show: false,
        index: null,
        child: null,
        sidebarProps: null,
        label: null
    },
    {
        path: comparatorPathsIrve.resultRecapInstallers,
        element: <ResultRecapInstallersRoute />,
        state: 'comparator.resultRecapInstallers',
        show: false,
        index: null,
        child: null,
        sidebarProps: null,
        label: null
    },
    {
        path: comparatorPathsIrve.resultRecapAll,
        element: <ResultRecapAllRoute />,
        state: 'comparator.resultRecapAll',
        show: false,
        index: null,
        child: null,
        sidebarProps: null,
        label: null
    },
    {
        path: comparatorPathsIrve.resultAccountAlreadyExists,
        element: <ResultAccountAlreadyExistsRoute />,
        state: 'comparator.resultAccountAlreadyExists',
        show: false,
        index: null,
        child: null,
        sidebarProps: null,
        label: null
    }
];
