import { lazy } from 'react';
import Loader from '@/components/common/Loader';

export const StationsRoute = Loader(
    lazy(() => import('@/pages/accountInstaller/Me/irve/Stations'))
);

export const StationsAddRoute = Loader(
    lazy(() => import('@/pages/accountInstaller/Me/irve/StationsAdd'))
);
