import { RootState, store } from '@/store';

const state: RootState = store.getState();

export const basePathsPv = {
    index: '/'
};

export const loginPathsPv = {
    index: `/login`
};

// pv
export const userCustomerPathsPv = {
    project: `/pv/account-customer-b2c/project`,
    meInformations: `/pv/account-customer-b2c/me`,
    documents: `/pv/account-customer-b2c/documents`,
    recap: `/pv/account-customer-b2c/recap`,
    messenger: `/pv/account-customer-b2c/messenger`,
    changeStation: `/pv/account-customer-b2c/change-station`,
    changeInstaller: `/pv/account-customer-b2c/change-installer`,
    selectStation: `/pv/account-customer-b2c/select-station`,
    selectInstaller: `/pv/account-customer-b2c/select-installer`,
    yousign: `/pv/account-customer-b2c/yousign`,
    contact: `/pv/account-customer-b2c/contact`
};

export const userInstallerPathsPv = {
    overview: `/pv/account-installer/overview`,
    meInformations: `/pv/account-installer/me/informations`,
    meLegalInformations: `/pv/account-installer/me/legal-informations`,
    meInstallations: `/pv/account-installer/me/legal-installations`,
    meStations: `/pv/account-installer/me/stations`,
    meAccessories: `/pv/account-installer/me/accessories`
};

export const userInstallerB2BPathsPv = {
    index: `/pv/account-installer/opportunities-b2b`,
    onGoing: `/pv/account-installer/opportunities-b2b/on-going`,
    onGoingProfile: `/pv/account-installer/opportunity-b2b/on-going/profile`,
    archived: `/pv/account-installer/opportunities-b2b/archived`,
    archivedProfile: `/pv/account-installer/opportunity-b2b/archived/profile`,
    messenger: `/pv/account-installer/opportunity-b2b/messenger`
};

export const userInstallerB2CApartmentPathsPv = {
    index: `/pv/account-installer/opportunities-b2c/apartment`,
    onGoing: `/pv/account-installer/opportunities-b2c/apartment/on-going`,
    onGoingProfile: `/pv/account-installer/opportunity-b2c/apartment/on-going/profile`,
    archived: `/pv/account-installer/opportunities-b2c/apartment/archived`,
    archivedProfile: `/pv/account-installer/opportunity-b2c/apartment/archived/profile`
};

export const userInstallerB2CHousePathsPv = {
    index: `/pv/account-installer/opportunities-b2c/house`,
    onGoing: `/pv/account-installer/opportunities-b2c/house/on-going`,
    onGoingProfile: `/pv/account-installer/opportunity-b2c/house/on-going/profile`,
    archived: `/pv/account-installer/opportunities-b2c/house/archived`,
    archivedProfile: `/pv/account-installer/opportunity-b2c/house/archived/profile`,
    changeStation: `/pv/account-installer/opportunity-b2c/house/change-station`,
    changeInstaller: `/pv/account-installer/opportunity-b2c/house/change-installer`,
    messenger: `/pv/account-installer/opportunity-b2c/house/opportunity-b2c/messenger`,
    preview: `/pv/account-installer/opportunity-b2c/house/opportunity-b2c/preview`
};

export const userInstallerSetupPathsPv = {
    profile: `/pv/account-installer/setup/profile`,
    legalInformations: `/pv/account-installer/setup/informations`,
    installations: `/pv/account-installer/setup/installations`,
    pannels: `/pv/account-installer/setup/pannels`,
    pannelsAdd: `/pv/account-installer/setup/pannels-add`,
    accessories: `/pv/account-installer/setup/accessoires`,
    accessoriesAdd: `/pv/account-installer/setup/accessoires-add`,
    regions: `/pv/account-installer/setup/regions`,
    regionsAdd: `/pv/account-installer/setup/regions-add`,
    offerSimulation: '/pv/account-installer/setup/offer-simulation'
};

export const comparatorPathsPv = {
    landing: `/pv/`,
    formHousing: `/pv/comparator/form-housing`,
    formConsum: `/pv/comparator/form-consum`,
    formProject: '/pv/comparator/form-project',
    formInformation: '/pv/comparator/form-information',
    resultLoader: `/pv/comparator/result-loader`,
    resultRedirect: `/pv/comparator/redirect`,
    resultOffers: `/pv/comparator/result-offers`,
    resultRecapAll: `/pv/comparator/recap-all`,
    formOther: '/pv/comparator/form-other',
    notFound: `/pv/comparator/*`
};

export const boAccessoriesPathsPv = {
    accessories: `/pv/dashboard/accessories`,
    accessoriesStationMonophasedProfile: `/pv/dashboard/accessories-station-monophased/profile`,
    accessoriesStationMonophasedAdd: `/pv/dashboard/accessories-station-monophased/add`,
    accessoriesStationTriphasedProfile: `/pv/dashboard/accessories-station-triphased/profile`,
    accessoriesStationTriphasedAdd: `/pv/dashboard/accessories-station-triphased/add`,
    accessoriesInstallerProfile: `/pv/dashboard/accessories-installer/profile`,
    accessoriesInstallerAdd: `/pv/dashboard/accessories-installer/add`
};

export const boAdminsPathsPv = {
    admins: `/pv/dashboard/admins`,
    adminsProfile: `/pv/dashboard/admins/profile`,
    adminAdd: `/pv/dashboard/admins/add`
};

export const boManagersPathsPv = {
    managers: `/pv/dashboard/managers`,
    managersProfile: `/pv/dashboard/managers/profile`,
    managerAdd: `/pv/dashboard/managers/add`
};

export const boPmsPathsPv = {
    pms: `/pv/dashboard/pms`,
    pmsProfile: `/pv/dashboard/pms/profile`,
    pmAdd: `/pv/dashboard/pms/add`
};

export const boInstallersPathsPv = {
    installers: `/pv/dashboard/installers`,
    installersProfile: `/pv/dashboard/installers/profile`,
    installersProfileStationsAdd: `/pv/dashboard/installers/profile/add-stations`,
    installerAdd: `/pv/dashboard/installers/add`,
    installerAddSimple: `/pv/dashboard/installers/add-simple`,
    installerAddLegalInformations: `/pv/dashboard/installers/add-legal-informations`,
    installerAddLSelectedStations: `/pv/dashboard/installers/add-selected-stations`,
    installerAddInstallations: `/pv/dashboard/installers/add-selected-installations`,
    installerAddAccessories: `/pv/dashboard/installers/add-accessories`,
    installerAddDepartments: `/pv/dashboard/installers/add-departments`,
    installerAddDepartmentsProfile: `/pv/dashboard/installers/add-departments-profile`,
    installerAddSolarPanels: `/pv/dashboard/installers/add-solar-panels`,
    installerRegionsAdd: `/pv/dashboard/installers/regions-add`
};

export const boB2BPathsPv = {
    index: `/pv/dashboard/opportunities-b2b`,
    onGoing: `/pv/dashboard/opportunities-b2b/on-going`,
    onGoingProfile: `/pv/dashboard/opportunity-b2b/on-going/profile`,
    archived: `/pv/dashboard/opportunities-b2b/archived`,
    archivedProfile: `/pv/dashboard/opportunity-b2b/archived/profile`,
    studyAdd: `/pv/dashboard/opportunity-b2b/study/add`,
    installersUpdate: `/pv/dashboard/opportunity-b2b/installers-update`,
    messenger: `/pv/dashboard/opportunity-b2b/messenger`
};

export const boB2CApartmentPathsPv = {
    index: `/pv/dashboard/opportunities-b2c/apartment`,
    onGoing: `/pv/dashboard/opportunities-b2c/apartment/on-going`,
    onGoingProfile: `/pv/dashboard/opportunity-b2c/apartment/on-going/profile`,
    archived: `/pv/dashboard/opportunities-b2c/apartment/archived`,
    archivedProfile: `/pv/dashboard/opportunity-b2c/apartment/archived/profile`,
    changeStation: `/pv/dashboard/change-station`,
    changeInstaller: `/pv/dashboard/change-installer`,
    messenger: `/pv/dashboard/opportunity-b2c/messenger`
};

export const boB2CHousePathsPv = {
    index: `/pv/dashboard/opportunities-b2c/house`,
    onGoing: `/pv/dashboard/opportunities-b2c/house/on-going`,
    onGoingProfile: `/pv/dashboard/opportunity-b2c/house/on-going/profile`,
    archived: `/pv/dashboard/opportunities-b2c/house/archived`,
    archivedProfile: `/pv/dashboard/opportunity-b2c/house/archived/profile`,
    changeOffer: `/pv/dashboard/change-offers`,
    changeInstaller: `/pv/dashboard/change-installer`,
    messenger: `/pv/dashboard/opportunity-b2c/messenger`
};

export const boB2CInvoicePathsPv = {
    preview: `/pv/dashboard/opportunity-b2c/invoice/preview`
};

export const boSolarPanelsPathsPv = {
    solarPanels: `/pv/dashboard/solar-panels`,
    solarPanelsProfile: `/pv/dashboard/solar-panels/profile`,
    solarPanelsAdd: `/pv/dashboard/solar-panels/add`
};

export const boOverviewsPathsPv = {
    index: `/pv/dashboard/overview`
};

// PV
