import { lazy } from 'react';
import Loader from '@/components/common/Loader';

export const InstallersRoute = Loader(
    lazy(() => import('@/pages/dashboard/Installers/pv/Installers'))
);

export const InstallerProfileRoute = Loader(
    lazy(
        () =>
            import('@/pages/dashboard/Installers/pv/Profile/InstallerProfile')
    )
);

export const InstallerProfileAddStationRoute = Loader(
    lazy(() => import('@/pages/dashboard/Installers/pv/Profile/StationsAdd'))
);

export const InstallerAddDepartmentsProfileRoute = Loader(
    lazy(
        () => import('@/pages/dashboard/Installers/pv/Profile/DepartmentsAdd')
    )
);

export const InstallerAddRoute = Loader(
    lazy(() => import('@/pages/dashboard/Installers/pv/Add/Installer'))
);

export const InstallerAddLegalInformationsRoute = Loader(
    lazy(
        () => import('@/pages/dashboard/Installers/pv/Add/LegalInformations')
    )
);

export const InstallerAddStationsRoute = Loader(
    lazy(() => import('@/pages/dashboard/Installers/pv/Add/Stations'))
);

export const InstallerAddInstallationsRoute = Loader(
    lazy(() => import('@/pages/dashboard/Installers/pv/Add/Installations'))
);

export const InstallerAddAccessoriesRoute = Loader(
    lazy(() => import('@/pages/dashboard/Installers/pv/Add/Accessories'))
);

export const InstallerAddDepartmentsRoute = Loader(
    lazy(() => import('@/pages/dashboard/Installers/pv/Add/Departments'))
);


export const InstallerAddSolarPanels = Loader(
    lazy(() => import('@/pages/dashboard/Installers/pv/Profile/PannelAdd'))
);

export const InstallerRegionsAddRoute = Loader(
    lazy(() => import('@/pages/dashboard/Installers/pv/Profile/DepartmentsAdd'))
);