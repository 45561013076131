import { lazy } from 'react';
import Loader from '@/components/common/Loader';

export const B2BOnGoingRoute = Loader(
    lazy(() => import('@/pages/accountInstaller/B2B/pv/OnGoing'))
);

export const B2BArchivedRoute = Loader(
    lazy(() => import('@/pages/accountInstaller/B2B/pv/Archived'))
);

export const B2BOnGoingProfileRoute = Loader(
    lazy(() => import('@/pages/accountInstaller/B2B/pv/Profile/Profile'))
);

export const B2BMessengerRoute = Loader(
    lazy(() => import('@/pages/accountInstaller/B2B/pv/Profile/Messenger'))
);

export const B2COnGoingProfileRoute = Loader(
    lazy(() => import('@/pages/accountInstaller/B2C/pv/Profile/Profile'))
);
