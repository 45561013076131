import { UserLogin, UserTokens } from '../../models/common/user.models';
import { api } from './config';
import { Notifications } from '../../models/irve/notification.models.type';

export const notifAdminGetAll = async (): Promise<Notifications> => {
    try {
        const response = await api.get(
            `/api/bo/notification/get-all-notifications`
        );
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const notifAdminSetStudyMessagesRead = async (
    id: number
): Promise<boolean> => {
    try {
        const response = await api.put(
            `/api/bo/notification/study/conversation/${id}/set-messages-read`
        );
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const notifAdminSetInvoiceRead = async (
    id: number
): Promise<boolean> => {
    try {
        const response = await api.put(
            `/api/bo/notification/invoice/${id}/set-invoice-read`
        );
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};

export const notifAdminSetOpportunityMessageRead = async (
    id: number
): Promise<boolean> => {
    try {
        const response = await api.put(
            `/api/bo/notification/opportunity/${id}/message/set-message-read`
        );
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI');
    }
};
