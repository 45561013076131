import { api } from './config';
import {
    UserCustomer, UserCustomerRegistered,
    UserCustomerSubscriber,
    UserCustomerToUpdate
} from '@/models/common/user.models';
import { apiFormdata } from './configFormData';
import { StationInstaller } from '@/models/irve/station.models';
import {
    ResultComparatorInstaller,
    ResultComparatorStation
} from '@/models/irve/resultsComparator.models';
import { B2C, B2CAccessories, B2CMessage } from '@/models/irve/b2c.models';
import {
    AccessoryInstallerInstaller,
    AccessoryStationMonophasedInstaller,
    AccessoryStationTriphasedInstaller
} from '@/models/irve/accessory.models.type';
import { YousignResponse } from '@/models/irve/yousign.models';

export const meCustomerProfile = async (): Promise<UserCustomer> => {
    try {
        const response = await api.get('api/customer-b2c/me');
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI::api/customer-b2c/me');
    }
};

export const registerCustomerB2C = async (
    user: UserCustomerSubscriber
): Promise<UserCustomer> => {
    try {
        const response = await api.post(
            'api/customer-b2c/register-w-qna-opportunity',
            user
        );
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error(
            'Failed::AccessAPI::api/customer-b2c/register-w-qna-opportunity'
        );
    }
};

export const registerCustomerB2CAndLogin = async (
    user: UserCustomerSubscriber
): Promise<UserCustomerRegistered> => {
    try {
        const response = await api.post(
            'api/customer-b2c/register-and-login',
            user
        );
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error(
            'Failed::AccessAPI::api/customer-b2c/register-and-login'
        );
    }
};

export const getPossibleStations = async (): Promise<
    ResultComparatorStation[]
> => {
    try {
        const response = await api.get(
            `api/customer-b2c/me/get-possible-stations`
        );
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const getPossibleInstallers = async (
    stationInstaller: StationInstaller
): Promise<ResultComparatorInstaller[]> => {
    try {
        const response = await api.post(
            `api/customer-b2c/me/get-possible-installers`,
            stationInstaller
        );
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const addSelectedStation = async (
    selectedStation: ResultComparatorStation
): Promise<B2C> => {
    try {
        const response = await api.post(
            `api/customer-b2c/me/add-selected-station`,
            selectedStation
        );
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const addSelectedInstaller = async (
    selectedInstaller: ResultComparatorInstaller
): Promise<B2C> => {
    try {
        const response = await api.post(
            `api/customer-b2c/me/add-selected-installer`,
            selectedInstaller
        );
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const addDoc = async (data: FormData): Promise<B2C> => {
    try {
        //console.log('Service sending data => ', data);
        const response = await apiFormdata.post(
            `api/customer-b2c/me/add-doc`,
            data
        );
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const opportunityUpdateStatus = async (status: string): Promise<B2C> => {
    try {
        const response = await api.post(
            `api/customer-b2c/me/update-opportunity-status/${status}`
        );
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const getOpportunity = async (): Promise<B2C> => {
    try {
        const response = await api.get(`api/customer-b2c/me/opportunity`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const meCustomerUpdateProfile = async (
    customerProfile: UserCustomerToUpdate
) => {
    try {
        const response = await api.put(
            `api/customer-b2c/me/update-profile`,
            customerProfile
        );
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const getAcessoriesInstallerInstaller = async (): Promise<
    AccessoryInstallerInstaller[]
> => {
    try {
        const response = await api.get(
            `api/customer-b2c/me/get-all-acessories-installers`
        );
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const getSelectedAccessoriesInstallerInstaller = async (): Promise<
    AccessoryInstallerInstaller[]
> => {
    try {
        const response = await api.get(
            `api/customer-b2c/me/get-selected-acessories-installers`
        );
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const updateSelectedAccessoriesInstallerInstaller = async (
    accessoriesList: AccessoryInstallerInstaller[],
    accessoryStation: boolean
): Promise<B2C> => {
    try {
        const response = await api.post(
            `api/customer-b2c/me/update-selected-acessories-installers`,
            {
                accessoriesInstallerInstaller: accessoriesList,
                accessoryStationInstaller: accessoryStation
            }
        );
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const updateSelectedAccessoryStationInstaller = async (
    accessoryStationInstaller:
        | AccessoryStationTriphasedInstaller
        | AccessoryStationMonophasedInstaller
): Promise<B2C> => {
    try {
        const response = await api.post(
            `api/customer-b2c/me/update-selected-accessory-station`,
            { accessoryStationInstaller: accessoryStationInstaller }
        );
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const getOpportunityAccessories = async (): Promise<B2CAccessories> => {
    try {
        const response = await api.get(
            `api/customer-b2c/me/get-opportunity-accessories`
        );
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const getOpportunityAllMessages = async (): Promise<B2CMessage[]> => {
    try {
        const response = await api.get(`api/customer-b2c/me/get-all-messages`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const setOpportunitySetAllowedToValidate = async (): Promise<B2C> => {
    try {
        const response = await api.put(
            `api/customer-b2c/me/opportunity/set-allowed-to-validate`
        );
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const addOpportunityMessage = async (
    message: string
): Promise<B2CMessage[]> => {
    try {
        const response = await api.post(`api/customer-b2c/me/add-message`, {
            message: message
        });
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};

export const getYousignLink = async (): Promise<YousignResponse> => {
    try {
        const response = await api.get(
            `api/customer-b2c/me/yousign/get-invoice`
        );
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to log in');
    }
};
