import { api } from './config';
import { AccessoryInstaller } from '@/models/irve/accessory.models.type';

export const getAccessoriesInstallerList = async (): Promise<
    AccessoryInstaller[]
> => {
    try {
        const response = await api.get('api/accessory/installer');
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed::AccessAPI::api/accessory/installer');
    }
};
