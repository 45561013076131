import { RouteType } from '@/models/common/route.models';
import { userInstallerB2BPaths, userInstallerB2CApartmentPaths, userInstallerB2CHousePaths, userInstallerPaths, userInstallerSetupPaths } from '@/routes/routesPathsIrve';

import {
    AccountBalanceTwoTone,
    Apartment,
    ApartmentTwoTone,
    AppSettingsAlt,
    AutoStories,
    AutoStoriesOutlined,
    BusinessTwoTone,
    DashboardCustomizeTwoTone,
    EarbudsBatteryTwoTone,
    EvStationTwoTone,
    HandymanTwoTone,
    HomeTwoTone,
    LanguageTwoTone,
    LocalOfferTwoTone,
    ManageAccountsTwoTone,
    SolarPower
} from '@mui/icons-material';

import PageLayout from '@/layouts/PageLayout';

import { RegionsAddRoute, RegionsRoute } from './Regions.routes';

import { B2BArchivedRoute, B2BMessengerRoute, B2BOnGoingProfileRoute, B2BOnGoingRoute, B2COnGoingProfileRoute } from './B2B.routes';

import { B2CApartmentsArchivedRoute, B2CApartmentsOnGoingRoute, B2CChangeStationsRoute, B2CHouseOnGoingRoute, B2CHousesArchivedRoute, B2CInvoicePreviewRoute } from './B2C.routes';

import { PannelsAddRoute, PannelsRoute } from './Pannels.routes';

import { AccessoriesAddRoute, AccessoriesRoute } from './Accessories.routes';

import { MeProfileInstallationsRoute, MeProfileLegalInformationsRoute, MeProfileOfferSimulationRoute, MeProfileRoute } from './Me.routes';

import { OverviewsRoute } from './Overviews.routes';
import { userInstallerB2BPathsPv, userInstallerB2CApartmentPathsPv, userInstallerB2CHousePathsPv, userInstallerPathsPv, userInstallerSetupPathsPv } from '@/routes/routesPathsIPV';

const accountInstallerRoutesPv: RouteType[] = [
    {
        path: userInstallerPathsPv.overview,
        element: <OverviewsRoute />,
        state: 'userInstallerPathsPv.overview',
        sidebarProps: {
            displayText: 'Accueil',
            icon: <DashboardCustomizeTwoTone />
        },
        show: true,
        index: null,
        child: null,
        label: null
    },
    {
        path: userInstallerB2BPathsPv.onGoingProfile,
        element: <B2BOnGoingProfileRoute />,
        state: 'userInstallerB2BPathsPv.opportunitiesB2BOnGoingProfile',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: userInstallerB2BPathsPv.messenger,
        element: <B2BMessengerRoute />,
        state: 'userInstallerB2BPathsPv.messenger',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },

    {
        path: undefined,
        element: null,
        state: 'undefined',
        sidebarProps: null,
        show: true,
        index: null,
        child: null,
        label: 'Opportunités'
    },

    {
        path: userInstallerB2CHousePathsPv.onGoingProfile,
        element: <B2COnGoingProfileRoute />,
        state: 'userInstallerB2CHousePathsPv.onGoingProfile',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: userInstallerB2CHousePathsPv.changeStation,
        element: <B2CChangeStationsRoute />,
        state: 'userInstallerB2CHousePathsPv.changeStation',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: userInstallerB2CHousePathsPv.preview,
        element: <B2CInvoicePreviewRoute />,
        state: 'userInstallerB2CHousePathsPv.preview',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },

    {
        path: userInstallerB2CApartmentPathsPv.index,
        element: <B2CApartmentsOnGoingRoute />,
        state: 'userInstallerB2CApartmentPathsPv.index',
        sidebarProps: {
            displayText: 'B2C',
            icon: <Apartment />
        },
        show: true,
        label: null,
        child: null,
        index: null
    },
    {
        path: userInstallerB2CApartmentPathsPv.onGoingProfile,
        element: <OverviewsRoute />,
        state: 'userInstallerB2CApartmentPathsPv.onGoingProfile',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },

    /*    {
        path: userInstallerB2BPathsPv.index,
        element: <PageLayout />,
        state: 'userInstallerB2BPathsPv.index',
        sidebarProps: {
            displayText: 'B2B',
            icon: <BusinessTwoTone />
        },
        show: true,
        label: null,
        child: [
            {
                path: userInstallerB2BPathsPv.onGoing,
                element: <B2BOnGoingRoute />,
                state: 'userInstallerB2BPathsPv.onGoing',
                sidebarProps: {
                    displayText: 'En cours',
                    icon: null
                },
                show: true,
                label: null,
                child: null,
                index: null
            },
            {
                path: userInstallerB2BPathsPv.archived,
                element: <B2BArchivedRoute />,
                state: 'userInstallerB2BPathsPv.archived',
                sidebarProps: {
                    displayText: 'Archivées',
                    icon: null
                },
                show: true,
                label: null,
                child: null,
                index: null
            }
        ],
        index: null
    }, */
    {
        path: userInstallerB2BPathsPv.onGoingProfile,
        element: <OverviewsRoute />,
        state: 'userInstallerB2BPathsPv.onGoingProfile',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },

    {
        path: undefined,
        element: null,
        state: 'undefined',
        sidebarProps: null,
        show: true,
        index: null,
        child: null,
        label: 'Mes données'
    },

    {
        path: userInstallerSetupPathsPv.profile,
        element: <MeProfileRoute />,
        state: 'userInstallerSetupPathsPv.informations',
        sidebarProps: {
            displayText: 'Mon profil',
            icon: <ManageAccountsTwoTone />
        },
        show: true,
        index: false,
        child: null,
        label: null
    },
    {
        path: userInstallerSetupPathsPv.legalInformations,
        element: <MeProfileLegalInformationsRoute />,
        state: 'userInstallerSetupPathsPv.legalInformations',
        sidebarProps: {
            displayText: 'Mes informations legales',
            icon: <AccountBalanceTwoTone />
        },
        show: true,
        index: false,
        child: null,
        label: null
    },
    {
        path: userInstallerSetupPathsPv.installations,
        element: <MeProfileInstallationsRoute />,
        state: 'userInstallerSetupPathsPv.installations',
        sidebarProps: {
            displayText: 'Mes infos installations',
            icon: <AutoStoriesOutlined />
        },
        show: true,
        index: false,
        child: null,
        label: null
    },
    {
        path: userInstallerSetupPathsPv.pannels,
        element: <PannelsRoute />,
        state: 'userInstallerSetupPathsPv.Pannels',
        sidebarProps: {
            displayText: 'Mes panneaux',
            icon: <SolarPower />
        },
        show: true,
        index: false,
        child: null,
        label: null
    },
    {
        path: userInstallerSetupPathsPv.pannelsAdd,
        element: <PannelsAddRoute />,
        state: 'userInstallerB2BPathsPv.onGoingProfile',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },

    {
        path: userInstallerSetupPathsPv.accessoriesAdd,
        element: <AccessoriesAddRoute />,
        state: 'userInstallerB2BPathsPv.accessoriesAdd',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    },
    {
        path: userInstallerSetupPathsPv.regions,
        element: <RegionsRoute />,
        state: 'userInstallerSetupPathsPv.regions',
        sidebarProps: {
            displayText: 'Mes regions',
            icon: <LanguageTwoTone />
        },
        show: true,
        index: false,
        child: null,
        label: null
    },
    {
        path: userInstallerSetupPathsPv.regionsAdd,
        element: <RegionsAddRoute />,
        state: 'userInstallerSetupPathsPv.regionsAdd',
        sidebarProps: null,
        show: false,
        index: false,
        child: null,
        label: null
    }
    /* {
        path: userInstallerSetupPathsPv.offerSimulation,
        element: <MeProfileOfferSimulationRoute />,
        state: 'userInstallerSetupPathsPv.offerSimulation',
        sidebarProps: {
            displayText: 'Simulations de mes offres',
            icon: <LocalOfferTwoTone />
        },
        show: true,
        index: false,
        child: null,
        label: null
    } */
];

export default accountInstallerRoutesPv;
