import { RootState } from './';

// Select Local Storage

export const selectApp = (state: RootState) => state.localStorage.appIrve;

export const selectAccessory = (state: RootState) => state.localStorage.accessoryIrve;

export const selectForm1 = (state: RootState) => state.localStorage.form1Irve;

export const selectForm2Apartment = (state: RootState) => state.localStorage.form2ApartmentIrve;

export const selectForm2House = (state: RootState) => state.localStorage.form2HouseIrve;

export const selectForm3 = (state: RootState) => state.localStorage.form3Irve;

export const selectForm4 = (state: RootState) => state.localStorage.form4Irve;

export const selectForm5 = (state: RootState) => state.localStorage.form5Irve;

export const selectForm6 = (state: RootState) => state.localStorage.form6Irve;

export const selectFormElecConsum = (state: RootState) => state.localStorage.formElecConsumIrve;

export const selectLayout = (state: RootState) => state.localStorage.layoutIrve;

export const selectPagination = (state: RootState) => state.localStorage.pagination;

export const selectNotification = (state: RootState) => state.localStorage.notificationIrve;

export const selectOpportunity = (state: RootState) => state.localStorage.opportunityIrve;

export const selectResultComparator = (state: RootState) => state.localStorage.resultComparatorIrve;

export const selectStation = (state: RootState) => state.localStorage.stationIrve;

export const selectStudy = (state: RootState) => state.localStorage.studyIrve;

export const selectUser = (state: RootState) => state.localStorage.userIrve;

export const selectVehicle = (state: RootState) => state.localStorage.vehicleIrve;

export const selectRoutesIrvePV = (state: RootState) => state.localStorage.routesIrvePv;

export const selectState = (state: RootState) => state.localStorage;

// Select Cookies Storage

export const selectAuth = (state: RootState) => state.cookieStorage.auth;

// Pv

export const selectHousingPv = (state: RootState) => state.localStorage.formHousingPv;

export const selectConsumPv = (state: RootState) => state.localStorage.formConsumPv;

export const selectProjectPv = (state: RootState) => state.localStorage.formProjectPv;

export const selectInformationPv = (state: RootState) => state.localStorage.formInformationPv;

export const selectResultComparatorPv = (state: RootState) => state.localStorage.resultComparatorPv;

export const selectOpportunityPv = (state: RootState) => state.localStorage.opportunityPv;

export const selectRegionList = (state: RootState) => state.localStorage.regionList;

export const selectInstallationsPv = (state: RootState) => state.localStorage.installationPv;

export const selectSolarPanel = (state: RootState) => state.localStorage.solarPanel;

export const selectLoading = (state: RootState) => state.localStorage.loading;

export const selectValidity = (state: RootState) => state.localStorage.validity;
