import { lazy } from 'react';
import Loader from '@/components/common/Loader';

export const ResultLoaderRoutePv = Loader(
    lazy(() => import('@/pages/comparator/pv/ResultLoader'))
);

export const ComparatorNotFoundRoutePv = Loader(
    lazy(() => import('@/pages/comparator/pv/NotFound'))
);

export const ResultInstallerNotFoundRoute = Loader(
    lazy(() => import('@/pages/comparator/pv/ResultInstallerNotFound'))
);

export const ResultLoaderRedirectRoute = Loader(
    lazy(() => import('@/pages/comparator/pv/ResultRedirectDashboard'))
);

export const ResultOffersRoute = Loader(
    lazy(() => import('@/pages/comparator/pv/ResultOffers'))
);

export const RecapOffersRoute = Loader(
    lazy(() => import('@/pages/comparator/pv/ResultRecapAll'))
);
