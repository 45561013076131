import { lazy } from 'react';
import Loader from '@/components/common/Loader';

export const ApartmentsOngoingRoute = Loader(
    lazy(() => import('@/pages/dashboard/B2C/irve/ApartmentsOnGoing'))
);

export const ApartmentsArchivedRoute = Loader(
    lazy(() => import('@/pages/dashboard/B2C/irve/ApartmentsArchived'))
);

export const ApartmentsOngoingProfileRoute = Loader(
    lazy(() => import('@/pages/dashboard/B2C/irve/Profile/Profile'))
);

export const ApartmentsOngoingProfileChangeStationsRoute = Loader(
    lazy(() => import('@/pages/dashboard/B2C/irve/Profile/ChangeStations'))
);

export const ApartmentsOngoingProfileChangeInstallersRoute = Loader(
    lazy(() => import('@/pages/dashboard/B2C/irve/Profile/ChangeInstallers'))
);
