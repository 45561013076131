import { lazy } from 'react';
import Loader from '@/components/common/Loader';

export const MeProfileRoute = Loader(lazy(() => import('@/pages/accountInstaller/Me/irve/Profile')));

export const MeProfileLegalInformationsRoute = Loader(lazy(() => import('@/pages/accountInstaller/Me/irve/LegalInformations')));

export const MeProfileInstallationsRoute = Loader(lazy(() => import('@/pages/accountInstaller/Me/irve/Installations')));

export const MeProfileOfferSimulationRoute = Loader(lazy(() => import('@/pages/accountInstaller/Me/irve/OfferSimulation')));
