import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    B2B,
    B2BInstallers,
    B2BComment,
    B2BStore,
    B2BMessage,
    B2BTableHeader,
    B2BStatusI,
    B2BInstallersV2
} from '../../../models/irve/b2b.models';
import { UserInstaller } from '../../../models/common/user.models';

type UpdateStudyAction = PayloadAction<B2B>;
type UpdateStudyInstallersAction = PayloadAction<B2BInstallers>;
type UpdateStudyCommentAction = PayloadAction<B2BComment>;
type UpdateStudyConversationAction = PayloadAction<B2BMessage[]>;
type UpdateStudySelectedIdAction = PayloadAction<number>;
type UpdateStudyFilteredListAction = PayloadAction<B2BTableHeader[]>;
type UpdateStudyStatusAction = PayloadAction<B2BStatusI>;
type UpdateStudyInstallerIdAction = PayloadAction<number>;
type UpdateStudyIdAction = PayloadAction<number>;
type UpdateStudySelectedInstallersIdAction = PayloadAction<number[]>;
type UpdateStudyConversationIdAction = PayloadAction<number>;
type UpdateStudyInstallerAction = PayloadAction<B2BInstallersV2>;

const initialState: B2BStore = {
    study: null,
    studyInstallers: null,
    studyComment: null,
    studyConversation: null,
    studySelectedId: null,
    studyFilteredList: null,
    studyStatus: null,
    studyInstallerId: null,
    studyId: null,
    studySelectedInstallersId: null,
    studyConversationId: null,
    studyInstaller: null
};

const studySlice = createSlice({
    name: 'studySlice',
    initialState,
    reducers: {
        setStateStudyIrve: (state, action: UpdateStudyAction) => {
            state.study = action.payload;
        },
        setStateStudyInstallersIrve: (
            state,
            action: UpdateStudyInstallersAction
        ) => {
            state.studyInstallers = action.payload;
        },
        setStateStudyCommentIrve: (state, action: UpdateStudyCommentAction) => {
            state.studyComment = action.payload;
        },
        setStateStudyConversationIrve: (
            state,
            action: UpdateStudyConversationAction
        ) => {
            state.studyConversation = action.payload;
        },
        setStateStudySelectedIdIrve: (
            state,
            action: UpdateStudySelectedIdAction
        ) => {
            state.studySelectedId = action.payload;
        },
        setStateStudyFilteredListIrve: (
            state,
            action: UpdateStudyFilteredListAction
        ) => {
            state.studyFilteredList = action.payload;
        },
        setStateStudyStatusIrve: (state, action: UpdateStudyStatusAction) => {
            state.studyStatus = action.payload;
        },
        setStateStudyInstallerIdIrve: (
            state,
            action: UpdateStudyInstallerIdAction
        ) => {
            state.studyInstallerId = action.payload;
        },
        setStateStudyIdIrve: (state, action: UpdateStudyIdAction) => {
            state.studyId = action.payload;
        },
        setStateStudySelectedInstallersIdIrve: (
            state,
            action: UpdateStudySelectedInstallersIdAction
        ) => {
            state.studySelectedInstallersId = action.payload;
        },
        setStateStudyConversationIdIrve: (
            state,
            action: UpdateStudyConversationIdAction
        ) => {
            state.studyConversationId = action.payload;
        },
        setStateStudyInstallerIrve: (
            state,
            action: UpdateStudyInstallerAction
        ) => {
            state.studyInstaller = action.payload;
        },
        clearStateStudyIrve: (state) => {
            state.study = null;
            state.studyInstallers = null;
            state.studyComment = null;
            state.studyConversation = null;
            state.studySelectedId = null;
            state.studyFilteredList = null;
            state.studyStatus = null;
            state.studyInstallerId = null;
            state.studyId = null;
            state.studySelectedInstallersId = null;
            state.studyConversationId = null;
            state.studyInstaller = null;
        }
    }
});

export const {
    setStateStudyIrve,
    setStateStudyInstallersIrve,
    setStateStudyCommentIrve,
    setStateStudyConversationIrve,
    setStateStudySelectedIdIrve,
    setStateStudyFilteredListIrve,
    setStateStudyStatusIrve,
    setStateStudyInstallerIdIrve,
    setStateStudySelectedInstallersIdIrve,
    setStateStudyIdIrve,
    setStateStudyConversationIdIrve,
    setStateStudyInstallerIrve,
    clearStateStudyIrve
} = studySlice.actions;

export default studySlice.reducer;
